import * as mutations from "@/store/mutation-types";
import * as actions from "@/store/action-types";
import discounts from "@/requests/discounts";

export default {
  [actions.GET_DISCOUNTS](context, page) {
    return new Promise((resolve, reject) => {
      discounts
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.DISCOUNTS, {
              page,
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
