import axios from "axios";

export default {
  list(params) {
    return axios.get(`marketplace_crm/curations?${params}`);
  },

  index() {
    return axios.get(`marketplace_crm/curations`);
  },

  create(payload) {
    return axios.post(`marketplace_crm/curations`, payload);
  },

  update(id, payload) {
    return axios.patch(`marketplace_crm/curations/${id}`, payload);
  },

  getBusinessTypes() {
    return axios.get(`marketplace_crm/business_types`);
  },

  delete(id) {
    return axios.delete(`marketplace_crm/curations/${id}`);
  },

  curation(id) {
    return axios.get(`marketplace_crm/curations/${id}`);
  },

  search(id, query) {
    return axios.get(`marketplace_crm/curations/${id}?search=${query}`);
  },

  products(page, params) {
    return axios.get(`marketplace_crm/products?${params}&page=${page}`);
  },

  attach(id, payload) {
    return axios.patch(`marketplace_crm/curations/${id}/attach`, payload);
  },
  remove(id, payload) {
    return axios.patch(`marketplace_crm/curations/${id}/detach`, payload);
  },

  stats() {
    return axios.get(`marketplace_crm/vendors/stats`);
  }
};
