<template>
  <div class="image-upload">
    <div
      v-if="imagePreview"
      v-loading="uploading"
      class="image-upload__preview"
      :style="imageStyle"
      @dragenter.prevent="onDragEnter"
      @dragover.prevent="onDragOver"
      @dragleave.prevent="onDragLeave"
      @drop.prevent="onDrop"
      @mouseover="hover = true"
      @mouseout="hover = false"
    >
      <el-button
        v-if="hover && imageUrl"
        @click="changeBanner"
        type="plain"
        class="change-banner"
        >Change banner</el-button
      >

      <el-upload
        action=""
        class="upload"
        :on-change="uploadPhoto"
        :show-file-list="false"
        :auto-upload="false"
        accept="image/jpeg,image/jpg,image/png"
        ref="upload"
        @before-upload="() => false"
      >
        <img
          v-if="!imageUrl"
          :src="getImage('default/image-upload-icon.svg')"
          :alt="'Image'"
          :class="'default'"
        />
        <img v-else :src="imageUrl" :alt="'Image'" :class="imageType" />
        <div v-if="!imageUrl">
          <span class="text-primary text-underline">Click to upload</span>
          or drag & drop banner
        </div>
      </el-upload>
    </div>
  </div>
</template>

<script>
export default {
  name: "EdenImageDragAndDrop",
  props: {
    imagePreview: {
      type: Boolean,
      default: true,
    },
    imageSize: {
      type: Number,
      default: 80,
    },
    imageType: {
      type: String,
      default: "rounded",
    },
    imageUrl: {
      type: String,
      default: "",
    },
    imageText: {
      type: String,
      default: "image",
    },
    imageFolder: {
      type: String,
      default: "ungrouped",
    },
  },
  data() {
    return {
      types: {
        square: "0px",
        rounded: "12px",
        circle: "1000px",
      },
      style: {
        fontWeight: "500",
        fontSize: "0.875rem",
      },
      cloudinaryUrl: "https://api.cloudinary.com/v1_1/eden-life-inc/upload",
      cloudinaryPresetUpload: "zt3wbrxu",
      uploading: false,
      hover: false,
    };
  },
  computed: {
    imageStyle() {
      return {
        background: this.imageUrl ? "#d3d8db" : "#EBFFF5",
        height: `${this.imageSize}px`,
        width: `${this.imageSize}px`,
        borderRadius: this.types[this.imageType],
      };
    },
    setImageUrl: {
      get() {
        return this.imageUrl;
      },
      set(value) {
        this.$emit("update:imageUrl", value);
      },
    },
  },
  methods: {
    uploadPhoto(event) {
      if (event.size / 1024 / 1024 < 20) {
        this.uploading = true;
        this.$emit("uploading");
        const formData = new FormData();
        const image = event.raw;
        const env =
          process.env.NODE_ENV === "production" ? "production" : "staging";

        formData.append("file", image);
        formData.append("folder", `marketplace/${env}/${this.imageFolder}`);
        formData.append("upload_preset", this.cloudinaryPresetUpload);

        fetch(this.cloudinaryUrl, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            this.setImageUrl = data.url;
            setTimeout(() => {
              this.uploading = false;
              this.$emit("uploaded");
            }, 500);
          })
          .catch((error) => {
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
            this.uploading = false;
          });
      } else {
        this.$message.error("Image cannot be larger than 20MB!");
      }
    },
    // async uploadPhoto(event) {
    //   this.uploading = true;
    //   this.$emit("uploading");
    //   try {
    //     const fileUrl = await this.uploadFile(event);
    //     this.setImageUrl = fileUrl;
    //     setTimeout(() => {
    //       this.uploading = false;
    //       this.$emit("uploaded");
    //     }, 500);
    //   } catch (error) {
    //     const errorMessage = error.response.data;
    //     if (errorMessage.errors) {
    //       const errorKeys = Object.keys(errorMessage.errors);
    //       this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
    //     } else {
    //       this.$message.error(errorMessage.message);
    //     }
    //     this.uploading = false;
    //   }
    // },
    onDragEnter(event) {
      event.preventDefault();
    },
    onDragOver(event) {
      event.preventDefault();
    },
    onDragLeave(event) {
      event.preventDefault();
    },
    onDrop(e) {
      const file = e.dataTransfer.files[0];
      this.uploadPhoto({ raw: file, size: file.size });
      this.hover = false;
    },
    changeBanner() {
      this.$refs.upload.$refs.input.click();
    },
  },
};
</script>

<style scoped lang="scss">
.image-upload {
  // display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__preview {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 112px !important;
    width: 100% !important;
    border-radius: 16px;
    background: var(--eden-green-septenary);
    margin-bottom: 10px;
    flex-direction: column;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;

      &.default {
        height: auto;
        width: 10%;
      }

      &.rounded {
        border-radius: 12px;
      }
    }
  }

  a {
    // color: var(--eden-green-primary);
  }
}

.change-banner {
  position: absolute;
  visibility: hidden;
  color: white;
  background-color: transparent;
  border: 1px solid #ffffff;
  padding: 10px;
  cursor: pointer;
  transition: visibility 0.5s;
}

.image-upload__preview:hover .change-banner {
  visibility: visible;
}

.upload {
  width: 100%;
}

.upload ::v-deep .el-upload {
  height: 112px !important;
  width: 100% !important;
}
</style>
