const Error404 = () => import("@/pages/error");
const Unauthorized = () => import("@/pages/unauthorized");
const Login = () => import("@/pages/login");
const ForgotPassword = () => import("@/pages/forgot-password");
const ResetPassword = () => import("@/pages/reset-password");
const AcceptInvite = () => import("../pages/invite.vue");
const routes = () => {
  return [
    {
      path: "*",
      name: "error",
      component: Error404,
    },
    {
      path: "/unauthorized",
      name: "unauthorized",
      component: Unauthorized,
      meta: {
        permission: "all",
        layout: "app",
      },
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: ForgotPassword,
      meta: {
        permission: "guest",
      },
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: ResetPassword,
      meta: {
        permission: "guest",
      },
    },
    {
      path: "/",
      redirect: {
        name: "login",
      },
      meta: {
        permission: "guest",
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        permission: "guest",
      },
    },
    {
      path: "/invite/account/:id",
      name: "accept-invite",
      component: AcceptInvite,
      meta: {
        permission: "guest",
      },
    },
  ];
};

export default routes();
