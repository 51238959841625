const Dashboard = () => import("@/pages/dashboard/index");
const DashboardFeedback = () => import("@/pages/dashboard/feedback");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "gardener",
  "gardener_pro",
];

const MODULE = "dashboard";

const routes = () => {
  return [
    {
      path: "/dashboard",
      name: "dashboard.index",
      component: Dashboard,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/dashboard/feedback",
      name: "dashboard.feedback",
      component: DashboardFeedback,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
