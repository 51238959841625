const Zone = () => import("@/pages/zones/index");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "growth",
  "gardener",
  "gardener_pro",
];

const MODULE = "zones";

const routes = () => {
  return [
    {
      path: "/zones",
      name: "zones.index",
      component: Zone,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
