<template>
  <img
    :src="getAvatar(src)"
    alt="avatar"
    class="user-avatar"
    :style="{ height: `${size}px`, width: `${size}px` }"
  />
</template>

<script>
export default {
  name: "EdenAvatar",
  props: {
    src: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 32,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.user-avatar {
  height: 32px;
  width: 32px;
  object-fit: cover;
  border-radius: 100px;
}
</style>
