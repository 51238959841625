<template>
  <component :is="layout">
    <!-- TODO: For page caching Removed to monitor inconsistent behaviour on CRM -->
    <!-- <router-view :layout.sync="layout" :key="$route.fullPath?.split('?')[0]" /> -->
    <router-view :layout.sync="layout" :key="$route.fullPath?.split('?')[0]" />
  </component>
</template>

<script>
export default {
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || "default") + "-layout";
    },
  },
  mounted() {
    console.log(JSON.parse(localStorage.getItem("eden-crm-user")));
  },
};
</script>

<style lang="scss"></style>
