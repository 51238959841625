import axios from "axios";

export default {
  summary(period, range) {
    if (period === "custom") {
      return axios.post(`feedback_summary/all/filter`, range);
    } else {
      return axios.get(`feedback_summary?period=${period}`);
    }
  },

  index(period, type, page) {
    return axios.get(`feedback/details/${type}?period=${period}&?page=${page}`);
  },

  filter(type, payload) {
    return axios.post(`feedback/details/${type}/filter`, payload);
  },

  getGardenerWithMostCustomers(period, range) {
    if (period === "custom") {
      return axios.post(
        "feedback/gardeners/most_happy_customers/all/filter",
        range,
      );
    }
    return axios.get(
      `feedback/gardeners/most_happy_customers?period=${period}`,
    );
  },

  getServiceWithMostCustomers(period, range) {
    if (period === "custom") {
      return axios.post(
        "feedback/services/most_happy_customers/all/filter",
        range,
      );
    }
    return axios.get(`feedback/services/most_happy_customers?period=${period}`);
  },

  reasons(service) {
    return axios.get(`feedback/service/${service}/ratings_with_reasons`);
  },

  allReasons() {
    return axios.get("feedback/services/reasons");
  },

  add(payload) {
    return axios.post("feedback/service/rating/add", payload);
  },

  get(id) {
    return axios.get(`feedback/service/ratings/${id}`);
  },
};
