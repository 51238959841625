import * as types from "../../mutation-types";

export default {
  [types.ZONES_LIST](state, data) {
    state.zones_list = data;
  },

  [types.ZONES](state, { page, data }) {
    state.zones.total = data.total;
    state.zones.pages[page] = {
      from: data.from,
      to: data.to,
      data: data.data,
    };
  },

  [types.LOCATION_AREAS_LIST](state, data) {
    state.location_areas_list = data;
  },

  [types.LOCATION_AREAS](state, { page, data }) {
    state.location_areas.total = data.total;
    state.location_areas.pages[page] = {
      from: data.from,
      to: data.to,
      data: data.data,
    };
  },

  [types.MICROFULFILLMENT_LIST](state, data) {
    state.micro_fulfillment_list = data;
  },

  [types.MICROFULFILLMENT_CENTRES](state, { page, data }) {
    state.micro_fulfillment_centers.total = data.meta.total;
    state.micro_fulfillment_centers.pages[page] = {
      from: data.meta.from,
      to: data.meta.to,
      data: data.data,
    };
  },

  [types.RESET_STATE](state, defaultState) {
    Object.assign(state, { ...defaultState.zones });
  },
};
