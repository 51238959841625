import * as mutations from "@/store/mutation-types";
import * as actions from "@/store/action-types";
import zones from "@/requests/zones/index";
import locationareas from "@/requests/zones/locationareas";
import mfc from "@/requests/zones/mfc";

export default {
  [actions.GET_ZONES_LIST](context) {
    return new Promise((resolve, reject) => {
      zones
        .list()
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.ZONES_LIST, response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_ZONES](context, page) {
    return new Promise((resolve, reject) => {
      zones
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.ZONES, {
              page,
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_LOCATION_AREAS_LIST](context) {
    return new Promise((resolve, reject) => {
      locationareas
        .list()
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.LOCATION_AREAS_LIST, response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_LOCATION_AREAS](context, page) {
    return new Promise((resolve, reject) => {
      locationareas
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.LOCATION_AREAS, {
              page,
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_MICROFULFILLMENT_LIST](context) {
    return new Promise((resolve, reject) => {
      mfc
        .list()
        .then((response) => {
          console.log(response.data.data, "mfc list");
          if (response.data.status) {
            context.commit(mutations.MICROFULFILLMENT_LIST, response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_MICROFULFILLMENT_CENTRES](context, page) {
    return new Promise((resolve, reject) => {
      mfc
        .index(page)
        .then((response) => {
          console.log(response.data, "mfcs");
          if (response.data.status) {
            context.commit(mutations.MICROFULFILLMENT_CENTRES, {
              page,
              data: response.data,
            });
          }
          console.log(response.data, "ghjhhh");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
