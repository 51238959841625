import * as mutations from "../../mutation-types";
import * as actions from "../../action-types";

import kitchen from "@/requests/stewards/kitchen";
import combocheck from "@/requests/stewards/combocheck";
import ofa from "@/requests/stewards/ofa";

export default {
  [actions.GET_PRODUCTION_PROVISIONS](context, { spId, period, from, to }) {
    return new Promise((resolve, reject) => {
      kitchen
        .items(spId, period, from, to)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.PRODUCTION_PROVISIONS, {
              period,
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [actions.GET_COMBO_CHECK](context, { spId, period, from, to }) {
    return new Promise((resolve, reject) => {
      combocheck
        .combos(spId, period, from, to)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.COMBO_CHECK, {
              period,
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [actions.GET_COMBO_HISTORY](context, { page }) {
    return new Promise((resolve, reject) => {
      combocheck
        .history(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.COMBO_HISTORY, {
              page,
              data: response.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_OFA_HISTORY](context, { page }) {
    return new Promise((resolve, reject) => {
      ofa
        .history(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.OFA_HISTORY, {
              page,
              data: response.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
