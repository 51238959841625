const Activities = () => import("@/pages/activities/index");

const authenticatedRoles = ["superadmin", "admin", "operations", "kitchen"];

const MODULE = "activities";

const routes = () => {
  return [
    {
      path: "/activities",
      name: "activities.index",
      component: Activities,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
