const Business = () => import("../pages/business/index");
const BusinesAddAccount =  () => import("../pages/business/add");
const BusinesAccountProfile =  () => import("../pages/business/company-profile");
const BusinesAccountSubscriptions =  () => import("../pages/business/subscription-groups");
const BusinesAccountSubscriptionsCycle =  () => import("../pages/business/subscription-cycle");
const BusinessUploadReceipts =  () => import("../pages/business/upload-receipts");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "growth",
  "gardener",
  "gardener_pro",
];

const routes = () => {
    return [
        /**
        Eden for business module routes
         */
        {
            path: "/business",
            name: "business.index",
            component: Business,

            meta: {
                module: 'business',
                permission: authenticatedRoles,
                layout: "app",
            },
        },
        /**
         * Bulk Messages Sub-Module
         */
        {
          path: "/business/account/:id",
          name: "business.profile",
          component: BusinesAccountProfile,
          meta: {
            module: "business",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
            path: "/business/account/:id/subscriptions",
            name: "business.corporate-subscriptions",
            component: BusinesAccountSubscriptions,
            meta: {
              module: "business",
              permission: authenticatedRoles,
              layout: "app",
            },
          },
          {
            path: "/business/account/:id/subscription-cycle",
            name: "business.corporate-subscription-cycle",
            component: BusinesAccountSubscriptionsCycle,
            meta: {
              module: "business",
              permission: authenticatedRoles,
              layout: "app",
            },
          },

          {
            path: "/business/add-account",
            name: "business.add-account",
            component: BusinesAddAccount,
            meta: {
              module: "business",
              permission: authenticatedRoles,
              layout: "app",
            },
          },
          {
            path: "/business/edit-account/:id",
            name: "business.edit-account",
            component: BusinesAddAccount,
            meta: {
              module: "business",
              permission: authenticatedRoles,
              layout: "app",
            },
          },
          {
            path: "/business/:id/upload-receipts",
            name: "business.uploaded-receipts",
            component: BusinessUploadReceipts,
            meta: {
              module: "business",
              permission: authenticatedRoles,
              layout: "app",
            },
          },
    ];
};

export default routes();
