import dayjs from "dayjs";
import en from "dayjs/locale/en";

/**
 * Define Start of the week as [Monday]
 */

dayjs.locale({
  ...en,
});

const COUNTRY = localStorage.getItem("eden-crm-location");

dayjs().utcOffset(COUNTRY === "KE" ? 2 : 0);

/**
 * Define Plugins and Extend
 */

import advancedFormat from "dayjs/plugin/advancedFormat";
const weekday = require("dayjs/plugin/weekday");
const relativeTime = require("dayjs/plugin/relativeTime");
const customParseFormat = require("dayjs/plugin/customParseFormat");

dayjs.extend(weekday);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

/**
 * Setting defaults for Method names with 'format' is compulsory.
 * Setting defaults for Method names with 'get' is optional, and when
 * If {date} is not specified, dayjs(date) becomes dayjs(undefined),
 * which defaults to current date.
 */

export default {
  methods: {
    /**
     * Formats a date string/object into the format specified
     * @param date
     * @param format
     * @returns {string, object}
     */
    formatDate(date, format) {
      if (!date) {
        return "-";
      }
      switch (format) {
        case "d-m-y":
          return dayjs(date).format("DD-MM-YYYY");
        case "y-m-d":
          return dayjs(date).format("YYYY-MM-DD");
        case "do m, y":
          return dayjs(date).format("DD MMM, YYYY");
        case "m do, y":
          return dayjs(date).format("MMM DD, YYYY");
        case "ddd do, m, y":
          return dayjs(date).format("ddd DD, MMM, YYYY");
        case "dddd do, m, y":
          return dayjs(date).format("ddd DD, MMM, YYYY");
        case "dddd ddd, m, y":
          return dayjs(date).format("dddd DD, MMM, YYYY");
        case "do m":
          return dayjs(date).format("DD MMM");
        case "m y":
          return dayjs(date).format("MMM YYYY");
        case "dddd":
          return dayjs(date).format("dddd DD, MMM");
        case "mm d, y":
          return dayjs(date).format("MMMM D, YYYY");
        case "m do, y; H:mm A":
          return dayjs(date).format("MMM DD, YYYY; h:mm A");
        case "object":
          return dayjs(date);
        default:
          return dayjs(date).format("DD MMM, YYYY");
      }
    },

    /**
     * Formats a date/time string into the format specified
     * @param time
     * @param format
     * @returns {string}
     */
    formatTime(time, format) {
      if (!time) {
        return "-";
      }

      if (time === "now") {
        return dayjs().format(format === "24h" ? "HH:mm" : "hh:mm A");
      }

      switch (format) {
        case "hour":
          return dayjs(time).format("H");
        case "12h":
          return dayjs(time).format("hh:mm A");
        case "24h":
          return dayjs(time).format("HH:mm");
        default:
          return dayjs(time, "hh:mm").format("hh:mm A");
      }
    },
    shortDate(date) {
      return date ? dayjs(date).format("Do MMM, YYYY") : "-";
    },

    /**
     * Formats date string/object into the format specified
     * @param date
     * @param format {string}
     * @returns {string}
     */
    formatDateAndTime(date, format) {
      if (!date) {
        return "-";
      }
      return this.formatDate(date, format) + " " + this.formatTime(date, "12h");
    },

    /**
     * Formats date to get time elapsed.
     * @param date
     * @returns {string}
     */
    formatTimeFromNow(date, suffix = true) {
      return dayjs(date).fromNow(!suffix);
    },


      /**
     * Formats date string/object into the format specified
     * @param date
     * @returns {string}
     */
    formtTimeDifference(former, later ){
      return dayjs(former).from(later)
    },

    /**
     * Formats a give period {string} into date using specified format.
     * @param period {string}
     * @param format {string}
     */
    formatPeriodDate(period, format) {
      switch (period) {
        case "last90":
          return this.getPastDate({
            date: dayjs(),
            unit: "day",
            num: 90,
            format,
          });
        case "last60":
          return this.getPastDate({
            date: dayjs(),
            unit: "day",
            num: 60,
            format,
          });
        case "last30":
          return this.getPastDate({
            date: dayjs(),
            unit: "day",
            num: 30,
            format,
          });
        case "lastmonth": {
          const lastmonth = this.getPastDate({
            date: dayjs(),
            unit: "month",
            num: 1,
            format: "object",
          });
          return {
            start: this.formatDate(dayjs(lastmonth).startOf("month"), format),
            end: this.formatDate(dayjs(lastmonth).endOf("month"), format),
          };
        }
        case "lastweek": {
          const lastweek = this.getPastDate({
            date: dayjs(),
            unit: "week",
            num: 1,
            format: "object",
          });
          return {
            start: this.formatDate(dayjs(lastweek).startOf("week"), format),
            end: this.formatDate(dayjs(lastweek).endOf("week"), format),
          };
        }
        case "yesterday":
          return this.getPastDate({
            date: dayjs(),
            unit: "day",
            num: 1,
            format,
          });
        case "today":
          return this.formatDate(dayjs(), format);
        case "tomorrow":
          return this.getFutureDate({
            date: dayjs(),
            unit: "day",
            num: 1,
            format,
          });
        case "nexttwodays":
          return this.getFutureDate({
            date: dayjs(),
            unit: "day",
            num: 2,
            format,
          });
        case "thisweek":
          return {
            start: this.formatDate(dayjs().startOf("week"), format),
            end: this.formatDate(dayjs().endOf("week"), format),
          };
        case "nextweek": {
          const nextweek = this.getFutureDate({
            date: dayjs(),
            unit: "week",
            num: 1,
            format: "object",
          });
          return {
            start: this.formatDate(dayjs(nextweek).startOf("week"), format),
            end: this.formatDate(dayjs(nextweek).endOf("week"), format),
          };
        }
        case "thismonth": {
          return {
            start: this.formatDate(dayjs().startOf("month"), format),
            end: this.formatDate(dayjs().endOf("month"), format),
          };
        }
        default:
          return this.formatDate(dayjs());
      }
    },

    formatStartAndEndDate({ period, from, to }) {
      let start_date, end_date;
      if (period !== "custom") {
        const date = this.formatPeriodDate(period, "y-m-d");
        start_date = date.start ? date.start : this.formatDate(date, 'y-m-d') ;
        end_date = date.end ? date.end : this.formatDate(date, 'y-m-d');
      } else {
        start_date = from;
        end_date = to;
      }

      return {
        start_date,
        end_date,
      };
    },

    formatDefaultServiceStartDate() {
      const nexttwodays = this.formatPeriodDate("nexttwodays", "y-m-d");
      const nextthreedays = this.getFutureDate({
        date: nexttwodays,
        unit: "day",
        num: 1,
        format: "y-m-d",
      });

      return this.getWeekday(nexttwodays).toLowerCase() === "sunday"
        ? [nextthreedays]
        : [nexttwodays];
    },

    formatSubscriptionDate(start, end) {
      const s = this.formatDate(start, "do m");
      const e = this.formatDate(end, "do m");

      const sYear = this.getYear(start);
      const eYear = this.getYear(end);

      return {
        start: `${s} ${sYear !== eYear ? sYear : ""}`,
        end: `${e} ${sYear === eYear ? sYear : eYear}`,
      };
    },

    /**
     * Returns past date using a specified date as  start point.
     * @param date - date to count backwards from
     * @param unit - day, week, month, year
     * @param num - quantity of unit
     * @param format
     * @returns {string}
     */
    getPastDate({ date, unit, num, format }) {
      return this.formatDate(
        dayjs(date ?? undefined).subtract(num, unit),
        format,
      );
    },

    /**
     * Similar to getPastDate()
     * Returns future date using a specified date as start point.
     * @param date - date to count forwards from
     * @param unit - day, week, month, year
     * @param num - quantity of unit
     * @param format
     * @returns {string}
     */
    getFutureDate({ date = undefined, unit, num, format }) {
      return this.formatDate(dayjs(date).add(num, unit), format);
    },

    /**
     * Returns formatted specified.
     * @param date
     * @param format
     * @returns {string}
     */
    getDate(date, format) {
      return this.formatDate(date ?? dayjs(), format);
    },

    getDateDifference({ fDate, sDate, unit }) {
      return dayjs(fDate).diff(sDate, unit ? unit : "day");
    },

    /**
     * Returns string of minute for a date.
     * @param date
     * @param format
     * @returns {string}
     */
    getMinutes(date) {
      return dayjs(date ?? undefined).minute();
    },

    /**
     * Returns string of hour for a date.
     * @param date
     * @param format
     * @returns {string}
     */
    getHour(date) {
      console.log(dayjs(date ?? undefined).form, "date");
      return dayjs(date ?? undefined).hour();
    },

    /**
     * Returns index of weekday for a date.
     * @param date
     * @returns {number}
     */
    getWeekdayIndex(date = undefined) {
      return dayjs(date).weekday();
    },

    /**
     * Returns string of weekday for a date.
     * @param date
     * @returns {string}
     */
    getWeekday(date = undefined) {
      return dayjs(date).format("dddd");
    },

    /**
     * Returns index of month for a date.
     * @param date
     * @returns {number}
     */
    getMonthIndex(date) {
      return dayjs(date ?? undefined).month();
    },

    getMonthNumber(month) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const monthIndex = months.findIndex((item) => item === month);

      return monthIndex > -1 ? monthIndex + 1 : monthIndex;
    },

    /**
     * Returns string of month for a date.
     * @param date
     * @param format
     * @returns {string}
     */
    getMonth(date, format) {
      return dayjs(date ?? undefined).format(format ? format : "MMMMM");
    },

    /**
     * Returns string of year for a date.
     * @param date
     * @returns {number}
     */
    getYear(date) {
      return dayjs(date ?? undefined).year();
    },
  },
};
