const FeatureTracker = () => import("@/pages/feature-tracker/index");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "growth",
  "gardener",
  "gardener_pro",
  "steward",
  "ofa",
];

const routes = () => {
  return [
    {
      path: "/garden-feature-tracker",
      name: "feature-tracker.index",
      component: FeatureTracker,
      meta: {
        module: "feature-tracker",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
