<template>
  <div class="table-heading">
    <div class="table-heading__title">
      <h6 v-if="title" class="body-text font-base subtitle">{{ title }}</h6>
      <el-input
        :class="{ 'ml-auto': positionSearchAtEnd }"
        v-if="showSearch"
        v-model="searchQuery"
        type="text"
        :prefix-icon="'el-icon-search'"
        placeholder="Search"
        @keyup.enter.native="$emit('search', searchQuery)"
      ></el-input>
      <slot name="title"></slot>
    </div>
    <div class="table-heading__actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  name: "EdenTableActions",
  props: {
    title: {
      type: String,
      default: "",
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    positionSearchAtEnd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchQuery: "",
    };
  },
  watch: {
    searchQuery() {
      if (this.searchQuery === "") {
        this.$emit("search", this.searchQuery);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ml-auto {
  margin-left: auto;
}
.table-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;

  &__title {
    display: flex;
    align-items: center;
    flex: 1;

    .subtitle {
      margin-right: 20px;
      font-weight: 500;
    }

    .el-input {
      width: 200px;
      font-weight: 400;

      @media (max-width: 525px) {
        width: 90%;
        margin: 10px 0;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }
}
</style>
