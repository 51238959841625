import axios from "axios";

export default {
  list() {
    return axios.get("services");
  },

  index() {
    return axios.get("service/all");
  },

  add(payload) {
    return axios.post("service", payload);
  },

  update(id, payload) {
    return axios.post(`service/${id}/measurement`, payload);
  },

  status(id, payload) {
    return axios.post(`service/${id}`, payload);
  },

  serviceTypes(service) {
    return axios.get(`${service}/items/all`);
  },
};
