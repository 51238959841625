import axios from "axios";

export default {
  index(id, page) {
    return axios.get(`billing_history/${id}?page=${page}`);
  },

  filter(payload) {
    return axios.post("billing_history/filter", payload);
  },

  export(payload) {
    return axios.post("billing_history/export/all", payload);
  },
};
