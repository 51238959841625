import * as types from "../../mutation-types";

export default {
  [types.SERVICE_PARTNERS_LIST](state, service_partners_list) {
    service_partners_list
      .filter((sp) => sp.active_status)
      .forEach((sp) => {
        const service = sp.service_offering.toLowerCase();
        const id = sp.id;
        if (!state.service_partners_list[service]) {
          state.service_partners_list[service] = {};
        }
        state.service_partners_list[service][id] = sp;
      });
  },

  [types.SERVICE_PARTNERS](state, { page, data }) {
    state.servicepartners.total = data.total;
    state.servicepartners.pages = {
      [page]: {
        from: data.from,
        to: data.to,
        data: data.data,
      },
    };
  },

  [types.PROVISIONED_ORDERS](state, { provisioned_orders, service }) {
    state.provisioned_orders[service] = provisioned_orders;
  },

  [types.RESET_STATE](state, defaultState) {
    Object.assign(state, { ...defaultState.servicepartners });
  },
};
