import axios from "axios";

export default {
  summary({ start_date, end_date, customer_type, gardener_id }) {
    let params = `start_date=${start_date}&end_date=${end_date}`;

    if (customer_type) {
      params += `&customer_type=${customer_type}`;
    }

    if (gardener_id) {
      params += `&gardener_id=${gardener_id}`;
    }
    return axios.get(`crm/summary/payment-tracker?${params}`);
  },

  index({ type, page, start_date, end_date, gardener_id }) {
    let params = `customer_type=${type}&page=${page}&start_date=${start_date}&end_date=${end_date}`;

    if (gardener_id) {
      params += `&gardener_id=${gardener_id}`;
    }
    return axios.get(`crm/gardener/payment-tracker?${params}`);
  },

  filter(params) {
    return axios.get(`crm/gardener/payment-tracker?${params}`);
  },

  update(payload) {
    return axios.put("crm/gardener/payment-tracker", payload);
  },

  export() {
    return axios.get("crm/gardener/payment-tracker/export");
  },
  getEmployees(employees) {
    return axios.get(`crm/gardener/corporate/employees`, {
      params: { employees_id: employees },
    });
  },
};
