import axios from "axios";

// window.axios.defaults.baseURL =
//   "https://api-marketplace.edenlife.ng/api/v1/marketplace_crm/";

export default {
  list() {
    return axios.get("/marketplace_crm/vendors/all?status=approved");
  },

  index(page) {
    return axios.get(`/marketplace_crm/vendors?status=Approved&page=${page}`);
  },

  search(search) {
    return axios.get(`marketplace_crm/vendors?status=Approved&query=${search}`);
  },

  updateVendorState(id, payload) {
    return axios.patch(`marketplace_crm/vendors/${id}/change-status`, payload);
  },

  export(payload) {
    return axios.post(`marketplace_crm/vendors/export`, payload);
  },
};
