const Discounts = () => import("@/pages/discounts/index");
const Discount = () => import("@/pages/discounts/discount");
const Badge = () => import("@/pages/discounts/badge");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "growth",
  "gardener",
  "gardener_pro",
];

const routes = () => {
  return [
    {
      path: "/discounts-and-rewards",
      name: "discounts.index",
      component: Discounts,
      meta: {
        module: "discounts",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/discounts-and-rewards/discount/:id",
      name: "discounts.discounts.discount",
      component: Discount,
      meta: {
        module: "discounts",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/discounts-and-rewards/badges/:id",
      name: "discounts.badges.index",
      component: Badge,
      meta: {
        module: "discounts",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
