import axios from "axios";

export default {
  index({ servicePartnerId, service, start_date, end_date, page }) {
    let params = `from=${start_date}&to=${end_date}&page=${page}`;

    if (service.includes("laundry")) {
      const type = service.split("_")[1];
      params += `&schedule_type=${
        type === "delivery" ? "deliver_to_customer" : "pickup_from_customer"
      }`;
      service = "laundry";
    }

    if (servicePartnerId && servicePartnerId !== "all") {
      return axios.get(`${servicePartnerId}/customer_orders/zones?${params}`);
    }
    return axios.get(
      `customer_orders/zones/lite?service_type=${service}&${params}`,
    );
  },

  search({ servicePartnerId, service, query, start_date, end_date }) {
    let params = `start_date=${start_date}&end_date=${end_date}`;

    if (service.includes("laundry")) {
      const type = service.split("_")[1];
      params += `&schedule_type=${
        type === "delivery" ? "deliver_to_customer" : "pickup_from_customer"
      }`;
      service = "laundry";
    }

    if (servicePartnerId && servicePartnerId !== "all") {
      return axios.get(
        `customer_orders/zones/${servicePartnerId}/search/${query}?${params}`,
      );
    }

    return axios.get(
      `customer_orders/zones/no_sp/search/${query}?service_type=${service}&${params}`,
    );
  },

  export({ servicePartnerId, period, start_date, end_date }) {
    const sp = servicePartnerId === "all" ? "" : `${servicePartnerId}/`;
    let params = `start_date=${start_date}&end_date=${end_date}`;
    if (period === "custom") {
      return axios.get(
        `${sp}customer_orders/for_stewards/zones/export?period=${period}&${params}`,
      );
    }
    return axios.get(
      `${sp}customer_orders/for_stewards/zones/export?period=${period}`,
    );
  },

  activity(service, payload) {
    return axios.post(`crm/operations/${service}`, payload);
  },

  logisticsOrders(payload) {
    return axios.post(`crm/order/upload/google-sheet`, payload);
  }
};
