import axios from "axios";

export default {
  index(servicePartnerId, period, page) {
    return axios.get(
      `${servicePartnerId}/customer_orders/zones?page=${page}&period=${period}`
    );
  },

  order(servicePartnerId, date) {
    return axios.get(`${servicePartnerId}/customer_orders/zones?from=${date}`);
  },

  search(servicePartnerId, query, period) {
    return axios.get(
      `stewards/customer_orders/zones/${servicePartnerId}/search/${query}?period=${period}`
    );
  },

  filter(payload) {
    return axios.post("stewards/customer_orders/filter", payload);
  },

  pack(payload) {
    return axios.post("customer_order/update_packed_status", payload);
  },

  export(servicePartnerId, period) {
    return axios.get(
      `${servicePartnerId}/customer_orders/for_stewards/zones/export?period=${period}`
    );
  },

  mark(payload) {
    return axios.post("crm/ofa/order", payload);
  },

  history(page) {
    return axios.get(`crm/ofa/daily/summary?page=${page}`);
  },

  filterDate(start, end) {
    return axios.get(
      `crm/ofa/daily/summary?start_date=${start}&end_date=${end}`
    );
  },
};
