<template>
  <div class="px-2 py-1">
    <template v-if="loading">Loading...</template>
    <template v-if="notifications.length">
      <div v-for="(group, groupName) in groupedNotifications" :key="groupName">
        <p class="text-bold bg-sidenav py-1 fs-12">{{ groupName }}</p>
        <div
          v-for="(notification, index) in group"
          :key="index"
          style="position: relative"
          class="border-bottom pa-1 border-grey-senary"
          :class="{ 'bg-grey-octonary': !notification.read }"
        >
          <div @click="markNotificationsAsRead(notification._id)">
            <p class="text-bold font-bold">
              {{ notification.payload.title }}
            </p>
            <p class="text-pointer">
              {{ notification.payload.message }}
            </p>
            <div
              v-if="!notification.read"
              class="mr-1"
              style="
                position: absolute;
                top: 5px;
                right: 0;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: var(--eden-green-primary);
              "
            ></div>
            <div class="is-flex is-flex-column">
              <a
                :href="notification.payload.url"
                class="text-primary text-medium font-base"
                >View More</a
              >
              <small>{{
                calculateTimeDifference(notification.createdAt)
              }}</small>
            </div>
          </div>
        </div>
      </div>

      <div class="is-flex gap-1 is-justify-end text-cursor mt-2">
        <el-button
          type="primary"
          :size="'small'"
          @click="previous"
          :disabled="isPreviousButtonDisabled"
          >Previous</el-button
        >
        <el-button
          type="primary"
          :size="'small'"
          @click="next"
          :disabled="!showPagination"
          >Next</el-button
        >
      </div>
    </template>
    <template v-else>
      <eden-content-empty :text="'No Notifications'" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
    total: Number,
    showPagination: Boolean,
  },
  data() {
    return {
      applicationIdentifier: process.env.VUE_APP_NOVU_APP_IDENTIFIER,
      page: 0,
    };
  },
  computed: {
    user() {
      const { logged_in_userId } = this.$store.getters.user;
      const userId = logged_in_userId;

      return {
        userId,
      };
    },
    isPreviousButtonDisabled() {
      return this.page === 0;
    },
    groupedNotifications() {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      return this.notifications.reduce((accumulator, notification) => {
        const date = new Date(notification.createdAt);
        let groupName = "";

        if (
          date.getDate() === today.getDate() &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear()
        ) {
          groupName = "Today";
        } else if (
          date.getDate() === yesterday.getDate() &&
          date.getMonth() === yesterday.getMonth() &&
          date.getFullYear() === yesterday.getFullYear()
        ) {
          groupName = "Yesterday";
        } else {
          groupName = this.formatDate(date);
        }

        if (!accumulator[groupName]) {
          accumulator[groupName] = [];
        }

        accumulator[groupName].push(notification);
        return accumulator;
      }, {});
    },
  },
  watch: {
    page(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.$emit("update-list", this.page);
      }
    },
  },
  methods: {
    formatDate(date) {
      const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      return `${weekdays[date.getDay()]}, ${
        months[date.getMonth()]
      } ${date.getDate()}, ${date.getFullYear()}`;
    },
    markNotificationsAsRead(messageIds) {
      this.$emit("markRead", messageIds);
    },
    calculateTimeDifference(createdAt) {
      const now = new Date();
      const notificationDate = new Date(createdAt);

      const timeDifference = now - notificationDate;
      const minutesDifference = Math.round(timeDifference / (1000 * 60));

      if (minutesDifference < 60) {
        return `${minutesDifference} minutes ago`;
      } else {
        return `${this.formatTime(createdAt, "12h")}`;
      }
    },
    previous() {
      if (this.page > 0) {
        this.page -= 1;
        this.$emit("update-list", this.page);
      }
    },
    next() {
      this.page += 1;
      this.$emit("update-list", this.page);
    },
  },
};
</script>
