import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = () => ({
  production_provisions: {},
  combo_check: {},
  combo_history: {},
  ofa_history: {},
});

export default {
  state,
  getters,
  actions,
  mutations,
};
