import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = () => ({
  service_partners_list: {
    beauty: {},
    carebox: {},
    cleaning: {},
    laundry: {},
    meal: {},
  },
  servicepartners: {
    total: null,
    pages: {},
  },
  provisioned_orders: {
    beauty: {},
    cleaning: {},
    laundry: {},
    meal: {},
  },
});

export default {
  state,
  getters,
  actions,
  mutations,
};
