import * as types from "../../mutation-types";

export default {
  [types.CUSTOMERS_SUMMARY](state, customers_summary) {
    state.customers_summary = customers_summary;
  },

  [types.CUSTOMERS_INDIVIDUAL](state, { page, data }) {
    state.customers_individual.total = data.total;
    state.customers_individual.pages[page] = {};
    state.customers_individual.pages[page].from = data.from;
    state.customers_individual.pages[page].to = data.to;
    state.customers_individual.pages[page].data = data.data;
  },

  [types.CUSTOMERS_CORPORATE](state, { page, data }) {
    state.customers_corporate.total = data.total;
    state.customers_corporate.pages[page] = {};
    state.customers_corporate.pages[page].from = data.from;
    state.customers_corporate.pages[page].to = data.to;
    state.customers_corporate.pages[page].data = data.data;
  },

  [types.CUSTOMERS_ONETIME](state, { page, data }) {
    state.customers_onetime.total = data.total;
    state.customers_onetime.pages[page] = {};
    state.customers_onetime.pages[page].from = data.from;
    state.customers_onetime.pages[page].to = data.to;
    state.customers_onetime.pages[page].data = data.data;
  },

  [types.CUSTOMER_SCHEDULE](state, { period, data }) {
    state.customer_schedule[period] = [];
    state.customer_schedule[period] = data;
  },

  [types.CUSTOMER_ORDERS](state, { period, page, data }) {
    /**
     * Reset state if [period] change;
     */
    if (state.customer_orders.period !== period) {
      state.customer_orders = {
        period,
        total: data.total,
        pages: {},
      };
    }

    /**
     * Set [page] data;
     */
    state.customer_orders.pages = {
      [page]: {
        from: data.from,
        to: data.to,
        data: data.data,
      },
    };
  },

  [types.CUSTOMER_TRANSACTIONS](state, { page, data }) {
    state.customer_transactions.total = data.total;
    state.customer_transactions.pages[page] = {};
    state.customer_transactions.pages[page].from = data.from;
    state.customer_transactions.pages[page].to = data.to;
    state.customer_transactions.pages[page].data = data.data.map(
      (transaction) => {
        return {
          ...transaction,
          services: transaction.service
            .split(",")
            .map((service) => service.toLowerCase())
            .sort((a, b) => (a < b ? -1 : 1)),
        };
      }
    );
  },

  [types.RESET_STATE](state, defaultState) {
    Object.assign(state, { ...defaultState.customers });
  },
};
