<template>
  <div>
    <div v-if="orientation === 'horizontal'">
      <ul class="eden-periods">
        <li
          v-for="(value, key, i) in periods"
          :key="i"
          :class="{
            selected: period === key,
            disabled: disabled,
          }"
          @click="disabled ? false : setPeriod(key)"
        >
          {{ value }}
        </li>
        <li
          v-if="custom.from"
          class="custom"
          @click="disabled ? false : setPeriod('custom')"
        >
          <span @click="showCustomPeriodPicker = true"
            >[{{ formatDate(custom.from, "do m, y") }}
            <span v-if="custom.from !== custom.to">
              - {{ formatDate(custom.to, "do m, y") }}</span
            >]</span
          >
        </li>
      </ul>
    </div>
    <div v-else>
      <eden-custom-period-preview
        v-if="custom.from"
        :daterange="custom"
        :loading="loading"
        @change="showCustomPeriodPicker = true"
        @clear="clear"
      />
      <el-dropdown v-else @command="setPeriod">
        <el-button type="plain" :disabled="loading || disabled">
          {{ periods[period] }} <i class="eden-icon-arrow-down"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(value, key, i) in periods"
            :key="i"
            :class="{ selected: key === period }"
            :command="key"
            >{{ value }}</el-dropdown-item
          >
          <el-dropdown-item v-if="showCustomPeriod" :command="'custom'"
            >Custom</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <eden-custom-period
      :show.sync="showCustomPeriodPicker"
      :title="customTitle"
      :button="customButton"
      @confirm="setCustomPeriod($event)"
      @cancel="setPeriod(defaultPeriod)"
    />
  </div>
</template>

<script>
export default {
  name: "EdenPeriods",
  props: {
    orientation: {
      type: String,
      default: "vertical",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultPeriod: {
      type: String,
      default: "today",
    },
    customPeriods: {
      type: Object,
      default() {
        return {};
      },
    },
    addCustomFilter: {
      type: Boolean,
      default: false,
    },
    showCustomPeriod: {
      type: Boolean,
      default: false,
    },
    customTitle: {
      type: String,
      default: "Get data for",
    },
    customButton: {
      type: String,
      default: "Get",
    },
  },
  data() {
    return {
      period: "",
      custom: {
        from: null,
        to: null,
      },
      showCustomPeriodPicker: false,
    };
  },
  computed: {
    periods() {
      let periods = {
        lastweek: "Last week",
        yesterday: "Yesterday",
        today: "Today",
        tomorrow: "Tomorrow",
        nexttwodays: "Next two days",
        thisweek: "This week",
        nextweek: "Next week",
      };

      if (this.addCustomFilter) {
        periods.custom = 'Custom'
      }

      let customPeriods = {
        ...this.customPeriods,
      };

      if (this.orientation === "horizontal" && this.showCustomPeriod) {
        customPeriods.custom = "Custom";
      }
      return Object.keys(this.customPeriods).length ? customPeriods : periods;
    },
  },
  created() {
    this.period = this.defaultPeriod;
  },
  methods: {
    setPeriod(period) {
      if (this.period === period) {
        return;
      }
      this.period = period;
      if (period === "custom") {
        this.showCustomPeriodPicker = true;
        return;
      } else {
        this.custom.from = null;
        this.custom.to = null;
      }
      this.$emit("set-period", {
        period: this.period,
        from: null,
        to: null,
      });
    },
    setCustomPeriod(daterange) {
      this.$emit("set-period", {
        period: "custom",
        from: daterange.from,
        to: daterange.to,
      });
      this.custom.from = daterange.from;
      this.custom.to = daterange.to;
    },
    clear() {
      this.custom.from = null;
      this.custom.to = null;
      this.setPeriod(this.defaultPeriod);
    },
  },
};
</script>

<style lang="scss" scoped>
.eden-periods {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    cursor: pointer;
    color: var(--eden-grey-quaternary);
    font-weight: 400;
    font-size: 0.75rem;
    margin: 0 11px;

    &::marker {
      color: #cbd8d2;
    }

    &:first-child {
      list-style: none;
    }

    &.selected {
      color: var(--eden-green-secondary);
      font-weight: 500;
    }

    &.disabled {
      cursor: not-allowed !important;
    }

    &.custom {
      list-style: none;
      margin-left: 0;
      color: var(--eden-grey-primary);
      font-weight: 500;
    }
  }
}
</style>
