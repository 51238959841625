const Orders = () => import("@/pages/orders/index");
const SingleOrder = () => import("@/pages/orders/order");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "growth",
  "gardener",
  "gardener_pro",
];

const MODULE = "orders";

const routes = () => {
  return [
    {
      path: "/orders",
      name: "orders.index",
      component: Orders,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/orders/:id",
      name: "orders.order",
      component: SingleOrder,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
