import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = () => ({
  feedback_summary: {},
  feedback: {
    happy: {},
    unhappy: {}
  }
});

export default {
  state,
  getters,
  actions,
  mutations
};
