import axios from "axios";

export default {
  list() {
    return axios.get("crm/micro-fulfillment-center");
  },

  index(page) {
    return axios.get(`crm/micro-fulfillment-center?page=${page}`);
  },

  search(query) {
    return axios.get(`crm/micro-fulfillment-center?name=${query}`);
  },

  filter(payload) {
    return axios.post("crm/clusters/all/filter", payload);
  },

  add(payload) {
    return axios.post("crm/micro-fulfillment-center", payload);
  },

  update(payload) {
    return axios.post("crm/micro-fulfillment-center/update", payload);
  },

  delete(id) {
    return axios.delete("crm/micro-fulfillment-center", { data: { center_id: id } });
  },
};
