import axios from "axios";

export default {
  index(page) {
    return axios.get(`payment/links?page=${page}`);
  },

  filter(payload) {
    return axios.post("payment/links/filter", payload);
  },

  add(payload) {
    return axios.post("payment/pages", payload);
  },

  generate(payload) {
    return axios.post("payment/links", payload);
  },
};
