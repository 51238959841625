<template>
  <div class="main--pagination is-flex align-center justify-end">
    <span class="font-sm page--count"
      >{{ from }} - {{ to }} of {{ total }}</span
    >

    <el-input type="text" v-model.number="pageInput" v-number placeholder="1">
      <span slot="append" @click="goToPage">
        <i class="el-icon-arrow-right"> </i>
      </span>
    </el-input>

    <el-pagination
      :page-size="pageSize"
      background
      layout="prev, pager, next"
      :total="total"
      :current-page="setPage"
      @current-change="updateCurrentPage"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "EdenPagination",
  props: {
    from: {
      type: Number,
      default: 1,
    },
    to: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 1,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {
      pageInput: 1,
    };
  },
  computed: {
    setPage: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.$emit("update:currentPage", value);
      },
    },
    lastPage() {
      return Math.max(1, Math.ceil(this.total / this.pageSize));
    },
  },
  created() {
    this.pageInput = this.setPage;
  },
  methods: {
    updateCurrentPage(page) {
      this.pageInput = page;
      this.setPage = page;
    },
    goToPage() {
      if (this.pageInput === this.setPage) {
        return;
      }

      if (this.pageInput > this.lastPage) {
        this.$message.error({
          message: "Page number out of range",
          duration: 3000,
        });
        return;
      }

      if (this.pageInput) {
        this.setPage = this.pageInput;
      } else {
        this.$message.error({
          message: "You have to specify a page number",
          duration: 3000,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.main--pagination {
  margin-top: 32px;
  .page--count {
    color: #4b6358;
    font-weight: 400;
    margin-right: 16px;
  }
  .el-input {
    border-radius: 8px;
    width: 80px;
    height: 38px;
    padding: 10px 5px;
    color: #0f241b;
    margin-right: 16px;

    .el-input__inner {
      border-right: none;
      padding: 0 10px !important;
      text-align: center;

      &:focus + .el-input-group__append {
        border-color: #03a84e !important;
      }
    }

    .el-input-group__append {
      cursor: pointer;
      background: transparent;
      border-left: none;
      padding: 0 8px;
    }
  }
}
</style>
