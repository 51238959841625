import * as mutations from "@/store/mutation-types";
import * as actions from "@/store/action-types";
import messages from "@/requests/communications/messages";

export default {
  [actions.GET_BULK_MESSAGES](context, payload) {
    return new Promise((resolve, reject) => {
      messages
        .index(payload)
        .then((response) => {
          const { status, data, meta } = response.data;
          if (status) {
            context.commit(mutations.BULK_MESSAGES, {
              page: payload.page,
              data: {
                data,
                from: meta.from,
                to: meta.to,
                perPage: meta.per_page,
                total: meta.total,
              },
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
