import * as types from "../../mutation-types";

export default {
  [types.ACTIVITIES](state, { period, page, activities }) {
    state.activities[period] = {};
    state.activities[period][page] = activities;
  },

  [types.RESET_STATE](state, defaultState) {
    Object.assign(state, defaultState.activities);
  }
};
