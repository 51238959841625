<template>
  <div class="page-stat">
    <div class="page-stat--head">
      <p v-if="title" class="title">{{ title }}</p>
      <div class="actions" :style="{ width: !title ? '100%' : 'auto' }">
        <eden-periods
          v-if="showPeriods"
          :orientation="'horizontal'"
          :custom-periods="customPeriods"
          :show-custom-period="showCustomPeriod"
          :default-period="defaultPeriod"
          :disabled="disabled"
          @set-period="$emit('set-period', $event)"
        />
        <slot name="actions" />
      </div>
    </div>

    <div class="page-stat--body">
      <div
        v-for="(stat, index) in stats"
        :key="index"
        :style="{
          width: cardWidth,
        }"
        class="page-stat--card"
      >
        <div class="stat">
          <div class="stat-figure">
            <h5 :class="size">{{ loading ? "-" : stat.figure }}</h5>
            <img
              src="@/assets/img/expand-stat.svg"
              class="stat-expand"
              alt="expand"
            />
            <span v-if="stat.percentage_change" class="stat-increase">
              <i class="el-icon-top"></i> 5.5%
            </span>
            <el-tag
              v-if="stat.statusTag"
              :type="stat.statusTag.type"
              class="pt-1"
              >{{ stat.statusTag.text }}</el-tag
            >
          </div>
          <div class="stat-description">
            <p class="font-sm text-grey-tertiary">
              {{ stat.label }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EdenPageStats",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    showPeriods: {
      type: Boolean,
      default: false,
    },
    periods: {
      type: Object,
      default() {
        return {};
      },
    },
    defaultPeriod: {
      type: String,
      default: "today",
    },
    showCustomPeriod: {
      type: Boolean,
      default: true,
    },
    stats: {
      type: Array,
      default() {
        return [];
      },
    },
    size: {
      type: String,
      default: "regular",
    },
  },
  data() {
    return {};
  },
  computed: {
    cardWidth() {
      const length = this.stats.length;
      if (length <= 4) {
        return "25%";
      } else if (length >= 7) {
        return "20%";
      } else {
        return `${(100 / length - 1).toFixed(0)}%`;
      }
    },
    customPeriods() {
      return Object.keys(this.periods).length
        ? this.periods
        : {
            today: "Today",
            thisweek: "This week",
            thismonth: "This month",
          };
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.page-stat {
  margin: 50px 0 40px;
  border-bottom: 1px solid #f0f4f2;

  &--head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    .title {
      color: var(--eden-grey-primary);
      font-size: 1rem;
      font-weight: 500;
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &--periods {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      cursor: pointer;
      color: var(--eden-grey-quaternary);
      font-weight: 400;
      font-size: 0.75rem;
      margin: 0 11px;

      &::marker {
        color: #cbd8d2;
      }

      &:first-child {
        list-style: none;
      }

      &.selected {
        color: #14b757;
        font-weight: 500;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }
  }

  &--body {
    display: flex;
    flex-wrap: wrap;
    margin-top: 26px;
    width: 100%;
  }

  &--card {
    padding: 30px 10px;
    transition: all 0.3s ease-in-out;

    .stat {
      &-figure {
        display: flex;
        align-items: center;
        color: var(--eden-grey-primary);
        margin-bottom: 7px;
        position: relative;

        h5 {
          &.small {
            font-size: 1.75rem;
          }
        }

        .stat-expand {
          cursor: pointer;
          position: absolute;
          opacity: 0;
          right: 25%;
          top: 10%;
        }

        span {
          font-size: 14px;
          line-height: 15.24px;

          &.stat-increase {
            color: #14b757;
          }

          &.stat-decrease {
            color: #f40529;
          }
        }
      }

      &-description {
        color: #4b6358;

        .body-text {
          font-weight: 400;
        }
      }
    }

    @media (max-width: 756px) {
      margin: 20px;
    }
  }
}
</style>
