import * as Bytescale from "@bytescale/sdk";

const byteScaleConfig = {
  apiKey: process.env.VUE_APP_BYTESCALE_API_KEY,
};

const CLOUDINARY = "https://res.cloudinary.com/eden-life-inc/image/upload";
const IMAGE = `${CLOUDINARY}/w_500,f_jpg,q_auto/v1610021885/lighthouse/mealImages/NO_IMAGE_zweij9.png`;
const AVATAR = `${CLOUDINARY}/v1593165834/lighthouse/avatar-placeholder_uxleqd.png`;

// const IMAGE = `https://upcdn.io/FW25bgi/raw/uploads/crm/NO_IMAGE_zweij9.jpeg`;
// const AVATAR = `https://upcdn.io/FW25bgi/raw/uploads/crm/avatar-placeholder_uxleqd.png`;

export default {
  methods: {
    async uploadFile(payload) {
      const uploadManager = new Bytescale.UploadManager({
        apiKey: byteScaleConfig.apiKey,
      });

      const appEnvironment = process.env.NODE_ENV;

      const { fileUrl } = await uploadManager.upload({
        // Supported types:
        // - String
        // - Blob
        // - ArrayBuffer
        // - File (i.e. from a DOM file input element)
        data: payload.raw,
        path: {
          folderPath: `/uploads/crm/${appEnvironment}`,
        },
      });
      return fileUrl;
    },
    /**
     * Get and set image source (locally or via url)
     * @param image
     * @returns {string|*}
     */
    getImage(image) {
      if (!image) {
        return IMAGE;
      }

      if (image.indexOf("http") < 0) {
        return require("../assets/img/" + image);
      }
      return image;
    },

    /**
     * Get and set image representation for a service;
     * @param service
     * @returns {*|string}
     */
    getServiceImage(service) {
      return service ? require(`../assets/img/services/${service}.png`) : IMAGE;
    },

    /**
     * Get and set image representation for a card
     * @param type
     * @returns {*}
     */
    getCardImage(type, format) {
      try {
        return type && format
          ? require("../assets/img/cards/" + type + `.${format}`)
          : require("../assets/img/cards/visa.svg");
      } catch (ex) {
        console.log(ex);
        return require("../assets/img/cards/visa.svg");
      }
    },
    /**
     * Get an optimized version of an image from Cloundinary
     * @param url
     * @returns {string}
     */
    getThumbnailFromCloudinary(url) {
      if (url.includes("cloudinary.com")) {
        const imageUrl = url.split("upload");
        const base = imageUrl[0];
        const image = imageUrl[1];
        return `${base}upload/w_150,h_150,c_thumb,q_auto,f_auto${image}`;
      }
      return url;
    },
    getAvatar(image) {
      if (!image) {
        return require("../assets/img/profile-avatar.svg");
      }
      return image;
    },
    getDefaultAvatarUrl() {
      return AVATAR;
    },
    getCountryFlag(image) {
      if (!image) {
        return "";
      }
      return require(`../assets/img/flags/${image}.svg`);
    },
  },
};
