<template>
  <div class="eden-crm">
    <div :class="['eden-crm--sidebar', { open: sidebarOpen }]">
      <eden-crm-sidebar
        :key="key"
        @update-key="updateKey"
        @close="sidebarOpen = false"
        @calculator="showCalculator = true"
      />
    </div>
    <div class="eden-crm--dashboard">
      <div v-if="impersonating" class="eden-crm--dashboard__impersonation">
        <span class="font-sm">
          Impersonating - <strong>{{ role }}.</strong>
        </span>
        <el-button type="primary" @click="stopImpersonation" size="small">
          Stop
        </el-button>
      </div>
      <transition name="fade" mode="out-in">
        <div :key="key">
        <keep-alive>
        <slot />
        </keep-alive>
        </div>
      </transition>
    </div>
    <div class="eden-crm--sidebar-toggle" @click="sidebarOpen = true">
      <i class="eden-icon-dashboard" />
    </div>
    <transition name="overlay-fade" mode="out-in">
      <div
        v-if="sidebarOpen"
        class="overlay"
        @click="sidebarOpen = false"
      ></div>
    </transition>
    <eden-pricing-calculator :show.sync="showCalculator" />
    <eden-confirm-dialog
      title="Reload page"
      button-text="Reload"
      button-type="success"
      :show.sync="showConfirmDialog"
      @confirm="reloadApp"
    >
      <span class="font-base">
        The data on this page might be outdated. Do you want to reload the app?
      </span>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import EdenCrmSidebar from "@/components/Navigation/EdenCrmSidebar";
import * as mutations from "@/store/mutation-types";
import * as actions from "@/store/action-types";
import EdenPricingCalculator from "@/components/Navigation/EdenPricingCalculator";

export default {
  name: "AppLayout",
  components: {
    EdenPricingCalculator,
    EdenCrmSidebar,
  },
  data() {
    return {
      key: "",
      sidebarOpen: false,
      showCalculator: false,
      timeout: null,
      showConfirmDialog: false,
    };
  },
  computed: {
    impersonating() {
      return this.$store.getters.impersonate;
    },
    role() {
      const role = this.$store.getters.user_role;
      return this.roles()[role];
    },
  },
  mounted() {
    const { id, full_name, email, role } = this.$store.getters.user;
    this.mixpanelIdentify(id);
    this.mixpanelPeople({
      email,
      full_name,
      id,
      role,
    });
    this.mixpanelTrack({ event: "reload " });
    const events = [
      "mousedown",
      "mousemove",
      "keypress",
      "scroll",
      "touchstart",
    ];
    events.forEach((event) => {
      document.addEventListener(event, this.trackIdleTime);
    });
  },
  created() {
    this.key = new Date().getTime();
    this.$store.dispatch(actions.GET_SERVICES);
    this.$store.dispatch(actions.GET_MENU_VARIATIONS);
  },
  methods: {
    stopImpersonation() {
      const impersonatedRole = this.$store.getters.user_role;
      const { role } = this.$store.getters.user;

      this.$store.commit(mutations.USER_ROLE, role);
      this.$store.commit(mutations.IMPERSONATE, false);

      if (["steward", "ofa"].includes(impersonatedRole)) {
        this.$router.push({ name: "customers.index" });
      } else {
        this.updateKey();
      }
    },
    trackIdleTime() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.showConfirmDialog = true;
      }, 300000);
    },
    updateKey() {
      this.key = new Date().getTime();
    },
    reloadApp() {
      window.location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.eden-crm {
  height: 100vh;
  width: 100%;
  display: flex;
  position: relative;

  &--sidebar {
    height: 100vh;
    width: var(--sidenav-width);
  }

  &--dashboard {
    height: 100vh;
    width: calc(100% - #{var(--sidenav-width)});
    padding: 30px;
    background: #ffffff;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;

    &::-webkit-scrollbar {
      width: 5px;
      position: absolute;
      right: 0;
    }

    &::-webkit-scrollbar-track {
      background: #ccc;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--eden-green-primary);
      border-radius: 8px;
    }

    &__impersonation {
      position: absolute;
      z-index: 9;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100% !important;
      background-color: #fff8e1;
      color: #0f241b;
      padding: 5px 10px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .el-button {
        margin-left: 12px;
      }

      + div {
        padding-top: 30px;
      }
    }
    > * {
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  &--sidebar-toggle {
    position: fixed;
    z-index: 10;
    right: 30px;
    bottom: 30px;
    height: 48px;
    width: 48px;
    border-radius: 100px;
    background: var(--eden-green-primary);
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
      color: #ffffff;
      font-weight: 600;
      font-size: 1.125rem;
    }
  }

  @media (max-width: 1200px) {
    &--sidebar {
      position: absolute;
      left: calc(var(--sidenav-width) * -1);
      z-index: 12;
      transition: left 0.25s ease-out;

      &.open {
        left: 0;
        transition: left 0.25s ease-in;
      }

      &-toggle {
        display: flex;
      }
    }

    &--dashboard {
      width: 100%;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;

  &-fade-enter-active,
  &-fade-leave-active {
    transition-duration: 0.4s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  &-fade-enter,
  &-fade-leave-active {
    opacity: 0;
  }
}
</style>
