const Curations = () => import("@/pages/curations/index");
const CurationsDetails = () => import("@/pages/curations/curation-details");

const MODULE = "curations";

const authenticatedRoles = ["superadmin", "admin", "growth", "vendor_manager"];

const routes = () => {
  return [
    {
      path: "/curations",
      name: "curations.index",
      component: Curations,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/curations/:id",
      name: "curations.details",
      component: CurationsDetails,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
