import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = () => ({
  members: {
    total: null,
    pages: {},
  },
});

export default {
  state,
  getters,
  actions,
  mutations,
};
