import axios from "axios";

export default {
  index(page) {
    return axios.get(`crm/rider?page=${page}`);
  },

  search(query) {
    return axios.get(`crm/rider?name=${query}`);
  },

  add(payload) {
    return axios.post("crm/rider", payload);
  },

  get(id) {
    return axios.get(`crm/rider?rider_id=${id}`);
  },

  update(payload) {
    return axios.post("crm/rider/update", payload);
  },

  delete(payload) {
    return axios.post("crm/rider/delete", payload);
  },
};
