<template>
  <p>Loading</p>
</template>

<script>
export default {
  name: "EdenLoader",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 10px;
}
</style>
