import { render, staticRenderFns } from "./EdenAlert.vue?vue&type=template&id=6d37a450&"
import script from "./EdenAlert.vue?vue&type=script&lang=js&"
export * from "./EdenAlert.vue?vue&type=script&lang=js&"
import style0 from "./EdenAlert.vue?vue&type=style&index=0&id=6d37a450&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports