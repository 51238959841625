import * as types from "../../mutation-types";

export default {
  [types.ORDERS_SUMMARY](state, summary) {
    state.orders_summary = summary;
  },

  [types.ORDERS](state, { period, page, data }) {
    /**
     * Reset state if [period] changes;
     */
    if (state.orders.period !== period) {
      state.orders = {
        period,
        total: data.total,
        pages: {},
      };
    }

    /**
     * Set [page] data;
     */
    state.orders.pages[page] = {
      from: data.from,
      to: data.to,
      data: data.data,
    };
  },

  [types.ORDERS_SCHEDULE](state, { service, sp, period, page, data }) {
    /**
     * Reset state if [period] or [servicePartner] changes;
    //  */
    // const stateSp = state.orders_schedule[service].sp;
    // const statePeriod = state.orders_schedule[service].period;

    // if (statePeriod !== period || stateSp !== sp) {
      // console.log(data.total, data);
      state.orders_schedule[service] = {
        sp,
        period,
        total: data.total,
        pages: {},
      };
    // }

    /**
     * Set [page] data;
     */
    state.orders_schedule[service].pages = {
      [page]: {
        from: data.from ?? null,
        to: data.to ?? null,
        data: data.data ?? [],
      },
    };
  },

  [types.RESET_STATE](state, defaultState) {
    Object.assign(state, { ...defaultState.orders });
  },
};
