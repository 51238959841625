import * as mutations from "../../mutation-types";
import * as actions from "../../action-types";
import feedback from "@/requests/feedback/feedback";

export default {
  [actions.GET_FEEDBACK_SUMMARY](context, { period, range }) {
    return new Promise((resolve, reject) => {
      feedback
        .summary(period, range)
        .then(response => {
          if (response.data.status) {
            context.commit(mutations.FEEDBACK_SUMMARY, {
              period,
              feedback_summary: response.data.data
            });
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [actions.GET_FEEDBACK](context, { period, type, page }) {
    return new Promise((resolve, reject) => {
      feedback
        .index(period, type, page)
        .then(response => {
          if (response.data.status) {
            context.commit(mutations.FEEDBACK, {
              type,
              page,
              feedback: response.data.data
            });
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
