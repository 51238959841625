const Tags = () => import("@/pages/tags/index");
const TagsDetails = () => import("@/pages/tags/tag-details");

const MODULE = "tags";

const authenticatedRoles = [
  "superadmin",
  "admin",
  "growth",
  "vendor_manager",
  "gardener",
  "gardener_pro",
];

const routes = () => {
  return [
    {
      path: "/tags",
      name: "tags.index",
      component: Tags,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/tags/:id",
      name: "tags.details",
      component: TagsDetails,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
