const Feedback = () => import("@/pages/feedback/feedback/index");
const FeedbackType = () => import("@/pages/feedback/feedback/type");
const FeedbackBase = () => import("@/pages/feedback/feedback/base");

const Issues = () => import("@/pages/feedback/issues/index");
const Issue = () => import("@/pages/feedback/issues/issue");
const IssuesCategories = () => import("@/pages/feedback/issues/categories");

const Suggestions = () => import("@/pages/feedback/suggestions/index");
const Suggestion = () => import("@/pages/feedback/suggestions/suggestion");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "growth",
  "gardener",
  "gardener_pro",
];

const routes = () => {
  return [
    /**
     * Redirect to Issues Tracker page if someone tries to
     * access main module route [.../feedback] without
     * specifying a sub-module  [.../feedback/:sub-module]
     * Note: This should be changed when the main Feedback route
     *       is active.
     */
    {
      path: "/feedback",
      redirect: {
        name: "feedback.issues.index",
        component: Issues,
      },
    },
    /**
     * Feedback Sub-Module
     */
    {
      path: "/feedback/overview",
      name: "feedback.feedback.index",
      component: Feedback,
      meta: {
        module: "feedback",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/feedback/overview/",
      component: FeedbackBase,
      meta: {
        module: "feedback",
        permission: authenticatedRoles,
        layout: "app",
      },
      children: [],
    },
    {
      path: "/feedback/feedback/:type",
      name: "feedback.type",
      component: FeedbackType,
      meta: {
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    /**
     * Issues Tracker Sub-Module
     */
    {
      path: "/feedback/issues",
      name: "feedback.issues.index",
      component: Issues,
      meta: {
        module: "issues",
        permission: authenticatedRoles.concat(["ofa", "steward"]),
        layout: "app",
      },
    },
    {
      path: "/feedback/issues/:id",
      name: "feedback.issues.issue",
      component: Issue,
      meta: {
        module: "issues",
        permission: authenticatedRoles.concat(["ofa", "steward"]),
        layout: "app",
      },
    },
    {
      path: "/feedback/issues-categories",
      name: "feedback.issues.categories",
      component: IssuesCategories,
      meta: {
        module: "issues",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    /**
     * Suggestions Log Sub-Module
     */
    {
      path: "/feedback/suggestions",
      name: "feedback.suggestions.index",
      component: Suggestions,
      meta: {
        module: "suggestions",
        permission: authenticatedRoles.concat(["ofa", "steward"]),
        layout: "app",
      },
    },
    {
      path: "/feedback/suggestions/:id",
      name: "feedback.suggestions.suggestion",
      component: Suggestion,
      meta: {
        module: "suggestions",
        permission: authenticatedRoles.concat(["ofa", "steward"]),
        layout: "app",
      },
    },
  ];
};

export default routes();
