// Globally register all Eden components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their file name, which is how they are saved

import Vue from "vue";

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
  // Look for files in the current directory
  ".",
  // Do not look in subdirectories
  false,
  // Only include "Eden" prefixed .vue files
  /Eden[\w-]+\.vue$/
);

// For each matching file name...
requireComponent.keys().forEach((fileName) => {
  // Get the component config
  const componentConfig = requireComponent(fileName);
  const componentName = fileName
    // Remove the "./" from the beginning
    .replace(/^\.\//, "")
    // Remove the file extension from the end
    .replace(/\.\w+$/, "");

  // Globally register the component
  Vue.component(componentName, componentConfig.default || componentConfig);
});
