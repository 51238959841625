<template>
  <el-dialog :visible.sync="shouldShow" @close="closeEvent" width="30%">
    <template slot="title">
      <p class="dialog-header-title">{{ title }}</p>
      <span>
        You can find our excel template
        <a :href="templateUrl" target="_blank" class="text-primary">here</a>
      </span>
    </template>
    <el-row type="flex" :gutter="15" class="is-flex-wrap" v-if="$route.query.company_name && !subscriptionId">
      <el-col :md="24">
        <el-form :model="form" label-position="top" ref="form">
          <el-form-item label="Subscription" prop="subscription_id" :rules="validateField()">
            <el-select v-model="form.subscription_id" filterable placeholder="Select subscription">
              <el-option v-for="sub in subscriptions" :key="sub.id" :label="sub.name" :value="sub.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>

    </el-row>
    <vue-csv-import v-model="csv" :url="url" :auto-match-fields="true" :callback="handleSuccess" :catch="handleError"
      :button-class="'el-button el-button--plain'" headers="headers" :map-fields="fields" ref="csvImport">
      <template slot="error">
        File type is invalid. It has to be a csv file (e.g Excel, Google
        Spreadsheets)
      </template>

      <template slot="next">
        <div class="">
          <el-button plain @click="setHeaders">Set Headers</el-button>
        </div>
      </template>

      <template slot="submit">
        <div class="is-flex is-justify-end">
          <el-button plain @click="shouldShow = false">Cancel</el-button>
          <el-button type="primary" @click="uploadCSV" :loading="setUploadingStatus">Upload File</el-button>
        </div>
      </template>
    </vue-csv-import>
  </el-dialog>
</template>

<script>
import { VueCsvImport } from "vue-csv-import";

export default {
  name: "EdenUploadCsv",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    uploading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subscriptionId: {
      type: [String, Number]
    },
    templateUrl: {
      type: String,
      default: "",
    },
    fields: {
      type: Array,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    subscriptions: {
      type: Array
    }
  },
  components: {
    VueCsvImport,
  },
  data() {
    return {
      csv: [],
      form: {
        subscription_id: ''
      }
    };
  },
  watch: {
    show() {
      if (this.show) {
        setTimeout(() => {
          this.styleInput();
        }, 100);
      }
    },

    "form.subscription_id"(newval) {
      this.$emit('saveSubscriptionId', newval)
    },
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    setUploadingStatus: {
      get() {
        return this.uploading;
      },
      set(value) {
        this.$emit("update:uploading", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    styleInput() {
      const input = document.querySelector(
        ".vue-csv-uploader .csv-import-file"
      );
      let node = document.createTextNode("Click here to upload file");
      let label = document.createElement("span");
      label.appendChild(node);
      input.appendChild(label);

      const fileInput = document.querySelector(
        '.csv-import-file [type="file"]'
      );

      fileInput.onchange = () => {
        if (!fileInput.value) return;

        let value = fileInput.value.replace(/^.*[\\/]/, "");

        const labelChange = document.querySelector(".csv-import-file span");
        labelChange.classList.add("chosen");
        labelChange.innerHTML = value;
      };
    },
    setHeaders() {
      this.$refs.csvImport.load();
    },
    handleError(error) {
      this.setUploadingStatus = false;
      this.$message.error(error.response.data.message);
    },
    uploadCSV() {
      this.setUploadingStatus = true;
      this.$emit("mapped", this.$refs.csvImport.csv);
    },
    handleSuccess(response) {
      if (response.data.status) {
        this.setUploadingStatus = false;
        this.closeEvent();
        this.$message.success(response.data.message);
        this.$emit("success");
      } else {
        this.uploading = false;
        this.$message.error(response.data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
