<template>
  <div v-if="type === 'multiple'">
    <el-tag
      v-for="(service, index) in sortedServices"
      :key="index"
      :type="service"
      >{{ formatService(service) }}
      {{
        service === "meal" && subscription.menuType
          ? `(${subscription.menuType})`
          : ""
      }}</el-tag
    >
  </div>
  <el-tag v-else :type="service.toLowerCase()">{{
    formatService(service)
  }}</el-tag>
</template>

<script>
import * as actions from "@/store/action-types";

export default {
  name: "EdenServicesList",
  props: {
    type: {
      type: String,
      default: "multiple",
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    subscription: {
      type: Object,
      default() {
        return {};
      },
    },
    service: {
      type: String,
      default: "info",
    },
    services: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  mounted() {
    if (this.menuTypes.length === 0) {
      this.$store.dispatch(actions.GET_MENU_TYPES);
    }
  },
  computed: {
    sortedServices() {
      return this.sortArray({
        data: this.parseData(this.services),
      });
    },
    menuTypes() {
      return this.$store.getters.menu_types;
    },
    menuType() {
      return this.menuTypes.find(
        (item) => item.id === this.config.meal?.menu_type_variation_id,
      );
    },
  },
  methods: {
    formatService(service) {
      return service.includes("meal") ? "Food" : this.formatText(service);
    },
  },
};
</script>
