<template>
  <el-dropdown @command="setServicePartner">
    <el-button type="plain" :disabled="loading"
      >{{ setServicePartnerName === "all" ? "All SPs" : servicePartnerName
      }}<i class="eden-icon-arrow-down"></i
    ></el-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-if="allowAll"
        :command="'all'"
        :class="{ selected: servicePartnerId === 'all' }"
        >All SPs</el-dropdown-item
      >
      <el-dropdown-item
        v-for="(sp, i) in servicePartners"
        :key="i"
        :command="sp.id"
        :class="{ selected: servicePartnerId === sp.id }"
        >{{ sp.name }}</el-dropdown-item
      >
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import * as actions from "@/store/action-types";

export default {
  name: "EdenServicePartners",
  props: {
    allowAll: {
      type: Boolean,
      default: false,
    },
    servicePartnerId: {
      type: [String, Number],
      default: null,
    },
    servicePartnerName: {
      type: String,
      default: "",
    },
    service: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    servicePartners() {
      return this.$store.getters.service_partners_list[this.service];
    },
    setServicePartnerId: {
      get() {
        return this.servicePartnerId;
      },
      set(value) {
        this.$emit("update:servicePartnerId", value);
      },
    },
    setServicePartnerName: {
      get() {
        return this.servicePartnerName;
      },
      set(value) {
        this.$emit("update:servicePartnerName", value);
      },
    },
  },
  created() {
    const list = this.$store.getters.service_partners_list[this.service];

    if (!Object.keys(list).length) {
      this.$store.dispatch(actions.GET_SERVICE_PARTNERS_LIST).then(() => {
        this.getServicePartnerName();
      });
    } else {
      this.getServicePartnerName();
    }
  },
  methods: {
    setServicePartner(id) {
      this.setServicePartnerId = id;
      setTimeout(() => {
        this.getServicePartnerName();
      }, 100);
    },
    getServicePartnerName() {
      this.setServicePartnerName =
        this.servicePartnerId === "all"
          ? "all"
          : this.servicePartners[this.servicePartnerId].name;
    },
  },
};
</script>
