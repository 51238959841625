const Vendors = () => import("@/pages/vendors/index");
const VendorDetails = () => import("@/pages/vendors/vendor-details");
const VendorOrders = () => import("@/pages/vendors/vendor-orders");
const VendorEdit = () => import("@/pages/vendors/vendor-form");
const MODULE = "vendors";

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "growth",
  "vendor_manager",
  "gardener",
  "gardener_pro",
];

const routes = () => {
  return [
    {
      path: "/vendors",
      name: "vendors.index",
      component: Vendors,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/vendor/:id",
      name: "vendors.details",
      component: VendorDetails,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/vendor-orders/:id",
      name: "vendor.orders",
      component: VendorOrders,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/vendor/:id/edit",
      name: "vendors.detail.edit",
      component: VendorEdit,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
