import axios from "axios";

export default {
  list() {
    return axios.get(`lighthouse/settings/team_members/all`);
  },

  index(page) {
    return axios.get(`lighthouse/settings/team_members?page=${page}`);
  },

  search(query) {
    return axios.get(`lighthouse/settings/team_members/search/${query}`);
  },

  add(payload) {
    return axios.post("lighthouse/settings/add_team_member", payload);
  },
};
