const Settings = () => import("@/pages/settings/index");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "growth",
  "gardener",
  "gardener_pro",
  "steward",
  "ofa",
];

const routes = () => {
  return [
    {
      path: "/settings",
      name: "settings.index",
      component: Settings,
      meta: {
        module: "settings",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
