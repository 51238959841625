const ProductionProvisions = () =>
  import("@/pages/stewards/production-provisions/index");

const ComboCheck = () => import("@/pages/stewards/combo/index");
const ComboHistory = () => import("@/pages/stewards/combo/history/index");
const ComboSingleHistory = () =>
  import("@/pages/stewards/combo/history/history");

const OrdersFulfillment = () => import("@/pages/stewards/ofa/index");
const OrdersFulfillmentHistory = () =>
  import("@/pages/stewards/ofa/history/index");
const OrderFulfillmentHistory = () =>
  import("@/pages/stewards/ofa/history/history");

const authenticatedRoles = ["steward", "ofa", "superadmin", "admin"];
const otherRoles = [
  "growth",
  "kitchen",
  "operations",
  "gardener",
  "gardener_pro",
];

const routes = () => {
  return [
    {
      path: "/production-provisions",
      name: "production-provisions.index",
      component: ProductionProvisions,
      meta: {
        module: "production-provisions",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/combo-check",
      name: "combo-check.index",
      component: ComboCheck,
      meta: {
        module: "combo-check",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/combo-check/history",
      name: "combo-check.history",
      component: ComboHistory,
      meta: {
        module: "combo-check",
        permission: authenticatedRoles.concat(otherRoles),
        layout: "app",
      },
    },
    {
      path: "/combo-check/history/:date",
      name: "combo-check.date",
      component: ComboSingleHistory,
      meta: {
        module: "combo-check",
        permission: authenticatedRoles.concat(otherRoles),
        layout: "app",
      },
    },
    {
      path: "/order-fulfillment",
      name: "order-fulfillment.index",
      component: OrdersFulfillment,
      meta: {
        module: "order-fulfillment",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/order-fulfillment/history",
      name: "order-fulfillment.history",
      component: OrdersFulfillmentHistory,
      meta: {
        module: "order-fulfillment",
        permission: authenticatedRoles.concat(otherRoles),
        layout: "app",
      },
    },
    {
      path: "/order-fulfillment/history/:date",
      name: "order-fulfillment.date",
      component: OrderFulfillmentHistory,
      meta: {
        module: "order-fulfillment",
        permission: authenticatedRoles.concat(otherRoles),
        layout: "app",
      },
    },
  ];
};

export default routes();
