import * as mutations from "../../mutation-types";
import * as actions from "../../action-types";

import members from "@/requests/settings/members";

export default {
  [actions.GET_MEMBERS](context, page) {
    return new Promise((resolve, reject) => {
      members
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(
              mutations.CUSTOMERS_SUMMARY,
              response.data.data.summary
            );
            context.commit(mutations.MEMBERS, {
              page,
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
