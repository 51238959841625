import axios from "axios";

export default {
  index(page) {
    return axios.get(`transactions/all?page=${page}`);
  },

  search(query) {
    return axios.get(`transactions/search/${query}`);
  },

  filter(payload) {
    return axios.post("transactions/filter/all", payload);
  },

  add(payload) {
    return axios.post("transactions", payload);
  },

  get(id) {
    return axios.get(`transactions/${id}`);
  },

  update(id, payload) {
    return axios.put(`transactions/${id}`, payload);
  },

  addTxnInvoice(payload) {
    return axios.post("transactions/customer_invoice", payload);
  },

  updateTxnInvoice(id, payload) {
    return axios.put(`transactions/${id}/customer_invoice`, payload);
  },

  resendTxnInvoice(payload) {
    return axios.post("transactions/customer_invoice/resend", payload);
  },

  deleteTxn(id) {
    return axios.delete(`transactions/${id}`);
  },

  export() {
    return axios.get("transactions/export/all");
  },
};
