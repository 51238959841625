import * as types from "../../mutation-types";

export default {
  [types.PRODUCTION_PROVISIONS](state, { period, data }) {
    /**
     * Reset state if [period] changes;
     */
    if (state.production_provisions.period !== period) {
      state.production_provisions = {
        period,
        data,
      };
    }
  },

  [types.COMBO_CHECK](state, { period, data }) {
    state.combo_check = {
      period,
      data,
    };
  },

  [types.COMBO_HISTORY](state, { page, data }) {
    /**
     * Reset state if [period] or [servicePartner] changes;
     */

    state.combo_history.pages = {
      [page]: {
        from: data.meta.from,
        to: data.meta.to,
        data: data.data,
      },
    };
  },

  [types.OFA_HISTORY](state, { page, data }) {
    /**
     * Reset state if [period] or [servicePartner] changes;
     */

    state.ofa_history.pages = {
      [page]: {
        from: data.meta.from,
        to: data.meta.to,
        data: data.data,
      },
    };
  },

  [types.RESET_STATE](state, defaultState) {
    Object.assign(state, { ...defaultState.stewards });
  },
};
