import * as types from "../../mutation-types";

export default {
  // [types.CUSTOMERS_SUMMARY](state, customers_summary) {
  //   state.customers_summary = customers_summary;
  // },

  [types.VENDORS_SUMMARY](state, { data }) {
    state.vendors_summary = data;
  },
  [types.VENDORS_ACTIVE](state, { page, data, meta }) {
    state.vendors_active.total = meta.total;
    state.vendors_active.pages[page] = {};
    state.vendors_active.pages[page].from = meta.from;
    state.vendors_active.pages[page].to = meta.to;
    state.vendors_active.pages[page].data = data;
    state.vendors_active.per_page = meta.per_page;
  },
  [types.VENDORS_INACTIVE](state, { page, data, meta }) {
    state.vendors_inactive.total = meta.total;
    state.vendors_inactive.pages[page] = {};
    state.vendors_inactive.pages[page].from = meta.from;
    state.vendors_inactive.pages[page].to = meta.to;
    state.vendors_inactive.pages[page].data = data;
    state.vendors_inactive.per_page = meta.per_page;
  },
  [types.VENDORS_PENDING](state, { page, data, meta }) {
    state.vendors_pending.total = meta.total;
    state.vendors_pending.pages[page] = {};
    state.vendors_pending.pages[page].from = meta.from;
    state.vendors_pending.pages[page].to = meta.to;
    state.vendors_pending.pages[page].data = data;
    state.vendors_active.per_page = meta.per_page;
  },
  [types.VENDORS_REJECTED](state, { page, data, meta }) {
    state.vendors_rejected.total = meta.total;
    state.vendors_rejected.pages[page] = {};
    state.vendors_rejected.pages[page].from = meta.from;
    state.vendors_rejected.pages[page].to = meta.to;
    state.vendors_rejected.pages[page].data = data;
    state.vendors_active.per_page = meta.per_page;
  },
  [types.VENDORS_DISABLED](state, { page, data, meta }) {
    state.vendors_disabled.total = meta.total;
    state.vendors_disabled.pages[page] = {};
    state.vendors_disabled.pages[page].from = meta.from;
    state.vendors_disabled.pages[page].to = meta.to;
    state.vendors_disabled.pages[page].data = data;
    state.vendors_active.per_page = meta.per_page;
  },
  [types.ACCOUNT_MANAGERS](state, { data }) {
    state.account_managers = data;
  },
};
