<template>
  <div :class="['eden-container', { bordered: border }]">
    <slot />
  </div>
</template>

<script>
export default {
  name: "EdenContainer",
  props: {
    border: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.eden-container {
  padding: 20px 16px;
  max-height: 450px;
  overflow-y: scroll;
  border-radius: 8px;
  border: 1px solid transparent;

  &.bordered {
    border-color: var(--eden-grey-septenary);
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}
</style>
