import date from "@/mixins/date";
import exports from "@/mixins/exports";
import gardener from "@/mixins/gardener";
import image from "@/mixins/image";
import meal from "@/mixins/meal";
import permissions from "@/mixins/permissions";
import pricing from "@/mixins/pricing";
import progress from "@/mixins/progress";
import services from "@/mixins/services";
import sorting from "@/mixins/sorting";
import strings from "@/mixins/strings";
import support from "@/mixins/support";
import tags from "@/mixins/tags";
import validation from "./validation";

import Vue from "vue";

Vue.mixin(date);
Vue.mixin(exports);
Vue.mixin(gardener);
Vue.mixin(image);
Vue.mixin(meal);
Vue.mixin(permissions);
Vue.mixin(pricing);
Vue.mixin(progress);
Vue.mixin(services);
Vue.mixin(sorting);
Vue.mixin(strings);
Vue.mixin(support);
Vue.mixin(tags);
Vue.mixin(validation);
