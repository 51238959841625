import * as types from "../../mutation-types";

export default {
  [types.BULK_MESSAGES](state, { page, data }) {
    state.bulk_messages.total = data.total;
    state.bulk_messages.pages = {
      [page]: {
        from: data.from,
        to: data.to,
        perPage: data.perPage,
        data: data.data,
      },
    };
  },

  [types.RESET_STATE](state, defaultState) {
    Object.assign(state, { ...defaultState.communications });
  },
};
