import * as mutations from "../../mutation-types";
import * as actions from "../../action-types";

import business from "@/requests/business";

export default {
  [actions.GET_CORPORATE_ACCOUNT](context, page) {
    return new Promise((resolve, reject) => {
        business
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(
              mutations.CUSTOMERS_SUMMARY,
              response.data.data.summary
            );
            context.commit(mutations.CORPORATE_ACCOUNTS, {
              page,
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_EMPLOYEES](context, {id,page}) {
    return new Promise((resolve, reject) => {
        business
        .getEmployees(id, page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.SAVE_EMPLOYEES, {
              page,
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_UPLOAD_RECEIPTS](context, {id, page}) {
    return new Promise((resolve, reject) => {
        business
        .getUploadReceiptPayments(id)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.SAVE_UPLOAD_RECEIPTS, {
              data: response.data.data,
              page,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
