import axios from "axios";

export default {
  index(page, params = "") {
    return axios.get(`crm/menu?page=${page}${params}`);
  },

  get(id) {
    return axios.get(`crm/menu/${id}`);
  },

  retrieve(id) {
    return axios.get(`rotational_menu/${id}`);
  },

  create(payload) {
    return axios.post("rotational_menu", payload);
  },

  update(payload) {
    return axios.post(`crm/menu/update`, payload);
  },

  duplicate(id) {
    return axios.post(`crm/menu/${id}/duplicate`);
  },

  delete(payload) {
    return axios.delete(`crm/menu`, { data: payload });
  },

  checkSelectionWindow() {
    return axios.get("selection_window_status");
  },

  toggleSelectionWindow() {
    return axios.put("selection_window_status");
  },

  publish(payload) {
    return axios.post("crm/menu", payload);
  },

  getLogs(params) {
    return axios.get(`crm/menu/activity/log${params}`);
  },
};
