<template>
  <el-tag :type="setType(value)">{{ formatText(value) }}</el-tag>
</template>

<script>
export default {
  name: "EdenTag",
  props: {
    value: {
      type: String,
      default: "info",
    },
  },
};
</script>
