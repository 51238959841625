import * as mutations from "../../mutation-types";
import * as actions from "../../action-types";

import gardeners from "@/requests/gardeners/index";
import gardener from "@/requests/gardeners/gardener";
import payment from "@/requests/gardeners/payment-tracker";

export default {
  [actions.GET_GARDENERS_LIST](context) {
    return new Promise((resolve, reject) => {
      gardeners
        .list()
        .then((response) => {
          if (response.data.status) {
            context.commit(
              mutations.GARDENERS_LIST,
              response.data.data.gardeners,
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_GARDENERS](context, page) {
    return new Promise((resolve, reject) => {
      gardeners
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.GARDENERS, {
              page,
              data: response.data.data.gardeners,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_GARDENER_SCHEDULE](
    context,
    { id, period, page, filterParams = "" },
  ) {
    return new Promise((resolve, reject) => {
      gardener
        .schedule(id, period, page, filterParams)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.GARDENER_SCHEDULE, {
              period,
              page,
              data: response.data.data.schedule,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_GARDENERS_PAYMENT_TRACKER](context, payload) {
    return new Promise((resolve, reject) => {
      payment
        .index(payload)
        .then((response) => {
          const { status, data, meta } = response.data;
          if (status) {
            context.commit(mutations.GARDENERS_PAYMENT_TRACKER, {
              type: payload.type,
              page: payload.page,
              data: {
                data,
                from: meta.from,
                to: meta.to,
                per_page: meta.per_page,
                total: meta.total,
              },
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
