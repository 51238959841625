import axios from "axios";

export default {
  add(payload) {
    return axios.post("gardeners", payload);
  },

  get(id) {
    return axios.get(`gardeners/${id}`);
  },

  update(id, payload) {
    return axios.put(`gardener/${id}`, payload);
  },

  availability(id, payload) {
    return axios.post(`gardeners/${id}/availability`, payload);
  },

  delete(id, payload) {
    return axios.post(`gardeners/${id}/expunge`, payload);
  },

  linkCustomer(id, payload) {
    return axios.post(`gardeners/${id}/link_customer`, payload);
  },

  schedule(id, period, page, filterParams) {
    let filter='';
    if(filterParams){
      filter = filterParams+'&'
    }
    return axios.get(`gardeners/${id}/schedule?${filter}period=${period}&page=${page}`);
  },

  orders({ id, service, period }) {
    return axios.get(
      `gardeners/${id}/provisioned_orders/${service}?period=${period}`
    );
  },

  sales({ id, start_date, end_date }) {
    return axios.get(
      `crm/summary/sales-conversion/gardener?gardener_id=${id}&start_date=${start_date}&end_date=${end_date}`
    );
  },

  salesBreakdown({ id, start_date, end_date, type }) {
    let params = `gardener_id=${id}&start_date=${start_date}&end_date=${end_date}`;
    if (type) {
      params += `&type=${type}`;
    }
    return axios.get(`crm/gardener/sales-conversion?${params}`);
  },
};
