import axios from "axios";

export default {
  index(page) {
    return axios.get(`one_time_customers?page=${page}`);
  },

  search(query) {
    return axios.get(`one_time_customers/search/${query}`);
  },

  export() {
    return axios.get("one_time_customers/export");
  },

  add(payload) {
    return axios.post("customer", payload);
  },

  order(payload) {
    return axios.post("customer_order", payload);
  },

  get(id) {
    return axios.get(`customer/${id}`);
  },

  convert(id, payload) {
    return axios.post(
      `customer/${id}/convert_from_one_time_to_recurrent_individual`,
      payload
    );
  },

  provisionOrders(payload) {
    return axios.post("customer_order", payload);
  },
};
