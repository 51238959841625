import * as types from "../../mutation-types";

export default {
  [types.MEMBERS](state, { page, data }) {
    state.members.total = data.total;
    state.members.pages[page] = {};
    state.members.pages[page].from = data.from;
    state.members.pages[page].to = data.to;
    state.members.pages[page].data = data.data;
  },

  [types.RESET_STATE](state, defaultState) {
    Object.assign(state, { ...defaultState.settings });
  },
};
