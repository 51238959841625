<template>
  <div class="side-nav--location__switch">
    <el-select v-model="location" @change="changeLocation">
      <img slot="prefix" :src="getCountryFlag(location)" :alt="location" />
      <el-option
        v-for="(location, index) in locations"
        :key="index"
        :label="location.label"
        :value="location.value"
      >
        <div class="is-flex align-center">
          <img
            :style="{
              width: '24px',
              height: 'auto',
              borderRadius: '4px',
            }"
            :src="getCountryFlag(location.value)"
            :alt="`${formatText(location.label)}`"
          />
          <span :style="{ marginLeft: '10px' }"> {{ location.label }}</span>
        </div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import * as mutations from "@/store/mutation-types";

export default {
  name: "EdenLocationSwitch",
  data() {
    return {
      locations: [
        {
          label: "Nigeria",
          value: "NG",
        },
        {
          label: "Kenya",
          value: "KE",
        },
      ],
    };
  },
  computed: {
    location: {
      get() {
        return this.$store.getters.location;
      },
      set(value) {
        this.$emit("update:location", value);
      },
    },
  },
  mounted() {
    if (this.$route.path !== null) {
      this.nav = this.$route.path.split("/")[1];
    }
  },
  methods: {
    changeLocation(location) {
      this.location = location;
      this.$store.commit(mutations.LOCATION, location);
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped></style>
