<template>
  <el-checkbox-group class="eden-checkbox" v-model="setSelected" size="small">
    <el-checkbox
      v-for="(option, index) in options"
      :key="index"
      :label="property ? option[property] : option"
      border
      >{{ formatText(property ? option[property] : option) }}</el-checkbox
    >
  </el-checkbox-group>
</template>

<script>
export default {
  name: "EdenCheckbox",
  props: {
    selected: {
      type: Array,
      default() {
        return [];
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    property: {
      type: String,
      default: "name",
    },
    type: {
      type: String,
      default: "rounded",
    },
  },
  data() {
    return {};
  },
  computed: {
    setSelected: {
      get() {
        return this.selected;
      },
      set(value) {
        this.$emit("update:selected", value);
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.eden-checkbox {
  .el-checkbox {
    display: inline-flex;
    align-items: center;
    height: 30px !important;

    &.is-bordered {
      margin-right: 0 !important;
      border-radius: 1000px !important;
      border-color: transparent;
      background: var(--eden-grey-septenary);

      &.is-checked {
        border-color: var(--eden-green-tertiary);
        background: var(--eden-green-octonary);

        .el-checkbox__inner {
          border-color: var(--eden-green-primary);
          background: var(--eden-green-primary);
        }
      }

      .el-checkbox__label {
        padding-left: 5px;
        font-size: 0.75rem;
        color: var(--eden-grey-primary);
      }

      .el-checkbox__inner {
        border-radius: 20px;
        border-color: var(--eden-grey-tertiary);
        background: transparent;

        &::after {
          top: 1px;
        }
      }
    }
  }
}
</style>
