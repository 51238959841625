import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = () => ({
  customers_summary: {},
  customers_individual: {
    total: null,
    pages: {},
  },
  customers_corporate: {
    total: null,
    pages: {},
  },
  customers_onetime: {
    total: null,
    pages: {},
  },
  customer_schedule: {},
  customer_orders: {},
  customer_activity: { total: null, pages: {} },
  customer_transactions: { total: null, pages: {} },
});

export default {
  state,
  getters,
  actions,
  mutations,
};
