import axios from "axios";

export default {
  list() {
    return axios.get("locationareas");
  },
  index(page) {
    return axios.get(`clusters/locationarea/all?page=${page}`);
  },
  search(query) {
    return axios.get(`clusters/locationarea/all/search/${query}`);
  },
  add(payload) {
    return axios.post("locationareas", payload);
  },

  update(payload) {
    return axios.put("locationareas", payload);
  },

  delete(id) {
    return axios.delete("locationareas", { data: { location_area_id: id } });
  },
  gardener(action, payload) {
    return axios.post(`location_areas/${action}_gardener `, payload);
  },
};
