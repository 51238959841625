import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = () => ({
  // orders_summary: {},
  // orders: {},
  fulfillment_orders_schedule: {
    meal: {},
  },
});

export default {
  state,
  getters,
  actions,
  mutations,
};
