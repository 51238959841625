<template>
  <div class="service-configuration cleaning">
    <div class="title">
      <p>Cleaning Configuration</p>
    </div>
    <el-form :model="form" label-position="top" ref="form">
      <el-form-item
        label="Select cleaning type"
        prop="item"
        :rules="validateField()"
      >
        <el-radio-group
          v-model="form.item"
          class="eden-custom--radio flex"
          @change="setItemAreas"
        >
          <el-radio
            v-for="(type, index) in cleaningServiceTypes"
            :key="index"
            :label="type.slug"
            >{{ type.name }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="Select house information"
        prop="home_type"
        :rules="validateField()"
      >
        <el-radio-group
          v-model="form.home_type"
          class="eden-custom--radio flex"
        >
          <el-radio
            v-for="(type, i) in homeTypes"
            :key="i"
            :label="type.toLowerCase()"
            >{{ type }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="Select home areas"
        prop="item_areas_selected"
        :rules="validateField()"
      >
        <el-checkbox-group
          v-model="form.item_areas_selected"
          class="item-areas"
        >
          <el-checkbox
            v-for="(value, key, index) in form.item_areas"
            :key="index"
            :label="key"
            @change="updateItemArea($event, key)"
          >
            <div class="item-area">
              <p>{{ setItemAreaLabel(key) }}</p>
              <div
                v-if="form.item_areas_selected.includes(key)"
                class="counter"
              >
                <el-button
                  :type="'control'"
                  size="mini"
                  icon="el-icon-minus"
                  :disabled="form.item_areas[key] === 1"
                  @click="updateItemAreaNumber(key, 'subtract')"
                />
                <el-input
                  v-model.number="form.item_areas[key]"
                  v-number
                  type="text"
                  size="mini"
                />
                <el-button
                  :type="'control'"
                  size="mini"
                  icon="el-icon-plus"
                  @click="updateItemAreaNumber(key, 'add')"
                />
              </div>
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <template v-if="!oneTime">
        <el-form-item
          label="Select cleaning frequency"
          prop="frequency"
          :rules="validateField()"
        >
          <el-radio-group
            v-model="form.frequency"
            class="eden-custom--radio flex"
            @change="resetServiceDays"
          >
            <el-radio label="weekly">Weekly</el-radio>
            <el-radio label="bi-weekly">Every Two Weeks</el-radio>
            <el-radio label="monthly">Monthly</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="Select cleaning days"
          prop="service_day"
          :rules="validateField('Service day')"
        >
          <el-checkbox-group
            v-model="form.service_day"
            class="eden-custom--radio flex"
            @change="sortServiceDays"
          >
            <el-checkbox
              v-for="(value, key, index) in serviceDays()"
              :key="index"
              :label="key"
              @change="setServiceDay($event, key)"
              >{{ value }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="">
          <div class="service-summary">
            <span>
              Their home would be cleaned on
              {{ serviceDaysLabel || "-" }}
            </span>
          </div>
        </el-form-item>
      </template>
      <template v-else>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Select date(s)"
              prop="dates"
              :rules="validateField()"
            >
              <el-date-picker
                v-model="form.dates"
                value-format="yyyy-MM-dd"
                type="dates"
                format="dd MMM, yyyy"
                popper-class="customized-multi-day-picker"
                prefix-icon="eden-icon--calendar"
                :picker-options="{
                  shortcuts: setMappedDates,
                  disabledDate: setDisabledDates,
                }"
                @input="setDates"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <el-row v-if="form.service_day.length" type="flex">
        <el-col :span="24">
          <el-form-item label="Total amount">
            <el-input
              v-model.number="form.amount"
              v-number
              type="text"
              readonly
              placeholder="Total amount"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
import priceConfig from "@/requests/services/meal/menu_types";

export default {
  name: "CleaningConfiguration",
  props: {
    config: {
      type: Object,
      default() {
        return {};
      },
    },
    oneTime: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emitResponse: false,
      form: {
        item: "standard-cleaning",
        home_type: "flat",
        item_areas_selected: ["bedrooms"],
        item_areas: {},
        item_areas_price: {},
        frequency: "weekly",
        days: 1,
        service_day: ["tuesday"],
        dates: [],
        amount: 0,
      },
      amount: 0,
    };
  },
  computed: {
    location() {
      return this.$store.getters.location;
    },
    cleaningServiceTypes() {
      return this.$store.getters.service_types.cleaning.filter(
        (type) => type.name !== "Fumigation",
      );
    },
    homeTypes() {
      if (this.location === "KE") {
        return ["Apartment", "House"];
      } else {
        return ["Flat", "Duplex"];
      }
    },
    serviceDaysLabel() {
      const text = this.formatToTitleCase(this.form.service_day.join(", "));
      return text ? text : "-";
    },
    setMappedDates() {
      let dates = this.form.dates.map((date) => {
        return {
          text: this.formatDate(date, "mo d, y"),
        };
      });
      let dateText = {
        text: `Selected Dates: ${dates.length} `,
      };
      dates = [dateText, ...dates];
      return dates;
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (this.emitResponse) {
          this.setFormResponse();
        }
      },
    },
  },
  mounted() {
    if (this.oneTime) {
      this.form.dates = this.formatDefaultServiceStartDate();
    }
    const types = this.$store.getters.service_types.cleaning;
    if (types.length) {
      this.setConfigData();
    } else {
      this.$store.dispatch(actions.GET_SERVICE_TYPES, "cleaning").then(() => {
        this.setConfigData();
      });
    }

    this.form.home_type = this.location === "KE" ? "apartment" : "flat";
  },
  methods: {
    setConfigData() {
      this.setItemAreas();
      if (Object.keys(this.config).length) {
        this.setCleaningConfig();
      } else {
        this.emitResponse = true;
        this.setFormResponse();
      }
    },
    updateItemAreaNumber(key, action) {
      action === "add"
        ? this.form.item_areas[key]++
        : this.form.item_areas[key]--;
      this.form.item_areas = Object.assign({}, this.form.item_areas);
    },
    updateItemArea(status, key) {
      this.form.item_areas[key] = status ? 1 : 0;
    },
    resetServiceDays() {
      this.form.service_day = ["tuesday"];
    },
    sortServiceDays() {
      this.form.service_day = this.sortedDaysOfWeek(this.form.service_day);
    },
    setServiceDay(status, key) {
      if (this.form.frequency !== "weekly") {
        this.form.service_day = [key];
      }
    },
    setDisabledDates(time) {
      if (this.getWeekday(time).toLowerCase() === "sunday") {
        return true;
      }
      if (
        this.form.dates.length === 5 &&
        !this.form.dates.includes(this.formatDate(time, "y-m-d"))
      ) {
        return true;
      }
      if (this.allowAccessFor(["superadmin"])) {
        return false;
      }
      if (this.allowAccessFor(["admin", "operations", "kitchen"])) {
        return time.getTime() < new Date();
      }
      return (
        time.getTime() < new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      );
    },
    setDates() {
      const { dates } = this.form;
      if (dates.length > 5) {
        this.form.dates.pop();
        this.$message.info("You can only select 5 days");
      }
      this.form.dates = dates.sort((a, b) => new Date(a) - new Date(b));
    },
    setItemAreas() {
      const cleaningType = this.cleaningServiceTypes.filter((type) => {
        return this.setServiceType(type.name) ===
          this.form.item.includes("standard")
          ? "light-cleaning"
          : this.form.item;
      });
      if (cleaningType.length) {
        const config = Object.keys(this.config).length;
        if (config) {
          cleaningType[0].cleaning_areas.forEach((area) => {
            this.form.item_areas_price[area.slug] = area.price;
          });
          this.form.item_areas_selected = Object.keys(this.form.item_areas);
          return;
        }
        this.form.item_areas = {};
        this.form.item_areas_price = {};
        this.form.item_areas_selected = ["bedrooms"];
        cleaningType[0].cleaning_areas.forEach((area) => {
          this.form.item_areas[area.slug] = area.slug === "bedrooms" ? 1 : 0;
          this.form.item_areas_price[area.slug] = area.price;
        });
      }
    },
    setItemAreaLabel(slug) {
      return this.formatToTitleCase(slug).replace("Rooms", "Rooms /");
    },
    setCleaningConfig() {
      const { item, home_type, item_areas, frequency, service_day, amount } =
        this.config;
      try {
        this.form.item = item;
        this.form.home_type = home_type;
        this.form.item_areas = item_areas;
        this.form.item_areas_selected = Object.keys(item_areas);
        this.form.frequency = frequency.startsWith("week")
          ? "weekly"
          : frequency;
        this.form.service_day = service_day.map((day) => day.toLowerCase());
        this.form.amount = amount;
      } catch (e) {
        this.$message.error("Error setting Cleaning Configuration");
      } finally {
        this.emitResponse = true;
      }
    },
    async setFormResponse() {
      let {
        item,
        home_type,
        item_areas,
        item_areas_price,
        frequency,
        service_day,
        dates,
      } = this.form;

      let response = {
        item: item.replace("light", "standard"),
        home_type,
        item_areas,
        frequency,
        service_day,
        qty: Object.keys(item_areas).reduce((total, area) => {
          return total + item_areas[area];
        }, 0),
        dates,
      };

      if (frequency === "weekly" && service_day.length > 1) {
        response.frequency =
          service_day.length < 5
            ? `weekly${this.serviceDayPrefix()[service_day.length]}`
            : "daily";
      }
      // response.amount = this.pricing({
      //   cleaning: {
      //     item,
      //     item_areas,
      //     item_areas_price,
      //     frequency: this.oneTime ? "one-off" : response.frequency,
      //     service_day,
      //     dates,
      //   },
      // });

      await this.getPrice({
        cleaning: {
          item,
          item_areas,
          item_areas_price,
          frequency: this.oneTime ? "one-off" : response.frequency,
          service_day,
          dates,
          amount: 0,
          home_type,
          qty: Object.keys(item_areas).reduce((total, area) => {
            return total + item_areas[area];
          }, 0),
        },
      });
      response.amount = this.amount;

      if (!this.oneTime) {
        delete response.dates;
      }
      this.form.amount = response.amount;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$emit("update", {
            validity: valid,
            response,
            service: "cleaning",
          });
        }
      });
    },
    async getPrice(cleaning) {
      const params = {
        plan: cleaning,
        domicile_location: this.location,
        return_new_plan: false,
      };
      await priceConfig
        .price(params)
        .then((response) => {
          this.loading = false;
          this.amount = response.data.data[0].amount;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.service-configuration.cleaning {
  .item-areas {
    .el-checkbox {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 28px;
      margin-bottom: 5px;

      &__label {
        width: 100%;
      }
    }
  }
  .item-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    p {
      width: 50%;
    }

    .counter {
      width: 40% !important;

      .el-input__inner {
        padding: 0 5px;
      }
    }
  }
}
</style>
