const Directory = () => import("@/pages/logistics/directory/index");
const DirectoryBase = () => import("@/pages/logistics/directory/base");

const RiderAdd = () => import("@/pages/logistics/directory/rider/add");
const RiderEdit = () => import("@/pages/logistics/directory/rider/edit");
const Rider = () => import("@/pages/logistics/directory/rider/index");

const CleanerAdd = () => import("@/pages/logistics/directory/cleaner/add");
const CleanerEdit = () => import("@/pages/logistics/directory/cleaner/edit");
const Cleaner = () => import("@/pages/logistics/directory/cleaner/index");

const BeauticianAdd = () =>
  import("@/pages/logistics/directory/beautician/add");
const BeauticianEdit = () =>
  import("@/pages/logistics/directory/beautician/edit");
const Beautician = () => import("@/pages/logistics/directory/beautician/index");

const CompanyAdd = () => import("@/pages/logistics/directory/company/add");
const CompanyEdit = () => import("@/pages/logistics/directory/company/edit");
const Company = () => import("@/pages/logistics/directory/company/index");

const Schedule = () => import("@/pages/logistics/schedule/index");
const ScheduleBase = () => import("@/pages/logistics/schedule/base");
const ScheduleCreate = () => import("@/pages/logistics/schedule/create");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "growth",
  "gardener",
  "gardener_pro",
];

const routes = () => {
  return [
    /**
     * Redirect to Directory page if someone tries to
     * access main module route [.../logistics] without
     * specifying a sub-module  [.../logistics/:sub-module]
     */
    {
      path: "/logistics",
      redirect: {
        name: "logistics.directory.index",
        component: Directory,
      },
    },
    /**
     * Directory Sub-Module
     */
    {
      path: "/logistics/directory",
      name: "logistics.directory.index",
      component: Directory,
      meta: {
        module: "directory",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/logistics/directory/",
      component: DirectoryBase,
      meta: {
        module: "directory",
        permission: authenticatedRoles,
        layout: "app",
      },
      children: [
        {
          path: "riders",
          redirect: {
            name: "logistics.directory.index",
            component: Directory,
          },
        },
        {
          path: "add-rider",
          name: "logistics.directory.rider-add",
          component: RiderAdd,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "riders/:id",
          name: "logistics.directory.rider",
          component: Rider,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "riders/:id/edit",
          name: "logistics.directory.rider-edit",
          component: RiderEdit,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "cleaners",
          redirect: {
            name: "logistics.directory.index",
            component: Directory,
          },
        },
        {
          path: "add-cleaner",
          name: "logistics.directory.cleaner-add",
          component: CleanerAdd,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "cleaners/:id",
          name: "logistics.directory.cleaner",
          component: Cleaner,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "cleaners/:id/edit",
          name: "logistics.directory.cleaner-edit",
          component: CleanerEdit,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "beauticians",
          redirect: {
            name: "logistics.directory.index",
            component: Directory,
          },
        },
        {
          path: "add-beautician",
          name: "logistics.directory.beautician-add",
          component: BeauticianAdd,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "beauticians/:id",
          name: "logistics.directory.beautician",
          component: Beautician,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "beauticians/:id/edit",
          name: "logistics.directory.beautician-edit",
          component: BeauticianEdit,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "companies",
          redirect: {
            name: "logistics.directory.index",
            component: Directory,
          },
        },
        {
          path: "add-company",
          name: "logistics.directory.company-add",
          component: CompanyAdd,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "companies/:id",
          name: "logistics.directory.company",
          component: Company,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "companies/:id/edit",
          name: "logistics.directory.company-edit",
          component: CompanyEdit,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
      ],
    },

    /**
     * Schedule Sub-Module
     */
    {
      path: "/logistics/schedule",
      name: "logistics.schedule.index",
      component: Schedule,
      meta: {
        module: "schedule",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/logistics/schedule/",
      component: ScheduleBase,
      meta: {
        module: "schedule",
        permission: authenticatedRoles,
        layout: "app",
      },
      children: [
        {
          path: ":service/create",
          name: "logistics.schedule.create",
          component: ScheduleCreate,
          meta: {
            module: "schedule",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
      ],
    },
  ];
};

export default routes();
