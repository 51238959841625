<template>
  <div class="service-configuration beauty">
    <div class="title">
      <p>Beauty Configuration</p>
    </div>
    <el-form :model="form" label-position="top" ref="form">
      <el-form-item
        label="Select beauty type"
        prop="item"
        :rules="validateField()"
      >
        <el-radio-group v-model="form.item" class="eden-custom--radio flex">
          <el-radio
            v-for="(type, index) in beautyServiceTypes"
            :key="index"
            :label="type.slug"
            >{{ type.service_type }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <template v-if="!oneTime">
        <el-form-item
          label="Select beauty frequency"
          prop="frequency"
          :rules="validateField()"
        >
          <el-radio-group
            v-model="form.frequency"
            class="eden-custom--radio flex"
            @change="resetServiceDays"
          >
            <el-radio label="weekly">Weekly</el-radio>
            <el-radio label="bi-weekly">Every Two Weeks</el-radio>
            <el-radio label="monthly">Monthly</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="Select beauty days"
          prop="service_day"
          :rules="validateField()"
        >
          <el-checkbox-group
            v-model="form.service_day"
            class="eden-custom--radio flex"
            @change="sortServiceDays"
          >
            <el-checkbox
              v-for="(value, key, index) in serviceDays()"
              :key="index"
              :label="key"
              @change="setServiceDay($event, key)"
              >{{ value }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </template>
      <template v-else>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Select date(s)"
              prop="dates"
              :rules="validateField()"
            >
              <el-date-picker
                v-model="form.dates"
                value-format="yyyy-MM-dd"
                type="dates"
                format="dd MMM, yyyy"
                popper-class="customized-multi-day-picker"
                prefix-icon="eden-icon--calendar"
                :clearable="false"
                :picker-options="{
                  shortcuts: setMappedDates,
                  disabledDate: setDisabledDates,
                }"
                @input="setDates"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <el-form-item
        label="How many beauty variations per service?"
        prop="qty"
        :rules="validateField()"
      >
        <div class="counter">
          <el-button
            :type="'control'"
            icon="el-icon-minus"
            :disabled="form.qty === 1"
            @click="form.qty--"
          />
          <el-input
            v-model.number="form.qty"
            v-number
            type="text"
            @blur="validateQuantity"
          />
          <el-button
            :type="'control'"
            icon="el-icon-plus"
            @click="form.qty++"
          />
        </div>
      </el-form-item>
      <el-form-item v-if="form.service_day.length" label="">
        <div class="service-summary">
          <span>
            They will receive their beauty service on
            {{ serviceDaysLabel || "-" }}
          </span>
        </div>
      </el-form-item>
      <el-row v-if="form.service_day.length" type="flex">
        <el-col :span="24">
          <el-form-item label="Total amount">
            <el-input
              v-model.number="form.amount"
              v-number
              type="text"
              readonly
              placeholder="Total amount"
              :key="form.amount"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
import priceConfig from "@/requests/services/meal/menu_types";

export default {
  name: "BeautyConfiguration",
  props: {
    config: {
      type: Object,
      default() {
        return {};
      },
    },
    oneTime: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emitResponse: false,
      form: {
        item: "standard",
        qty: 1,
        frequency: "weekly",
        service_day: ["saturday"],
        dates: [],
        amount: 0,
      },
      amount: 0,
    };
  },
  computed: {
    beautyServiceTypes() {
      return this.$store.getters.service_types.beauty;
    },
    serviceDaysLabel() {
      const text = this.formatToTitleCase(this.form.service_day.join(", "));
      return text ? text : "-";
    },
    setMappedDates() {
      let dates = this.form.dates.map((date) => {
        return {
          text: this.formatDate(date, "mo d, y"),
        };
      });
      let dateText = {
        text: `Selected Dates: ${dates.length} `,
      };
      dates = [dateText, ...dates];
      return dates;
    },
    location() {
      return this.$store.getters.location;
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (this.emitResponse) {
          this.setFormResponse();
        }
      },
    },
  },
  mounted() {
    if (this.oneTime) {
      this.form.dates = this.formatDefaultServiceStartDate();
    }

    const types = this.$store.getters.service_types.beauty;
    if (types.length) {
      this.setConfigData();
    } else {
      this.$store.dispatch(actions.GET_SERVICE_TYPES, "beauty").then(() => {
        this.setConfigData();
      });
    }
  },
  methods: {
    setConfigData() {
      if (Object.keys(this.config).length) {
        this.setBeautyConfig();
      } else {
        this.emitResponse = true;
        this.setFormResponse();
      }
    },
    validateQuantity() {
      const { qty } = this.form;
      if (qty === "") this.form.qty = 1;
    },
    resetServiceDays() {
      this.form.service_day = ["saturday"];
    },
    sortServiceDays() {
      this.form.service_day = this.sortedDaysOfWeek(this.form.service_day);
    },
    setServiceDay(status, key) {
      if (this.form.frequency !== "weekly") {
        this.form.service_day = [key];
      }
    },
    setDisabledDates(time) {
      if (this.getWeekday(time).toLowerCase() === "sunday") {
        return true;
      }
      if (
        this.form.dates.length === 5 &&
        !this.form.dates.includes(this.formatDate(time, "y-m-d"))
      ) {
        return true;
      }
      if (this.allowAccessFor(["superadmin"])) {
        return false;
      }
      if (this.allowAccessFor(["admin", "operations", "kitchen"])) {
        return time.getTime() < new Date();
      }
      return (
        time.getTime() < new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      );
    },
    setDates() {
      const { dates } = this.form;
      if (dates.length > 5) {
        this.form.dates.pop();
        this.$message.info("You can only select 5 days");
      }
      this.form.dates = dates.sort((a, b) => new Date(a) - new Date(b));
    },
    setBeautyConfig() {
      const { item, qty, frequency, service_day, amount } = this.config;
      try {
        this.form.item = item;
        this.form.qty = qty;
        this.form.frequency = frequency.startsWith("week")
          ? "weekly"
          : frequency;
        this.form.service_day = service_day.map((day) => day.toLowerCase());
        this.form.amount = amount;
      } catch (e) {
        this.$message.error("Error setting Beauty Configuration");
      } finally {
        this.emitResponse = true;
      }
    },
    async setFormResponse() {
      let { item, qty, frequency, service_day, dates } = this.form;

      let response = {
        item,
        qty,
        frequency:
          frequency === "weekly" && service_day.length >= 5
            ? "daily"
            : frequency === "weekly"
            ? `weekly${this.serviceDayPrefix()[service_day.length]}`
            : frequency,
        service_day,
        dates,
      };

      // response.amount = this.pricing({
      //   beauty: {
      //     item,
      //     qty,
      //     frequency: this.oneTime ? "one-off" : response.frequency,
      //     service_day,
      //     dates,
      //   },
      // });
      await this.getPrice({
        beauty: {
          item,
          qty,
          frequency: response.frequency,
          service_day,
          dates,
          amount: 0,
        },
      });
      response.amount = this.amount;

      if (!this.oneTime) {
        delete response.dates;
      }
      this.form.amount = response.amount;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$emit("update", {
            validity: valid,
            response,
            service: "beauty",
          });
        }
      });
    },

    async getPrice(beauty) {
      const params = {
        plan: beauty,
        domicile_location: this.location,
        return_new_plan: false,
      };
      await priceConfig
        .price(params)
        .then((response) => {
          this.loading = false;
          this.amount = response.data.data[0].amount;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
