<template>
  <div class="is-flex is-align-center">
    <el-dropdown @command="setService">
      <el-button type="plain" :disabled="loading">
        {{ formatText(selectedService) }} <i class="eden-icon-arrow-down"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <template>
          <el-dropdown-item
            v-for="(service, index) in services"
            :key="index"
            :class="{
              selected: service.slug === selectedService,
            }"
            :command="service.slug"
            >{{ service.service_name }}</el-dropdown-item
          >
        </template>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "EdenServices",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedService: "meal",
    };
  },
  computed: {
    services() {
      return this.$store.getters.services;
    },
  },
  methods: {
    setService(service) {
      this.selectedService = service;
      this.$emit("set-service", this.selectedService);
    },
  },
};
</script>
