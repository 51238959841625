export default {
  methods: {
    formatGardenerLog(logs, count) {
      let parsedLogs = logs.reverse();
      if (count) {
        parsedLogs = parsedLogs.splice(0, count);
      }
      parsedLogs = parsedLogs.map((log, index) => {
        const prevLog = parsedLogs[index - 1];

        return {
          user_id: log.user_id,
          gardener_name: log.gardener_name,
          gardener_id: log.gardener_id,
          assigned_at: log.gardener_assigned_at,
          changed_at: prevLog ? prevLog.gardener_assigned_at : "-",
          assignment_made_by: log.assignment_made_by,
        };
      });

      return parsedLogs;
    },
  },
};
