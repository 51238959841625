import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = () => ({
  directory: {
    riders: {
      total: null,
      pages: {},
    },
    cleaners: {
      total: null,
      pages: {},
    },
    beauticians: {
      total: null,
      pages: {},
    },
    companies: {
      total: null,
      pages: {},
    },
  },
  schedule: {},
  schedule_orders: {},
});

export default {
  state,
  getters,
  actions,
  mutations,
};
