import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = () => ({
  zones_list: [],
  zones: {
    total: null,
    pages: {},
  },
  location_areas_list: [],
  location_areas: {
    total: null,
    pages: {},
  },
  micro_fulfillment_list: [],
  micro_fulfillment_centers: {
    total: null,
    pages: {},
  },
});

export default {
  state,
  getters,
  actions,
  mutations,
};
