<template>
  <div
    class="eden-activity-icon"
    :style="{
      backgroundColor: `${colors[event]}`
    }"
  >
    <img :src="getImage(`activities/${event}.svg`)" alt />
  </div>
</template>

<script>
export default {
  name: "EdenActivityIcon",
  props: {
    activity: {
      type: String,
      default: "assign"
    }
  },

  data() {
    return {
      colors: {
        user: "#DEFFEF",
        list: "#FFEBDE"
      }
    };
  },
  computed: {
    event() {
      if (this.activity.includes("assign")) {
        return "user";
      } else if (this.activity.includes("charge")) {
        return "payment";
      } else if (this.activity.includes("order")) {
        return "list";
      } else {
        return "user";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.eden-activity-icon {
  border-radius: 100%;
  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 18px;
    width: auto;
  }
}
</style>
