import axios from "axios";

export default {
  index(start, end, page) {
    return axios.get(`crm/customer/feed?start_date=${start}&end_date=${end}&page=${page}`);
  },

  search(query) {
    return axios.get(`crm/customer/feed/search/name?user_name=${query}`);
  },

  filter(payload) {
    return axios.post("audits/all/filter", payload);
  },

  getFoodActivities(period, page) {
    return axios.get(`meal/picking_items/audits?period=${period}&page=${page}`);
  },

  searchFoodActivities(query, period) {
    return axios.get(
      `meal/picking_items/audits/search/${query}?period=${period}`
    );
  }, 
};
