<template>
  <div>
    <slot />
    <download-csv
      v-if="data.data && data.data.length"
      :data="data && data.data"
      :title="data.title"
      :name="data.fileName"
    >
      <div class="csvDownloadButton">csv</div>
    </download-csv>
    <download-excel
      v-if="data.data && data.data.length"
      :data="data.data"
      :title="data.title"
      :name="data.fileName"
    >
      <div class="excelDownloadButton">excel</div>
    </download-excel>
  </div>
</template>

<script>
export default {
  name: "EdenFileExport",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    data() {
      if (this.data.data.length) {
        this.export();
      } else {
        this.$message.error("No data to export");
        this.$emit("cancelled");
      }
    },
  },
  methods: {
    export() {
      switch (this.data.format) {
        case "pdf":
          this.exportPdf(this.data, this.exported);
          break;
        case "csv":
          this.exportCsv(this.data, this.exported);
          break;
        case "xls":
          this.exportExcel(this.exported);
          break;
        default:
          break;
      }
    },
    exportPdf(data, callback) {
      this.generatePdf(data);
      callback();
    },
    exportCsv(data, callback) {
      setTimeout(() => {
        this.generateCsv(data);
        callback();
      }, 3000);
    },
    exportExcel(callback) {
      setTimeout(() => {
        document.querySelector(".excelDownloadButton").click();
        callback();
      }, 3000);
    },
    exported() {
      setTimeout(() => {
        this.$emit("exported");
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.orders-list {
  position: absolute;
  height: 1px;
  opacity: 0;
}

.csvDownloadButton,
.excelDownloadButton {
  position: absolute;
  opacity: 0;
}
</style>
