import * as mutations from "../../mutation-types";
import * as actions from "../../action-types";

import riders from "@/requests/logistics/directory/riders";
import cleaners from "@/requests/logistics/directory/cleaners";
import beauticians from "@/requests/logistics/directory/beauticians";
import companies from "@/requests/logistics/directory/companies";
import schedule from "@/requests/logistics/schedule";

export default {
  [actions.GET_LOGISTICS_DIRECTORY](context, { directory, page }) {
    return new Promise((resolve, reject) => {
      const directories = {
        riders: riders,
        cleaners: cleaners,
        beauticians: beauticians,
        companies: companies,
      };

      const module = directories[directory];
      module
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.LOGISTICS_DIRECTORY, {
              directory,
              page,
              data: response.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_LOGISTICS_SCHEDULE](context, { service, period }) {
    return new Promise((resolve, reject) => {
      schedule
        .index({ service, period })
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.LOGISTICS_SCHEDULE, {
              service,
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_LOGISTICS_SCHEDULE_ORDERS](context, { service, date, page }) {
    return new Promise((resolve, reject) => {
      schedule
        .orders({ service, date, page })
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.LOGISTICS_SCHEDULE_ORDERS, {
              service,
              page,
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
