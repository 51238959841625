import Vue from "vue";
import BeautyConfiguration from "./Beauty/BeautyConfiguration";
import CleaningConfiguration from "./Cleaning/CleaningConfiguration";
import LaundryConfiguration from "./Laundry/LaundryConfiguration";
import MealConfiguration from "./Meal/MealConfiguration";
import SubscriptionConfiguration from "./SubscriptionConfiguration";

Vue.component("beauty-configuration", BeautyConfiguration);
Vue.component("cleaning-configuration", CleaningConfiguration);
Vue.component("laundry-configuration", LaundryConfiguration);
Vue.component("meal-configuration", MealConfiguration);
Vue.component("subscription-configuration", SubscriptionConfiguration);
