const Products = () => import("@/pages/products/index");

const MODULE = "products";

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "growth",
  "vendor_manager",
  "gardener",
  "gardener_pro",
];

const routes = () => {
  return [
    {
      path: "/products",
      name: "products.index",
      component: Products,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
