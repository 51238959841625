<template>
  <el-select
    v-model="setModel"
    filterable
    :multiple="multiple"
    remote
    :remote-method="debounce"
    :loading="fetching"
    :placeholder="placeholder"
    :value-key="'id'"
  >
    <el-option
      v-for="customer in list"
      :key="customer.id"
      :label="formatName(customer.name)"
      :value="returnObject ? customer : customer[itemKey]"
    >
      <span :style="style.left">{{ formatName(customer.name) }}</span>
      <span :style="style.right">{{ customer.email.toLowerCase() }}</span>
    </el-option>
  </el-select>
</template>

<script>
import customers from "@/requests/customers/individual";

export default {
  name: "EdenCustomersSelect",
  props: {
    placeholder: {
      type: String,
      default: "Select customer(s)",
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    customers: {
      type: Array,
      default() {
        return [];
      },
    },
    customer: {
      type: [String, Number, Object],
      default: "",
    },
    customerName: {
      type: String,
      default: "",
    },
    itemKey: {
      type: String,
      default: "id",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    searchByName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fetching: false,
      hasMounted: false,
      list: [],
      style: {
        left: {
          float: "left",
          maxWidth: "100px",
        },
        right: {
          float: "right",
          color: "#8492a6",
          fontSize: "13px",
        },
      },
    };
  },
  computed: {
    setCustomers: {
      get() {
        return this.customers;
      },
      set(value) {
        this.$emit("update:customers", value);
      },
    },
    setCustomer: {
      get() {
        return this.customer;
      },
      set(value) {
        this.$emit("update:customer", value);
      },
    },
    setModel: {
      get() {
        return this.multiple ? this.customers : this.customer;
      },
      set(value) {
        this.$emit(`update:${this.multiple ? "customers" : "customer"}`, value);
      },
    },
  },
  created() {
    if (this.customerName) {
      this.debounce(this.customerName);
    }
    if (this.customer && this.customer.id) {
      this.getCustomer(this.customer.id);
    }
    if (this.customers.length > 0) {
      this.getCustomers();
    }
    this.hasMounted = true;
  },
  watch: {
    customer(newV, oldV) {
      if (newV.id !== oldV.id && !this.hasMounted) {
        this.getCustomer(this.customer.id);
      }
    },
  },
  methods: {
    debounce(query) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getCustomersList(query);
      }, 1000);
    },
    getCustomersList(query) {
      if (query !== "") {
        this.fetching = true;
        if (!this.searchByName) {
          customers
            .search(query)
            .then((response) => {
              if (response.data.status) {
                this.list = response.data.data.customers;
                this.fetching = false;
              }
            })
            .catch();
        } else {
          customers
            .searchByName(query)
            .then((response) => {
              if (response.data.status) {
                this.list = response.data.data.customers;
                this.fetching = false;
              }
            })
            .catch();
        }
      } else {
        this.list = [];
      }
    },
    getCustomer(id) {
      this.fetching = true;
      customers
        .get(id)
        .then((response) => {
          if (response.data.status) {
            this.list.push(response.data.data);
            this.fetching = false;
          }
        })
        .catch();
    },
    getCustomers() {
      this.fetching = true;
      customers
        .list()
        .then((response) => {
          if (response.data.status) {
            this.list = [...response.data?.data];
            this.fetching = false;
          }
        })
        .catch();
    },
  },
};
</script>

<style lang="scss" scoped></style>
