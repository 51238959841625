<template>
  <div
    :class="[
      'table--header',
      'text-cursor',
      { active: property === sortProperty },
    ]"
    @click="setSort"
  >
    <span>{{ label }}</span>
    <template>
      <i v-if="direction === 'asc'" class="el-icon-top"></i>
      <i v-else-if="direction === 'des'" class="el-icon-bottom"></i>
    </template>
  </div>
</template>

<script>
export default {
  name: "EdenTableColumnHeader",
  props: {
    label: {
      type: String,
      default: "",
    },
    property: {
      type: String,
      default: "",
    },
    sortProperty: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      direction: "asc",
    };
  },
  methods: {
    setSort() {
      if (this.property !== this.sortProperty) {
        this.direction = "asc";
      } else {
        this.direction = this.direction === "asc" ? "des" : "asc";
      }
      this.$emit("sort", this.direction);
    },
  },
};
</script>

<style lang="scss" scoped>
.table--header {
  display: inline-block;

  span {
    display: inline-block;
    white-space: pre;
    margin-right: 5px;
  }

  &.active {
    color: #21312a !important;

    i {
      color: var(--eden-green-primary) !important;
      font-weight: bold !important;
    }
  }
}
</style>
