const Customers = () => import("@/pages/customers/index");

const CustomerIndividualAdd = () => import("@/pages/customers/individual/add");
const CustomerIndividualEdit = () =>
  import("@/pages/customers/individual/edit");

const CustomerCorporateAdd = () => import("@/pages/customers/corporate/add");
const CustomerCorporateEdit = () => import("@/pages/customers/corporate/edit");

const CustomerOnetimeAdd = () => import("@/pages/customers/onetime/add");

const CustomersBase = () => import("@/pages/customers/base");
const CustomerIndividual = () => import("@/pages/customers/individual/index");
const CustomerCorporate = () => import("@/pages/customers/corporate/index");
const CustomerCorporateWallet = () => import("@/pages/customers/corporate/wallet/index");
const CustomerOrders = () => import("@/pages/customers/individual/orders");
const CustomerWallet = () => import("@/pages/customers/individual/wallet");
const CustomerGardeners = () =>
  import("@/pages/customers/individual/gardeners");

const CustomerSubscriptions = () =>
  import("@/pages/customers/individual/subscriptions");
const CustomerCorporateSubscriptions = () =>
  import("@/pages/customers/corporate/subscriptions");

const CustomerRewardCentre = () =>
  import("@/pages/customers/individual/rewards");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "growth",
  "gardener",
  "gardener_pro",
];

const MODULE = "customers";

const routes = () => {
  return [
    {
      path: "/customers",
      name: "customers.index",
      component: Customers,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/customers/individual/add",
      name: "customers.individual-add",
      component: CustomerIndividualAdd,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/customers/individual/:id/",
      component: CustomersBase,
      children: [
        {
          path: "",
          name: "customers.individual",
          component: CustomerIndividual,
          meta: {
            module: MODULE,
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "edit",
          name: "customers.individual.edit",
          component: CustomerIndividualEdit,
          meta: {
            module: MODULE,
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "orders",
          name: "customers.individual.orders",
          component: CustomerOrders,
          meta: {
            module: MODULE,
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "gardeners-history-log",
          name: "customers.individual.gardeners",
          component: CustomerGardeners,
          meta: {
            module: MODULE,
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "subscriptions",
          name: "customers.individual-subscriptions",
          component: CustomerSubscriptions,
          meta: {
            module: MODULE,
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "rewards-centre",
          name: "customers.individual.rewards",
          component: CustomerRewardCentre,
          meta: {
            module: MODULE,
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "wallet",
          name: "customers.individual.wallet",
          component: CustomerWallet,
          meta: {
            module: MODULE,
            permission: authenticatedRoles,
            layout: "app",
          },
        },
      ],
    },
    {
      path: "/customers/corporate/add",
      name: "customers.corporate-add",
      component: CustomerCorporateAdd,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/customers/corporate/:id/",
      component: CustomersBase,
      children: [
        {
          path: "",
          name: "customers.corporate",
          component: CustomerCorporate,
          meta: {
            module: MODULE,
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "edit",
          name: "customers.corporate-edit",
          component: CustomerCorporateEdit,
          meta: {
            module: MODULE,
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "subscriptions",
          name: "customer.corporate-subscriptions",
          component: CustomerCorporateSubscriptions,
          meta: {
            module: MODULE,
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "wallet",
          name: "customers.corporate.wallet",
          component: CustomerCorporateWallet,
          meta: {
            module: MODULE,
            permission: authenticatedRoles,
            layout: "app",
          },
        },
      ],
    },
    {
      path: "/customers/onetime/add",
      name: "customers.onetime-add",
      component: CustomerOnetimeAdd,
      meta: {
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
