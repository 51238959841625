export default {
  methods: {
    roles() {
      return {
        admin: "Admin",
        gardener: "Gardener",
        gardener_pro: "Gardener Pro",
        growth: "Growth",
        kitchen: "Food Production",
        ofa: "OFA",
        operations: "Operations",
        steward: "Production Assistant",
        superadmin: "Super Admin",
        vendor_manager: "Vendor Manager",
      };
    },
    allowAccessFor(roles, strict) {
      if (roles === "all") {
        return true;
      }
      const role = localStorage.getItem("eden-crm-user-role");
      return strict
        ? !!roles.includes(role)
        : !!roles.concat("superadmin").includes(role);
    },
    allowAccessForFeature(env = "staging", roles) {
      const role = localStorage.getItem("eden-crm-user-role");
      return (
        process.env.NODE_ENV === env &&
        !!roles.concat("superadmin").includes(role)
      );
    },
    allowEventMarking(period, roles) {
      const role = localStorage.getItem("eden-crm-user-role");
      return (
        period === "today" &&
        !!roles.concat("superadmin", "admin", "operations").includes(role)
      );
    },
  },
};
