const ServicePartners = () => import("@/pages/serviceoperations/partners");
const ServicePartnersBase = () =>
  import("@/pages/serviceoperations/partners/base");
const ServicePartner = () =>
  import("@/pages/serviceoperations/partners/partner");
const ServicePartnerAdd = () =>
  import("@/pages/serviceoperations/partners/add");
const ServicePartnerEdit = () =>
  import("@/pages/serviceoperations/partners/edit");

const ProvisionedOrders = () =>
  import("@/pages/serviceoperations/provisionedorders");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "growth",
  "gardener",
  "gardener_pro",
];

const routes = () => {
  return [
    /**
     * Redirect to Service Partners Tracker page if someone tries to
     * access main module route [.../serviceoperations] without
     * specifying a sub-module  [.../serviceoperations/:sub-module]
     */
    {
      path: "/serviceoperations",
      redirect: {
        name: "serviceoperations.partners.index",
        component: ServicePartners,
      },
    },
    /**
     * Service Partners Sub-Module
     */
    {
      path: "/serviceoperations/partners",
      name: "serviceoperations.partners.index",
      component: ServicePartners,
      meta: {
        module: "partners",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/serviceoperations/partners/",
      component: ServicePartnersBase,
      meta: {
        module: "partners",
        permission: authenticatedRoles,
        layout: "app",
      },
      children: [
        {
          path: "add",
          name: "serviceoperations.partners.partner-add",
          component: ServicePartnerAdd,
          meta: {
            module: "partners",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: ":id",
          name: "serviceoperations.partners.partner",
          component: ServicePartner,
          meta: {
            module: "partners",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: ":id/edit",
          name: "serviceoperations.partners.partner-edit",
          component: ServicePartnerEdit,
          meta: {
            module: "partners",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
      ],
    },
    /**
     * Orders (previously Provisioned Orders) Sub-Module
     */
    {
      path: "/serviceoperations/orders",
      name: "serviceoperations.serviceoperations-orders.index",
      component: ProvisionedOrders,
      meta: {
        module: "serviceoperations-orders",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
