import axios from "axios";

// window.axios.defaults.baseURL =
//   "https://api-marketplace.edenlife.ng/api/v1/marketplace_crm/";

export default {
  list() {
    return axios.get("vendors?status=pending");
  },

  index(page) {
    return axios.get(`/marketplace_crm/vendors?status=Pending&page=${page}`);
  },

  search(search) {
    return axios.get(`marketplace_crm/vendors?status=Pending&query=${search}`);
  },
  vendorApproval(id, payload) {
    return axios.patch(
      `marketplace_crm/vendors/${id}/update-approval`,
      payload,
    );
  },

  //   search(query) {
  //     return axios.get(`customers/all/search/${query}`);
  //   },

  //   searchByName(query) {
  //     return axios.get(`customers/search/name/${query}`);
  //   },

  //   export() {
  //     return axios.get("customers/all/export");
  //   },

  //   filter(params) {
  //     return axios.get(`crm/customer/filter?${params}`);
  //   },

  //   add(payload) {
  //     return axios.post("customer", payload);
  //   },

  //   update(id, payload) {
  //     return axios.put(`customer/${id}`, payload);
  //   },

  //   get(id) {
  //     return axios.get(`customer/${id}`);
  //   },

  //   personas() {
  //     return axios.get("customers/persona");
  //   },

  //   feed(id, params) {
  //     return axios.get(`crm/customer/feed/${id}?${params}`);
  //   },

  //   gardenerLog(id) {
  //     return axios.get(`gardener_assignment_logs/${id}`);
  //   },

  //   gardenerChat(userId, gardenerId, startDate) {
  //     return axios.get(
  //       `customer/${userId}/gardener/${gardenerId}/chat_logs/${startDate}`,
  //     );
  //   },
};
