const BulkMessages = () => import("@/pages/communications/bulk-messages/index");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "growth",
  "gardener",
  "gardener_pro",
];

const routes = () => {
  return [
    /**
     * Redirect to Service Partners Tracker page if someone tries to
     * access main module route [.../communications] without
     * specifying a sub-module  [.../communications/:sub-module]
     */
    {
      path: "/communications",
      redirect: {
        name: "communications.bulk-messages.index",
        component: BulkMessages,
      },
    },
    /**
     * Bulk Messages Sub-Module
     */
    {
      path: "/communications/bulk-messages",
      name: "communications.bulk-messages.index",
      component: BulkMessages,
      meta: {
        module: "bulk-messages",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
