import * as mutations from "../../mutation-types";
import * as actions from "../../action-types";

import individual from "@/requests/customers/individual";
import corporate from "@/requests/customers/corporate";
import onetime from "@/requests/customers/onetime";

import customer from "@/requests/customers/customer/index";

export default {
  [actions.GET_CUSTOMERS_INDIVIDUAL](context, page) {
    return new Promise((resolve, reject) => {
      individual
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(
              mutations.CUSTOMERS_SUMMARY,
              response.data.data.summary
            );
            context.commit(mutations.CUSTOMERS_INDIVIDUAL, {
              page,
              data: response.data.data.customers,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_CUSTOMERS_CORPORATE](context, page) {
    return new Promise((resolve, reject) => {
      corporate
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.CUSTOMERS_CORPORATE, {
              page,
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_CUSTOMERS_ONETIME](context, page) {
    return new Promise((resolve, reject) => {
      onetime
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.CUSTOMERS_ONETIME, {
              page,
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_CUSTOMER_ORDERS](context, { id, period, page }) {
    return new Promise((resolve, reject) => {
      customer
        .orders(id, period, page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.CUSTOMER_ORDERS, {
              period,
              page,
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_CUSTOMER_TRANSACTIONS](context, { id, page }) {
    return new Promise((resolve, reject) => {
      customer
        .transactions(id, page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.CUSTOMER_TRANSACTIONS, {
              page,
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
