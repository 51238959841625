const Services = () => import("@/pages/services/index");
const ServiceBase = () => import("@/pages/services/service/base");
const Service = () => import("@/pages/services/service/index");
const ServiceTypeAdd = () => import("@/pages/services/service/add");
const ServiceTypeEdit = () => import("@/pages/services/service/edit");
const ServiceTypeView = () => import("@/pages/services/service/view");
const OneTimeEdit = () => import("@/pages/services/service/one-time-edit");
const OneTimeMenu = () => import("@/pages/services/service/one-time/menu");
const MenuAuditLogs = () => import("@/pages/services/service/logs");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "growth",
  "gardener",
  "gardener_pro",
];

const routes = () => {
  return [
    {
      path: "/services",
      name: "services.index",
      component: Services,
      meta: {
        module: "services",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/services/:service/",
      component: ServiceBase,
      children: [
        {
          path: "",
          name: "services.service",
          component: Service,
          meta: {
            module: "services",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "add",
          name: "services.service-type.add",
          component: ServiceTypeAdd,
          meta: {
            module: "services",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: ":id/edit",
          name: "services.service-type.edit",
          component: ServiceTypeEdit,
          meta: {
            module: "services",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: ":id/view",
          name: "services.service-type.view",
          component: ServiceTypeView,
          meta: {
            module: "services",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "menu/logs",
          name: "services.service-type.logs",
          component: MenuAuditLogs,
          meta: {
            module: "services",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: ":id/edit-onetime",
          name: "services.one-time.edit",
          component: OneTimeEdit,
          meta: {
            module: "services",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: ":id",
          name: "services.one-time.menu",
          component: OneTimeMenu,
          meta: {
            module: "services",
            permission: authenticatedRoles,
            layout: "app",
          },
        },

        // {
        //   path: "/services/:service/menu/:id",
        //   name: "services.one-time.menu",
        //   component: OneTimeMenu,
        //   meta: {
        //     module: "services",
        //     permission: authenticatedRoles,
        //     layout: "app",
        //   },
        // },
      ],
    },
  ];
};

export default routes();
