<template>
  <el-form :model="form" label-position="top" ref="form">
    <el-form-item :label="showLabel ? 'Services' : ''">
      <el-checkbox-group
        v-model="form.services"
        class="eden-custom--radio flex"
      >
        <el-checkbox
          v-for="(service, index) in services"
          :key="index"
          :label="service.service_name.toLowerCase()"
          @change="resetConfig($event, service.service_name.toLowerCase())"
          >{{ service.service_name }}</el-checkbox
        >
      </el-checkbox-group>
    </el-form-item>
    <template v-if="form.services.length">
      <component
        v-for="service in form.services"
        :is="`${service}-configuration`"
        :key="service"
        :one-time="oneTime"
        :config="config[service]"
        @update="updateConfig"
        :menu-type-variation="menuTypeVariation"
      />
    </template>
  </el-form>
</template>

<script>
export default {
  name: "SubscriptionConfiguration",
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
    oneTime: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default() {
        return {};
      },
    },
    menuTypeVariation: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        services: [],
        config: {},
        amount: 0,
      },
    };
  },
  computed: {
    services() {
      return this.$store.getters.services.filter((service) => {
        return this.enabledServices().includes(
          service.service_name.toLowerCase(),
        );
      });
    },
  },
  created() {
    this.setConfig();
  },

  watch: {
    config() {
      this.setConfig();
    },
  },
  methods: {
    setConfig() {
      this.form.services = Object.keys(this.config);
      this.form.services.forEach((service) => {
        this.form.config[service] = this.config[service];
      });
    },
    resetConfig(status, service) {
      status
        ? (this.form.config[service] = {})
        : delete this.form.config[service];
      this.updateConfigAmount();
      this.emitConfig();
    },

    updateConfigAmount() {
      this.form.amount = this.form.services.reduce((total, item) => {
        return total + this.form.config[item].amount;
      }, 0);
    },
    updateConfig({ validity, response, service }) {
      this.form.config[service] = { ...response };
      this.updateConfigAmount();
      this.$refs.form.validate(async (valid) => {
        if (valid && validity) {
          this.emitConfig();
        }
      });
    },
    emitConfig() {
      // console.log(this.form.config, "config")
      this.$emit("set-config", {
        config: this.form.config,
        amount: this.form.amount,
      });
    },
  },
};
</script>

<style lang="scss">
.service-configuration {
  padding: 15px 15px 20px;
  border-radius: 6px;
  background: #f0f4f2;
  border: 1.5px solid #cbd8d2;
  margin-top: 20px;

  &.beauty {
    background: var(--eden-beauty-bg);
    border-color: var(--eden-beauty-border);
  }

  &.cleaning {
    background: var(--eden-cleaning-bg);
    border-color: var(--eden-cleaning-border);
  }

  &.laundry {
    background: var(--eden-laundry-bg);
    border-color: var(--eden-laundry-border);
  }

  &.meal {
    background: var(--eden-meal-bg);
    border-color: var(--eden-meal-border);
  }

  &.card {
    background: var(--eden-grey-octonary);
    border-color: var(--eden-grey-octonary);
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    p {
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--eden-grey-primary);
      line-height: 1.6;
    }

    span {
      height: 16px;
      width: 16px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #21312a10;
      cursor: pointer;

      i {
        color: var(--eden-grey-primary);
        font-size: 0.75rem;
        font-weight: 600;
      }
    }
  }

  .counter {
    display: flex;
    align-items: center;
    width: 100%;

    .el-input {
      margin: 0 10px;

      &__inner {
        height: 28px;
        line-height: 28px;
        text-align: center;
      }
    }
  }

  .el-form-item__content {
    line-height: 36px !important;
    justify-content: flex-start !important;
  }

  .service-summary {
    text-align: center;
    max-width: 80%;
    margin: 12px auto;

    span {
      font-size: 0.825rem;
      display: inline-block;
      line-height: 1.4;
      text-align: center;
      color: var(--eden-grey-tertiary);
    }
  }
}
</style>
