import axios from "axios";

export default {
  list(){
    return axios.get(`crm/beautician`);
  },
  index(page) {
    return axios.get(`crm/beautician?page=${page}`);
  },

  search(query) {
    return axios.get(`crm/beautician?name=${query}`);
  },

  add(payload) {
    return axios.post("crm/beautician", payload);
  },

  get(id) {
    return axios.get(`crm/beautician?beautician_id=${id}`);
  },

  update(payload) {
    return axios.post("crm/beautician/update", payload);
  },

  delete(payload) {
    return axios.post("crm/beautician/delete", payload);
  },
};
