import axios from "axios";

export default {
  index(page) {
    return axios.get(`discounts?page=${page}`);
  },

  search(query) {
    return axios.get(`discounts/search/${query}`);
  },

  add(payload) {
    return axios.post("discounts", payload);
  },

  get(id) {
    return axios.get(`discounts/${id}`);
  },

  update(id, payload) {
    return axios.put(`discounts/${id}`, payload);
  },

  delete(id) {
    return axios.post(`discounts/${id}/delete`);
  },

  link(payload) {
    return axios.post("discount/link_customer", payload);
  },

  unlink(payload) {
    return axios.post("discount/unlink_customer", payload);
  },
};
