const LOCATION = localStorage.getItem("eden-crm-location");

import mixpanel from "mixpanel-browser";
mixpanel.init(
  LOCATION === "KE"
    ? process.env.VUE_APP_MIXPANEL_KEY_KE
    : process.env.VUE_APP_MIXPANEL_KEY_NG
);

export default {
  methods: {
    mixpanelIdentify(id) {
      mixpanel.identify(id);
    },
    mixpanelPeople({ email, full_name, id, role }) {
      mixpanel.people.set({
        $email: email,
        $name: full_name,
        $id: id,
        $role: role,
      });
    },
    mixpanelTrack({ event, action, value }) {
      mixpanel.track(event, {
        action,
        value,
      });
    },
  },
};
