<template>
  <el-dialog
    :title="title"
    :visible.sync="setShow"
    :close-on-click-modal="closeOnClickModal"
    @close="close"
    width="25%"
    append-to-body
  >
    <slot />
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="cancel">{{ cancelText }}</el-button>
      <el-button :type="buttonType" :loading="buttonStatus" @click="confirm">{{
        buttonText
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "EdenConfirmDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    buttonType: {
      type: String,
      default: "success",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    buttonText: {
      type: String,
      default: "Confirm",
    },
    buttonStatus: {
      type: Boolean,
      default: false,
    },
    closeOnClickModal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    setButtonStatus: {
      get() {
        return this.buttonStatus;
      },
      set(value) {
        this.$emit("update:buttonStatus", value);
      },
    },
  },
  methods: {
    close() {
      this.setButtonStatus = false;
      this.setShow = false;
      this.$emit("close");
    },
    cancel() {
      this.close();
      this.$emit("cancel");
    },
    confirm() {
      this.setButtonStatus = true;
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss" scoped></style>
