import axios from "axios";

export default {
  index(page) {
    return axios.get(`subscriptions/all?page=${page}`);
  },

  get(email) {
    return axios.get(`subscriptions/${email}`);
  },

  add(payload) {
    return axios.post("subscriptions", payload);
  },

  update(id, payload) {
    return axios.put(`subscriptions/${id}`, payload);
  },

  servicesCount(id) {
    return axios.get(`subscriptions/${id}/services_metadata/current_cycle`);
  },

  servicesReceived(id) {
    return axios.get(`subscriptions/${id}/services_delivered/current_cycle`);
  },

  pending(id) {
    return axios.get(`subscriptions/${id}/queued_pending_plan`);
  },

  pricing() {
    return axios.get("pricing_config");
  },

  search(query) {
    return axios.get(`subscriptions/all/search/${query}`);
  },

  filter(payload) {
    return axios.post("subscriptions/filter", payload);
  },
};
