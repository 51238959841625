<template>
  <el-select
    v-model="setModel"
    filterable
    :multiple="multiple"
    :placeholder="placeholder"
    :value-key="'id'"
  >
    <el-option
      v-for="personnel in list"
      :key="personnel.id"
      :label="formatName(personnel.name)"
      :value="returnObject ? personnel : personnel[itemKey]"
    />
  </el-select>
</template>

<script>
import members from "@/requests/settings/members";
import * as actions from "@/store/action-types";

export default {
  name: "EdenPersonnelSelect",
  props: {
    personnelType: {
      type: String,
      default: "",
    },
    personnels: {
      type: Array,
      default() {
        return [];
      },
    },
    personnel: {
      type: [String, Number, Object],
      default: "",
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    itemKey: {
      type: String,
      default: "id",
    },
    personnelName: {
      type: String,
      default: "",
    },
    service: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fetching: false,
      list: [],
    };
  },
  computed: {
    setPersonnels: {
      get() {
        return this.personnels;
      },
      set(value) {
        this.$emit("update:personnels", value);
      },
    },
    setPersonnel: {
      get() {
        return this.personnel;
      },
      set(value) {
        this.$emit("update:personnel", value);
      },
    },
    setModel: {
      get() {
        return this.multiple ? this.personnels : this.personnel;
      },
      set(value) {
        this.$emit(
          `update:${this.multiple ? "personnels" : "personnel"}`,
          value,
        );
      },
    },
  },
  created() {
    switch (this.personnelType) {
      case "beautician":
        this.getBeauticians();
        break;
      case "cleaner":
        this.getCleaners();
        break;
      case "member":
        this.getMembers();
        break;
      case "rider":
        this.getRiders();
        break;
      case "service-partner":
        this.getServicePartners();
        break;
      default:
        return;
    }
  },
  methods: {
    mapValue(value) {
      if (this.multiple) {
        if (!this.returnObject) {
          return value?.map((item) => item[this.itemKey]);
        }
        return value;
      } else {
        return this.returnObject ? value : value[this.itemKey];
      }
    },
    getMembers() {
      this.fetching = true;
      members
        .list()
        .then((response) => {
          if (response.data.status) {
            this.list = response.data.data;
          }
        })
        .catch();
    },
    getBeauticians() {},
    getCleaners() {},
    getRiders() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_LOGISTICS_DIRECTORY, {
          directory: "riders",
        })
        .then((response) => {
          this.list = response.data.data.map((rider) => ({
            ...rider, name: rider.firstname + " " + rider.lastname 
          }));
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    async getServicePartners() {
      await this.$store.dispatch("GET_SERVICE_PARTNERS_LIST");
      this.list = this.$store.getters.service_partners_list[this.service];
    },
  },
};
</script>

<style lang="scss" scoped></style>
