export default {
  customers_summary: (state) => state.customers_summary,
  customers_individual: (state) => state.customers_individual,
  customers_corporate: (state) => state.customers_corporate,
  customers_onetime: (state) => state.customers_onetime,
  customer_schedule: (state) => state.customer_schedule,
  customer_orders: (state) => state.customer_orders,
  customer_activity: (state) => state.customer_activity,
  customer_transactions: (state) => state.customer_transactions,
};
