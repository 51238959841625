const MFCs = () => import("@/pages/fulfillment/index");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "growth",
  "gardener",
  "gardener_pro",
];

const MODULE = "fulfillment";

const routes = () => {
  return [
    {
      path: "/mfc",
      name: "fulfillment.index",
      component: MFCs,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
