<template>
  <div>
    <div class="eden-crm--sidebar">
      <div class="user">
        <div class="user-info">
          <eden-avatar :src="user.avatar" :size="26" />
          <div class="user-info--name">
            <p>{{ user.name }}</p>
            <span>{{ roles()[user.role] }}</span>
          </div>
        </div>
        <el-dropdown trigger="click" @command="command" placement="bottom-end">
          <i class="eden-icon-arrow-down"></i>
          <el-dropdown-menu :class="'user-impersonate'" slot="dropdown">
            <div class="roles">
              <el-menu>
                <el-submenu v-if="showImpersonate" index="1">
                  <template slot="title">
                    <div class="title">
                      <i class="eden-icon-impersonate"></i>
                      <span>Impersonate </span>
                    </div>
                  </template>
                  <template v-for="(value, key, i) in roles()">
                    <el-dropdown-item
                      index="1-1"
                      v-if="key !== 'superadmin' && key !== userRole"
                      :key="i"
                      :command="key"
                      :class="{ selected: impersonatedRole === key }"
                    >
                      <div>
                        <span>{{ value }}</span>
                        <i
                          v-if="impersonatedRole === key"
                          class="eden-icon-check"
                        ></i>
                      </div>
                    </el-dropdown-item>
                  </template>
                </el-submenu>
                <el-dropdown-item icon="eden-icon-logout" command="logout"
                  >Logout</el-dropdown-item
                >
              </el-menu>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <eden-location-switch v-if="allowLocationChange" />

      <eden-tab-toggle
        v-if="
          allowAccessFor([
            'superadmin',
            'admin',
            'vendor_manager',
            'growth',
            'operations',
            'gardener',
            'gardener_pro',
          ])
        "
        :current-nav-mode="currentNavMode"
        :toggle-items="navToggleItems"
        @toggle-nav-mode="(item) => (currentNavMode = item)"
      />

      <el-menu>
        <el-menu-item
          :class="`notification ${
            showNotification === true ? 'active' : 'inactive'
          }`"
          @click="showNotification = true"
          index="notifications"
        >
          <span class="icon">
            <i :class="`eden-icon-notification`"></i>
          </span>

          <span>Notifications</span>
          <span class="badge">
            {{ notificationCount }}
          </span>
        </el-menu-item>
      </el-menu>

      <div class="sidebar-routes">
        <el-menu
          v-if="currentNavMode === 'CRM'"
          :default-active="nav"
          :router="true"
        >
          <template v-for="(route, index) in navRoutes">
            <template v-if="!route.sub_routes">
              <el-menu-item
                v-if="allowAccessFor(route.access, true)"
                :key="index"
                :index="route.name"
                :route="{ name: `${route.name}.index` }"
                :disabled="!route.active"
                @click="closeSidebar"
              >
                <span class="icon">
                  <i :class="`eden-icon-${route.icon}`"></i>
                </span>
                <span>{{ route.label }}</span>
              </el-menu-item>
            </template>
            <template v-else>
              <el-submenu
                v-if="allowAccessFor(route.access)"
                class="el-submenu__sidenav"
                :key="index"
                :index="route.name"
                :disabled="!route.active"
              >
                <template slot="title">
                  <span class="icon"
                    ><i :class="`eden-icon-${route.icon}`"></i
                  ></span>
                  <span> {{ route.label }} </span>
                </template>
                <el-menu-item
                  v-for="(sub_route, index) in route.sub_routes"
                  :key="index"
                  :index="sub_route.name.split('.')[1]"
                  :route="{ name: `${sub_route.name}.index` }"
                  :disabled="!sub_route.active"
                  @click="closeSidebar"
                  :class="{ 'is-hidden': !allowAccessFor(sub_route.access) }"
                >
                  {{ sub_route.label }}
                </el-menu-item>
              </el-submenu>
            </template>
          </template>
        </el-menu>

        <el-menu v-else :default-active="nav" :router="true">
          <template v-for="(route, index) in navRoutesMarketplace">
            <template v-if="!route.sub_routes">
              <el-menu-item
                v-if="allowAccessFor(route.access, true)"
                :key="index"
                :index="route.name"
                :route="{ name: `${route.name}.index` }"
                :disabled="!route.active"
                @click="closeSidebar"
              >
                <span class="icon">
                  <i :class="`eden-icon-${route.icon}`"></i>
                </span>
                <span>{{ route.label }}</span>
              </el-menu-item>
            </template>
            <template v-else>
              <el-submenu
                v-if="allowAccessFor(route.access)"
                class="el-submenu__sidenav"
                :key="index"
                :index="route.name"
                :disabled="!route.active"
              >
                <template slot="title">
                  <span class="icon">
                    <i :class="`eden-icon-${route.icon}`"></i>
                  </span>
                  <span> {{ route.label }} </span>
                </template>
                <el-menu-item
                  v-for="(sub_route, index) in route.sub_routes"
                  :key="index"
                  :index="sub_route.name.split('.')[1]"
                  :route="{ name: `${sub_route.name}.index` }"
                  :disabled="!sub_route.active"
                  @click="closeSidebar"
                  :class="{ 'is-hidden': !allowAccessFor(sub_route.access) }"
                >
                  {{ sub_route.label }}
                </el-menu-item>
              </el-submenu>
            </template>
          </template>
        </el-menu>
      </div>

      <div class="sidebar-calculator-cta" @click="$emit('calculator')">
        <img :src="getImage('calculator.svg')" alt="Cal" />
      </div>
    </div>
    <notification
      :show.sync="showNotification"
      :notificationData="notifications"
      :loading="loading"
      @markRead="markNotificationsAsRead"
      @update-list="updateList"
    />
  </div>
</template>

<script>
import { navRoutes } from "@/components/Navigation/sidenav-routes";
import { navRoutesMarketplace } from "@/components/Navigation/sidenav-routes-marketplace";
import Notification from "@/components/Notification/Notification.vue";

import * as actions from "@/store/action-types";
import * as mutations from "@/store/mutation-types";
import EdenTabToggle from "../Global/EdenTabToggle.vue";
import { HeadlessService } from "@novu/headless";

export default {
  name: "EdenCrmSidebar",
  components: { Notification, EdenTabToggle },
  data() {
    return {
      nav: "dashboard",
      navRoutes: navRoutes(),
      navRoutesMarketplace: navRoutesMarketplace(),
      notificationCount: 0,
      showNotification: false,
      notifications: {},
      headlessServiceRef: null,
      page: 0,
      loading: false,
      applicationIdentifier: process.env.VUE_APP_NOVU_APP_IDENTIFIER,
      navToggleItems: ["CRM", "Marketplace"],
      currentNavMode: "CRM",
    };
  },
  computed: {
    user() {
      const { avatar, full_name, role, logged_in_userId } =
        this.$store.getters.user;
      const names = full_name.split(" ");
      const userId = logged_in_userId;

      return {
        avatar,
        name:
          names[0].length > 10
            ? `${names[0].charAt(0)} ${names[1].charAt(0)}`
            : `${names[0]} ${names[1].charAt(0)}.`,
        role,
        userId,
      };
    },
    showImpersonate() {
      const { role } = this.$store.getters.user;
      return ["superadmin", "admin"].includes(role);
    },
    impersonatedRole() {
      return this.$store.getters.user_role;
    },
    userRole() {
      return this.$store.getters.user.role;
    },
    allowLocationChange() {
      const impersonate = this.$store.getters.impersonate;
      return impersonate || this.allowAccessFor(["superadmin", "admin"]);
    },
    // nav() {
    //   if (this.currentNavMode === "CRM") {
    //     return "dashboard";
    //   } else {
    //     return "vendors";
    //   }
    // },
  },
  mounted() {
    const { module } = this.$route.meta;
    this.nav = module ?? "";
    module === "vendors" ? (this.currentNavMode = "Marketplace") : "CRM";
    this.sessionLoaded();
  },
  watch: {
    $route() {
      const { module } = this.$route.meta;
      this.nav = module ?? "";
    },
    currentNavMode() {
      if (this.currentNavMode === "Marketplace") {
        this.nav = "vendors";
        if (this.$route.meta.module === "vendors") return;
        this.$router.push({ name: "vendors.index" });
      } else {
        this.$router.push({ name: "dashboard.index" });
        this.nav = "dashboard";
      }
    },
  },
  methods: {
    closeSidebar() {
      this.$emit("close");
    },
    command(command) {
      if (command === "logout") {
        this.logOut();
      } else {
        this.impersonate(command);
      }
    },
    impersonate(key) {
      this.$store.commit(mutations.USER_ROLE, key);
      this.$store.commit(mutations.IMPERSONATE, true);

      const { name, meta } = this.$route;

      if (["steward", "ofa"].includes(key)) {
        const index =
          key === "steward"
            ? "production-provisions.index"
            : "order-fulfillment.index";
        if (name !== index) {
          this.$router.push({ name: index });
        } else {
          this.$emit("update-key");
        }
      } else {
        if (meta.permission.includes(key)) {
          this.$emit("update-key");
        } else {
          this.$router.push({ name: "customers.index" });
        }
      }

      this.closeSidebar();
    },
    changeLocation(location) {
      this.location = location;
      this.$store.commit(mutations.LOCATION, location);
      window.location.reload();
    },
    logOut() {
      this.$store.dispatch(actions.LOGOUT).then((response) => {
        if (response) {
          this.$message.success("You are logged out.");
          this.$router.push({ name: "login" });
        }
      });
    },

    sessionLoaded() {
      const headlessService = new HeadlessService({
        applicationIdentifier: this.applicationIdentifier,
        subscriberId: String(this.user.userId),
      });

      headlessService.initializeSession({
        listener: (res) => {
          this.loading = res.isLoading;
        },
        onSuccess: () => {
          this.headlessServiceRef = headlessService;
          this.fetchNotifications();
          this.fetchUnseenCount();
          headlessService.listenUnseenCountChange({
            listener: (unseenCount) => {
              if (unseenCount) {
                this.fetchNotifications();
                this.fetchUnseenCount();
              }
            },
          });

          headlessService.listenUnreadCountChange({
            listener: (unreadCount) => {
              if (unreadCount) {
                this.fetchNotifications();
              }
            },
          });
        },
        onError: (error) => {
          this.$message.error(error.message);
        },
      });
    },
    fetchNotifications() {
      this.headlessServiceRef.fetchNotifications({
        listener: (res) => {
          this.loading = res.isLoading;
        },
        onSuccess: (response) => {
          this.notifications = response;
        },
        page: this.page,
      });
    },
    fetchUnseenCount() {
      this.headlessServiceRef.fetchUnseenCount({
        listener: (res) => {
          this.loading = res.isLoading;
        },
        onSuccess: (data) => {
          this.notificationCount = data.count;
        },
        onError: (error) => {
          this.$message.error(error.message);
        },
      });
    },
    markNotificationsAsRead(messageIds) {
      if (!Array.isArray(messageIds)) {
        messageIds = [messageIds];
      }

      this.headlessServiceRef.markNotificationsAsRead({
        messageId: messageIds,
        listener: (res) => {
          this.loading = res.isLoading;
        },
        onError: (error) => {
          this.$message.error(error.message);
        },
      });
    },
    updateList(page) {
      this.page = page;
      this.fetchNotifications();
    },
  },
};
</script>

<style lang="scss" scoped>
.eden-crm--sidebar {
  background: var(--eden-sidenav);
  height: 100vh;
  transition: width 0.3s ease-in-out;
  width: 100%;
  padding: 12px;
  position: relative;
  overflow-y: scroll;

  .user {
    background: #ffffff;
    border: 1px solid #e2e9e6;
    border-radius: 8px;
    width: 100%;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #1d352a;

      &--name {
        margin-left: 10px;
        font-size: 0.75rem;
        color: var(--eden-grey-secondary);

        p {
          font-weight: 500;
          line-height: 1.5;
        }

        span {
          font-weight: 400;
          opacity: 0.74;
        }
      }
    }
  }

  .sidebar-routes {
    height: 70vh;
    overflow-y: scroll;
  }

  .el-menu {
    border: none;
    background: none;
    margin-top: 16px;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    background: var(--eden-sidenav);

    .el-menu-item {
      border-radius: 8px;
      background: var(--eden-sidenav);
      color: var(--eden-grey-tertiary);
      display: flex;
      align-items: center;
      font-size: 0.7rem;
      font-weight: 500;
      height: 38px;
      line-height: 20px;
      padding: 14px 0 14px 12px !important;
      transition: all 0.25s ease-in-out;

      .icon {
        width: 14px;
        height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 14px;

        i {
          color: var(--eden-grey-tertiary);
          font-size: 1.125rem;
          transition: color 0.25s ease-in-out;
        }
      }

      &.is-active {
        color: #ffffff;
        background: var(--eden-green-primary);
        transition: all 0.25s ease-in-out;

        i {
          color: #ffffff !important;
          transition: color 0.25s ease-in-out;
        }
      }

      &.is-disabled {
        opacity: 0.6;
      }

      &:hover:not(.is-active) {
        background: #bdeecd60;
        color: var(--eden-green-primary);

        i {
          color: var(--eden-green-primary);
        }
      }

      &.notification {
        border-bottom: 1px solid #e2e9e6;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        position: relative;

        &.active {
          background: #d4f7e5;
          color: #03a84e;

          i {
            color: #03a84e !important;
          }
        }

        &.inactive {
          background: transparent;
          color: #4b6358 !important;
          i {
            color: #4b6358 !important;
          }
        }

        .badge {
          height: 18px;
          width: 18px;
          border-radius: 50%;
          background: #e1404d;
          color: #ffffff;
          font-size: 11px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
        }
      }
    }
  }
  .el-submenu {
    background: var(--eden-sidenav) !important;

    &__title {
      i {
        font-size: 1.125rem;
      }
    }

    .el-menu {
      background-color: var(--eden-sidenav) !important;
    }

    .el-menu-item {
      background: var(--eden-sidenav) !important;
      padding-left: 40px !important;

      &:hover {
        background: var(--eden-sidenav) !important;
      }

      &.is-active {
        background: var(--eden-green-senary) !important;
        color: var(--eden-green-primary);
      }
    }
  }

  .sidebar-calculator-cta {
    height: 36px;
    width: 36px;
    border-radius: 100px;
    background: rgba(3, 168, 78, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
    overflow: hidden;

    img {
      height: 16px;
      width: auto;
    }
  }
}

.user-impersonate {
  left: var(--sidenav-padding) !important;
  top: 65px !important;
  width: calc(
    #{var(--sidenav-width)} - (#{var(--sidenav-padding)} * 2)
  ) !important;

  .el-menu {
    border: none;
  }
  .roles {
    .title {
      display: flex;
      align-items: center;
      font-size: 0.75rem;
      color: var(--eden-grey-primary);

      i {
        margin-right: 7px;
      }
    }
    .el-dropdown-menu__item {
      line-height: 1.4;
      padding: 11px 20px;
      font-size: 0.75rem;

      > div {
        display: flex;
        justify-content: space-between;
        width: 100%;

        i {
          color: var(--eden-green-primary) !important;
        }
      }
    }
  }
}
</style>
