<template>
  <span :class="['indicator', status]"></span>
</template>

<script>
export default {
  name: "EdenIndicator",
  props: {
    status: {
      type: String,
      default: "active",
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.indicator {
  display: inline-block;
  height: 7px;
  width: 7px;
  border-radius: 20px;
  background: var(--eden-grey-primary);

  &.active,
  &.unpause {
    background: var(--eden-green-primary);
  }

  &.paused {
    background: var(--eden-orange-primary);
  }

  &.inactive {
    background: #e13135;
  }
}
</style>
