<template>
  <el-dialog :visible.sync="shouldShow" :show-close="false" width="25%">
    <div class="is-align-center text-bold text-cursor">
      <div class="text-center mb-10">
        <span
          @click="selectMonthFormat = true"
          :class="[selectMonthFormat ? 'text-primary' : '', 'mr-2']"
          >{{ selectedMonth }}</span
        >
        <span
          @click="selectMonthFormat = false"
          :class="!selectMonthFormat ? 'text-primary' : ''"
          >{{ selectedYear }}</span
        >
      </div>
      <div v-if="selectMonthFormat">
        <span
          v-for="(monthGroup, i) in months"
          :key="i"
          class="newRow is-flex is-justify-between"
        >
          <span
            v-for="(month, index) in monthGroup"
            :key="index"
            :class="[selectedMonth === month ? 'activeClass' : '', 'field']"
            @click="selectMonth(i, index)"
          >
            {{ month.slice(0, 3) }}
          </span>
        </span>
      </div>
      <div v-else>
        <div class="text-center newRow">
          <i
            class="eden-icon-arrow-left font-sm mr-2"
            @click="displayNewYears('-')"
          />
          <span class="text-light"
            >{{ firstDisplayedYear }} -
            {{ firstDisplayedYear + yearsDisplayed - 1 }}</span
          >
          <i
            class="eden-icon-arrow-right font-sm ml-2"
            @click="displayNewYears('+')"
          />
        </div>
        <span
          v-for="(row, i) in yearsDisplayed / 4"
          :key="i"
          class="newRow is-flex is-justify-between"
        >
          <span
            v-for="(year, index) in 4"
            :key="index"
            :class="[
              selectedYear === firstDisplayedYear + i * 4 + index
                ? 'activeClass'
                : '',
              'field',
            ]"
            @click="selectYear(firstDisplayedYear + i * 4 + index)"
          >
            {{ firstDisplayedYear + i * 4 + index }}
          </span>
        </span>
      </div>
    </div>
    <span slot="footer" class="dialog-footer is-flex is-justify-between">
      <el-button plain @click="confirm('reset')">Reset to All time</el-button>
      <el-button type="primary" :loading="loading" @click="confirm">
        Set Date
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "EdenCustomCalendar",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    yearsDisplayed: {
      type: Number,
      default: 8,
    },
  },
  data() {
    return {
      loading: false,
      months: [
        ["January", "February", "March", "April"],
        ["May", "June", "July", "August"],
        ["September", "October", "November", "December"],
      ],
      selectedMonth: "",
      selectMonthFormat: true,
      selectedYear: "",
      firstDisplayedYear: "",
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        const dateNow = new Date();
        let mergedMonths = [].concat.apply([], this.months);
        this.selectedMonth = mergedMonths[dateNow.getMonth()];
        this.selectedYear = dateNow.getFullYear();
        this.firstDisplayedYear = this.selectedYear - this.yearsDisplayed + 1;
      }
    },
  },
  methods: {
    closeEvent() {
      this.selectMonthFormat = true;
      this.shouldShow = false;
    },
    selectMonth(i, index) {
      this.selectedMonth = this.months[i][index];
    },
    selectYear(value) {
      this.selectedYear = value;
    },
    displayNewYears(action) {
      if (action === "-") {
        this.firstDisplayedYear -= this.yearsDisplayed;
      } else {
        this.firstDisplayedYear += this.yearsDisplayed;
      }
    },
    confirm(value) {
      console.log(value);
      if (value === "reset") {
        this.$emit("setdate", { text: "All Time", value: "" });
      } else {
        const monthIndex = this.getMonthNumber(this.selectedMonth);
        const format = `${this.selectedYear}-${String(monthIndex)?.padStart(
          2,
          "0",
        )}`;
        this.$emit("setdate", {
          text: this.selectedMonth + " " + this.selectedYear,
          value: format,
        });
      }

      this.closeEvent();
    },
  },
};
</script>
<style>
.newRow {
  margin-top: 30px;
}
.activeClass {
  background-color: var(--eden-green-primary);
  color: var(--white);
}
.field {
  padding: 4px 10px;
  border-radius: 999px;
}
.text-light {
  font-weight: 200;
}
</style>
