import { pricing, setEnv } from "@edenlife/eden-pricing-module";

/**
 * Set environment
 */
setEnv(process.env.NODE_ENV === "production" ? "production" : process.env.NODE_ENV === "staging" ? "staging" : "develop");

/**
 * Localization
 */
const COUNTRY = localStorage.getItem("eden-crm-location");

/**
 * Use our pricing module to get services pricing
 */
export default {
  methods: {
    pricing(services) {
      return pricing({ location: COUNTRY ? COUNTRY : "NG", config: services });
    },
  },
};
