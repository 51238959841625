<template>
  <div class="eden-empty--page">
    <el-empty
      :image="getImage('empty-page.svg')"
      :description="'Currently building...'"
      :image-size="200"
    />
  </div>
</template>

<script>
export default {
  name: "EdenPageEmpty",
  props: {
    text: {
      type: String,
      default: "No data",
    },
    size: {
      type: Number,
      default: 140,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.eden-empty--page {
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.eden-empty--page .el-empty__description p {
  color: var(--eden-grey-tertiary) !important;
  font-weight: 500;
  margin-top: 10px;
  font-size: 1.25rem !important;
}
</style>
