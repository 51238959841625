import * as types from "../../mutation-types";

export default {
  [types.FEEDBACK_SUMMARY](state, { period, feedback_summary }) {
    state.feedback_summary[period] = {};
    state.feedback_summary[period] = feedback_summary;
  },

  [types.FEEDBACK](state, { type, page, feedback }) {
    state.feedback[type][page] = feedback;
  },

  [types.RESET_STATE](state, defaultState) {
    Object.assign(state, defaultState.feedbacks);
  }
};
