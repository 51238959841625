import axios from "axios";

export default {
  index(page) {
    return axios.get(`corporate_customer?page=${page}`);
  },

  search(query) {
    return axios.get(`corporate_customer/search/${query}`);
  },

  export() {
    return axios.get("corporate_customers/export");
  },

  add(payload) {
    return axios.post("corporate_customer", payload);
  },

  update(id, payload) {
    return axios.put(`corporate_customer/${id}`, payload);
  },

  get(id) {
    return axios.get(`corporate_customer/${id}`);
  },

  delete(id) {
    return axios.delete(`corporate_customer/${id}`);
  },

  personas() {
    return axios.get("corporate/persona");
  },

  status(id, payload) {
    return axios.put(`corporate_customer/${id}`, payload);
  },

  getEmployees(id) {
    return axios.get(`corporate_customer/${id}/employees`);
  },

  addEmployee(payload) {
    return axios.post("customer", payload);
  },

  addEmployees(payload) {
    return axios.post("customers/bulkupload", payload);
  },

  linkEmployees(payload) {
    return axios.post("corporate_customer/link_individual_customer", payload);
  },

  renewSubscription(id, payload) {
    return axios.post(`corporate_customer/${id}/renew_subscription`, payload);
  },

  getSubscriptionCycles(id) {
    return axios.get(`subscriptions/all_cycles/${id}`);
  },

  currentSubscriptionPauses(id) {
    return axios.get(`subscriptions/current/all_pauses/corporate/${id}`);
  },

  subscriptionPauses(id) {
    return axios.get(`subscriptions/all_pauses/corporate/${id}`);
  },

  getPausedServices(payload) {
    return axios.post(
      `corporate_customer/sub/fetch_total_paused_orders`,
      payload,
    );
  },

  ordersPause(payload) {
    return axios.post("pause_orders", payload);
  },

  ordersUnpause(payload) {
    return axios.post("unpause_orders", payload);
  },

  generateInvoice(payload) {
    return axios.post(
      `corporate_customer/subscription/generate_invoice_for_next_sub`,
      payload,
    );
  },
  reschedule(payload) {
    return axios.post(
      `crm/order/reschedule_delivery_date_for_corporate_customer`,
      payload,
    );
  },
  employeeReschedule(payload) {
    return axios.post(
      `crm/order/reschedule_delivery_date_for_customers`,
      payload,
    );
  },
  updateStatus(payload) {
    return axios.post(`customers/update_customer_subscriber_status`, payload);
  },
};
