
<template>
  <div class="attachments-upload">
    <div v-if="loading" class="loader">
      <i class="el-icon-loading"></i>
    </div>
    <div
      class="attachments-upload--title drag-and-drop"
      v-if="!setAttachments.length"
    >
      <el-upload
        action=""
        multiple
        :on-change="reviewAttachments"
        :show-file-list="false"
        :auto-upload="false"
        :accept="attachmentsType.toString()"
        ref="upload"
        @before-upload="() => false"
        @dragenter.native="onDragEnter"
        @dragover.native="onDragOver"
        @dragleave.native="onDragLeave"
        @drop.native="onDrop"
      >
        <div class="">
          <p>Drag and drop your file in this area or</p>
          <el-button
            type="plain"
            class="is-flex mx-auto"
            size="medium"
            icon="eden-icon-upload"
          >
            Browse files
          </el-button>
        </div>
      </el-upload>
    </div>
    <div class="attachments-upload--content" v-else>
      <div
        v-for="(attachment, index) in setAttachments"
        :key="index"
        class="attachment"
      >
        <p>
          <img
            :src="
              getAttachmentImage(attachment.url ? attachment.url : attachment)
            "
            :alt="'X'"
          />
          {{
            attachment.name
              ? attachment.name
              : attachment.slice(attachment.lastIndexOf("/") + 1)
          }}
          <span class="text-grey-tertiary ml-1">
            ({{ attachment.sizeMB }}MB)</span
          >

          <span class="remove" @click="removeAttachment(index)">Remove</span>
        </p>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "EdenAttachmentsDragAndDrop",
  props: {
    subtitle: {
      type: String,
      default: "",
    },
    attachments: {
      type: Array,
      default() {
        return [];
      },
    },
    fileFolder: {
      type: String,
      default: "ungrouped",
    },
    attachmentsType: {
      type: Array,
      default() {
        return [".jpeg,.jpg,.png,.pdf,.doc,.docx,.webp"];
      },
    },
  },
  data() {
    return {
      loading: false,
      cloudinaryUrl: "https://api.cloudinary.com/v1_1/eden-life-inc/upload",
      cloudinaryPresetUpload: "zt3wbrxu",
    };
  },
  computed: {
    setAttachments: {
      get() {
        return this.attachments;
      },
      set(value) {
        this.$emit("update:attachments", value);
      },
    },
  },
  methods: {
    resetAttachments() {
      this.setAttachments = [];
    },
    getAttachmentType(extension) {
      const images = ["jpg", "jpeg", "png"];
      return images.includes(extension) ? "image" : "file";
    },
    getAttachmentName(name) {
      const extension = name.split(".").pop();
      return name.length > 30 ? `${name.slice(0, 30)}...${extension}` : name;
    },
    getAttachmentImage(url) {
      const extension = url.split(".").pop();
      switch (extension) {
        case "pdf":
          return this.getImage("attachments/pdf.svg");
        case "doc":
          return this.getImage("attachments/doc.svg");
        case "docx":
          return this.getImage("attachments/doc.svg");
        default:
          return url;
      }
    },
    reviewAttachments(file) {
      let fileSizeMB = file.size / 1024 / 1024;
      if (fileSizeMB < 20) {
        let fileObject = {
          name: this.getAttachmentName(file.name),
          size: file.size,
          sizeMB: fileSizeMB.toFixed(2),
          raw: file.raw,
          url: file.name,
          extension: file.name.split(".").pop(),
        };
        this.uploadFile(file, fileObject);
      } else {
        this.$message.error("File cannot be larger than 20MB!");
      }
    },
    removeAttachment(index) {
      this.setAttachments.splice(index, 1);
    },
    triggerUpload() {
      this.$refs.upload.$refs.input.click();
    },
    uploadFile(event, fileObject) {
      this.loading = true;
      const formData = new FormData();
      const file = event.raw;
      const env =
        process.env.NODE_ENV === "production" ? "production" : "staging";

      formData.append("file", file);
      formData.append("folder", `crm/${env}/${this.fileFolder}`);
      formData.append("upload_preset", this.cloudinaryPresetUpload);
      formData.append("resource_type", "raw");
      fetch(this.cloudinaryUrl, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.loading = false;
          fileObject.url = data.secure_url;
          this.setAttachments.push(fileObject);
          setTimeout(() => {
            this.loading = false;
            this.$emit("uploaded");
          }, 500);
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.uploading = false;
        });
    },
    onDragEnter(event) {
      event.preventDefault();
    },
    onDragOver(event) {
      event.preventDefault();
    },
    onDragLeave(event) {
      event.preventDefault();
    },
    onDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;

      for (let i = 0; i < files.length; i++) {
        this.reviewAttachments({
          raw: files[i],
          size: files[i].size,
          name: files[i].name,
        });
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.drag-and-drop {
  height: 140px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px dashed #6ece8a;
  border-radius: 8px;
  background: #f8faf9;
  cursor: pointer;
  margin-bottom: 10px;
  .drag-and-drop-icon {
    font-size: 2rem;
    color: #d9d9d9;
    margin-bottom: 15px;
  }

  p {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 10px;
    color: #d9d9d9;
  }

  span {
    font-size: 0.875rem;
    margin-bottom: 10px;
    color: #d9d9d9;
  }
}
el-button {
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--eden-primary);
}

.attachments-upload {
  &--content {
    padding: 12px 15px;
    border: 1px dashed #6ece8a;
    border-radius: 8px;

    .attachment {
      position: relative;
      display: flex;
      align-items: center;
    }

    img {
      height: auto;
      width: 20px;
      border-radius: 4px;
    }

    p {
      font-weight: 500;
      font-size: 0.875rem;
      margin-left: 5px;
    }

    .remove {
      margin-left: 30px;
      transform: translateY(-50%);
      color: var(--eden-red-primary);
      cursor: pointer;
      font-size: 0.875rem;
    }
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
</style>
  