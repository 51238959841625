import * as mutations from "@/store/mutation-types";
import * as actions from "@/store/action-types";
import activities from "@/requests/activities";

export default {
  [actions.GET_ACTIVITIES](context, {period, start_date, end_date, page }) {
    return new Promise((resolve, reject) => {
      activities
        .index(start_date, end_date, page)
        .then(response => {
          if (response.data.status) {
            context.commit(mutations.ACTIVITIES, {
              period,
              page,
              activities: response.data
            });
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
