const Directory = () => import("@/pages/marketplace-logistics/directory/index");
const DirectoryBase = () =>
  import("@/pages/marketplace-logistics/directory/base");
const RiderAdd = () =>
  import("@/pages/marketplace-logistics/directory/rider/add");
const RiderEdit = () =>
  import("@/pages/marketplace-logistics/directory/rider/edit");
const Rider = () =>
  import("@/pages/marketplace-logistics/directory/rider/index");
const CompanyAdd = () =>
  import("@/pages/marketplace-logistics/directory/company/add");
const CompanyEdit = () =>
  import("@/pages/marketplace-logistics/directory/company/edit");
const Company = () =>
  import("@/pages/marketplace-logistics/directory/company/index");
const RiderOrder = () =>
  import("@/pages/marketplace-logistics/directory/rider/orders");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "growth",
  "vendor_manager",
];

const routes = () => {
  return [
    /**
     * Redirect to Directory page if someone tries to
     * access main module route [.../logistics] without
     * specifying a sub-module  [.../logistics/:sub-module]
     */
    {
      path: "/marketplace-logistics",
      redirect: {
        name: "marketplace-logistics.directory.index",
        component: Directory,
      },
    },
    /**
     * Directory Sub-Module
     */
    {
      path: "/marketplace-logistics/directory",
      name: "marketplace-logistics.directory.index",
      component: Directory,
      meta: {
        module: "directory",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/marketplace-logistics/directory/",
      component: DirectoryBase,
      meta: {
        module: "directory",
        permission: authenticatedRoles,
        layout: "app",
      },
      children: [
        {
          path: "riders",
          redirect: {
            name: "marketplace-logistics.directory.index",
            component: Directory,
          },
        },
        {
          path: "add-rider",
          name: "marketplace-logistics.directory.rider-add",
          component: RiderAdd,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "riders/:id",
          name: "marketplace-logistics.directory.rider",
          component: Rider,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "riders/:id/edit",
          name: "marketplace-logistics.directory.rider-edit",
          component: RiderEdit,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "riders/:id/orders/:name",
          name: "marketplace-logistics.directory.rider-orders",
          component: RiderOrder,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "add-company",
          name: "marketplace-logistics.directory.company-add",
          component: CompanyAdd,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "companies/:id",
          name: "marketplace-logistics.directory.company",
          component: Company,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "companies/:id/edit",
          name: "marketplace-logistics.directory.company-edit",
          component: CompanyEdit,
          meta: {
            module: "directory",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
      ],
    },
  ];
};

export default routes();
