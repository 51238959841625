<template>
  <el-drawer
    :title="'Pricing Calculator'"
    :visible.sync="shouldShow"
    direction="rtl"
    @close="closeEvent"
  >
    <div class="el-drawer--content">
      <div class="el-drawer--content__body">
        <el-form :model="form" label-position="top" ref="form">
          <el-row type="flex">
            <el-col :span="24">
              <subscription-configuration
                :config="form.plan"
                @set-config="setConfig"
                :menu-type-variation="{ calculator: null }"
              />
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Total" prop="amount">
                <el-input v-model="form.amount" v-number readonly />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: "EdenPricingCalculator",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        plan: {},
        amount: 0,
      },
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    services() {
      return this.$store.getters.services;
    },
  },
  methods: {
    setConfig({ config, amount }) {
      this.form.plan = config;
      this.form.amount = amount;
    },
    closeEvent() {
      this.shouldShow = false;
      this.form.plan = {};
    },
  },
};
</script>

<style lang="scss" scoped></style>
