<template>
  <!-- <el-drawer
    :class="'notifications'"
    :visible.sync="shouldShow"
    direction="ltr"
  >
    <template slot="title">
      <span class="title">Notifications</span>
    </template>
    <div class="el-drawer--content">
      <div class="el-drawer--content__body">
        <notification-card
          v-for="(notification, index) in notifications"
          :key="index"
          :notification="notification"
          @close="shouldShow = false"
        />
        <notification-center />
      </div>
    </div>
  </el-drawer> -->
  <el-drawer
    :class="'notifications'"
    :title="'Notifications'"
    :visible.sync="shouldShow"
    direction="ltr"
    @close="close"
  >
    <div class="el-drawer--content">
      <div class="el-drawer--content__body">
        <notification-center
          @markRead="markasRead"
          @update-list="updateList"
          :notifications="notificationData.data"
          :total="notificationData.totalCount"
          :showPagination="notificationData.hasMore"
        />
      </div>
    </div>
  </el-drawer>
</template>
<script>
import NotificationCenter from "./NotificationCenter.vue";

// import NotificationCard from "./NotificationCard.vue";
export default {
  components: {
    //  NotificationCard,
    NotificationCenter,
  },
  name: "Notification",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    notificationData: {
      type: Object,
      default: () => ({}),
    },
    loading: Boolean,
  },
  data() {
    return {
      // notifications: [
      //   {
      //     service: "laundry",
      //     description:
      //       "A QC Error has been reported on Adegoke Damola’s laundry order.",
      //     order: 596273,
      //     time: "Today at 11:42 AM",
      //     recent: true,
      //   },
      //   {
      //     service: "meal",
      //     description:
      //       "Confirm that all your customers have picked their meals for next week.",
      //     order: null,
      //     time: "Yesterday at 6:36 PM",
      //     recent: true,
      //   },
      //   {
      //     service: "laundry",
      //     description:
      //       "The QC error on Bolanle Adebola’s laundry order has been resolved.",
      //     order: 596273,
      //     time: "2 days ago",
      //     recent: false,
      //   },
      //   {
      //     service: "cleaning",
      //     description:
      //       "An SP has been assigned to Kofo Okesola’s cleaning order.",
      //     order: 596273,
      //     time: "2 days ago",
      //     recent: false,
      //   },
      // ],
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },

  watch: {
    show() {
      setTimeout(() => {
        document.querySelector(".v-modal").classList.add("hide");
      }, 50);
    },
  },
  methods: {
    close() {
      this.shouldShow = false;
    },
    markasRead(messageIds) {
      this.$emit("markRead", messageIds);
    },
    updateList(page) {
      this.$emit("update-list", page);
    },
  },
};
</script>

<style lang="scss"></style>
