import axios from "axios";

export default {
  list() {
    return axios.get(`menu_types`);
  },

  getMenuVariations() {
    return axios.get("menu_types/variations");
  },
  getCompanies() {
    return axios.get("crm/corporations/lite");
  },
  getMenuTypes() {
    return axios.get("crm/corporate/menu_types");
  },
  create(payload) {
    return axios.post("crm/corporate/menu_types", payload);
  },
  update(id, payload) {
    return axios.put(`crm/corporate/menu_types/${id}`, payload);
  },
  delete(id) {
    return axios.delete(`crm/corporate/menu_types/${id}`);
  },
  price(payload) {
    return axios.post(
      `${process.env.VUE_APP_API_SHORT}plan/generate_amount`,
      payload,
    );
  },
};
