const Vouchers = () => import("@/pages/vouchers/index");
const VoucherDetail = () => import("@/pages/vouchers/voucherDetails");
const DiscountDetail = () => import("@/pages/vouchers/discountDetails");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "growth",
  "vendor_manager",
  "gardener",
  "gardener_pro",
];

const routes = () => {
  return [
    {
      path: "/vouchers-and-discount",
      name: "vouchers.index",
      component: Vouchers,
      meta: {
        module: "vouchers",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/vouchers/:id",
      name: "vouchers.details",
      component: VoucherDetail,
      meta: {
        module: "vouchers",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/discounts/:id",
      name: "discounts.details",
      component: DiscountDetail,
      meta: {
        module: "vouchers",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
