import axios from "axios";

export default {
  list() {
    return axios.get("service_partners/list");
  },

  index(page) {
    return axios.get(`service_partners?page=${page}`);
  },

  search(query) {
    return axios.get(`service_partners/search/${query}`);
  },

  get(id) {
    return axios.get(`service_partners/${id}`);
  },

  schedule(id, start_date, end_date) {
    return axios.get(`service_partners/${id}/schedule?from=${start_date}&to=${end_date}`);
  },

  orderFilter(id, params = "") {
    return axios.get(`service_partners/${id}/schedule${params}`);
  },

  add(payload) {
    return axios.post("service_partner", payload);
  },

  update(id, payload) {
    return axios.put(`service_partners/${id}/profile`, payload);
  },
  updateSpStatus(id, payload) {
    return axios.put(`service_partners/${id}/update_active_status`, payload);
  },
  filter(payload) {
    return axios.post("service_partners/filter", payload);
  },
};
