import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = () => ({
  services: [],
  service: {
    service: null,
    total: null,
    pages: {},
  },
  service_types: {
    beauty: [],
    cleaning: [],
    laundry: [],
    meal: [],
  },
  menus: {
    total: null,
    pages: {},
  },
  serviceOptions: [
    {
      name: "meal",
      thumbnail: "https://crm.edenlife.ng/img/meal.03c0d45a.png",
    },

    {
      name: "laundry",
      thumbnail: "https://res.cloudinary.com/eden-life-inc/image/upload/v1688398111/email-template/dab0f72589a4e4c7e5ee2b81a5c2297f-sticker_1_kxsm3q.png",
    },

    {
      name: "cleaning",
      thumbnail: "https://res.cloudinary.com/eden-life-inc/image/upload/v1688398111/email-template/dab0f72589a4e4c7e5ee2b81a5c2297f-sticker_jmzswq.png",
    },
    {
      name: "beauty",
      thumbnail:
        "https://res.cloudinary.com/eden-life-inc/image/upload/v1688398111/email-template/image_1_lmwsdw.png",

    },
  ],
  meal_item_list: [],
  menu_variations: [],
  menu_types: [],
  ingredients: [],
  food_tags: [],
});

export default {
  state,
  getters,
  actions,
  mutations,
};
