import axios from "axios";

export default {
  index(page) {
    return axios.get(`crm/cleaner?page=${page}`);
  },
  list(){
    return axios.get(`crm/cleaner`);
  },
  search(query) {
    return axios.get(`crm/cleaner?name=${query}`);
  },

  add(payload) {
    return axios.post("crm/cleaner", payload);
  },

  get(id) {
    return axios.get(`crm/cleaner?cleaner_id=${id}`);
  },

  update(payload) {
    return axios.post("crm/cleaner/update", payload);
  },

  delete(payload) {
    return axios.post("crm/cleaner/delete", payload);
  },
};
