const LOCATION = localStorage.getItem("eden-crm-location");

export default {
  methods: {
    formatPdfPrice(value) {
      const valueToInt = parseInt(value);
      return valueToInt ? `N${Number(valueToInt).toLocaleString()}.00` : "₦0";
    },
    formatPrice(value) {
      const valueToInt = parseInt(value);
      const valuePrefix = LOCATION === "NG" ? "₦" : "KES";
      const valueToString = valueToInt
        ? `${Number(valueToInt).toLocaleString()}.00`
        : "0";
      return `${valuePrefix}${valueToString}`;
    },
    formatExportPrice(value) {
      const valueToInt = parseInt(value);
      const valuePrefix = LOCATION === "NG" ? "#" : "KES";
      const valueToString = valueToInt
        ? `${Number(valueToInt).toLocaleString()}.00`
        : "0";
      return `${valuePrefix}${valueToString}`;
    },
    formatFigure(value) {
      const valueToInt = parseInt(value);
      return valueToInt >= 0 ? Number(valueToInt).toLocaleString() : "-";
    },
    formatPercentage(value) {
      const valueToInt = parseInt(value);
      return valueToInt >= 0 ? `${Number(valueToInt)}%` : "-";
    },
    formatName(name) {
      if (name) {
        const names = name.split(" ");
        let newName = [];
        for (let i = 0; i < names.length; i++) {
          newName.push(
            names[i].charAt(0).toUpperCase() + names[i].substr(1).toLowerCase(),
          );
        }
        return newName.join(" ");
      }
      return "-";
    },
    formatToTitleCase(sentence) {
      if (sentence) {
        const words = sentence.includes("-")
          ? sentence.split("-")
          : sentence.split(" ");
        let newSentence = [];
        for (let i = 0; i < words.length; i++) {
          newSentence.push(
            words[i].charAt(0).toUpperCase() + words[i].substr(1).toLowerCase(),
          );
        }
        return newSentence.join(" ");
      }
      return "-";
    },
    formatText(text) {
      return text
        ? String(text).trim().charAt(0).toUpperCase() +
            String(text).trim().substr(1).toLowerCase()
        : "-";
    },
    formatWithoutSlug(text) {
      this.formatText(text?.replace("-", " ") || "")?.replace("-", " ");
    },
    formatArrayToText(data) {
      return data
        .map((item) => this.formatText(item))
        .toString()
        .split(",")
        .join(", ");
    },
    formatPhoneNumber(number) {
      if (!number) {
        return "-";
      }
      if (number.includes("+234") || number.includes("+254")) {
        return number;
      }
      let numberAsString = `${number}`;
      let code = LOCATION === "KE" ? "254" : "234";
      numberAsString =
        numberAsString.charAt(0) === "2"
          ? numberAsString
          : `${code}${numberAsString}`;
      return LOCATION === "KE"
        ? numberAsString.replace(/(\d{3})(\d{3})(\d{6})/, "+$1 $2 $3")
        : numberAsString.replace(
            /(\d{3})(\d{3})(\d{3})(\d{4})/,
            "+$1 $2 $3 $4",
          );
    },
    formatCurrency() {
      return LOCATION === "NG" ? "₦" : "KES";
    },
    formatDebitCard(bin, last4) {
      return `${bin.toString().substring(0, 4)} ${bin
        .toString()
        .substring(4, 7)}XX XXXX ${last4}`;
    },
    formatPersona(persona) {
      const customerPersona = persona ? persona.toLowerCase() : null;
      switch (customerPersona) {
        case "regular":
          return "Regular";
        case "humaan":
          return "Eden Humaan";
        case "careclub":
          return "Care Club Member";
        default:
          return "-";
      }
    },
    formatPaymentGateway(gateway) {
      switch (gateway) {
        case "paystack":
          return "Paystack";
        case "flutterwave":
          return "Flutterwave";
        case "direct_bank_transfer":
          return "Bank Transfer";
        default:
          return "-";
      }
    },
    formatSlug(text) {
      if (text.toLowerCase().includes("standard")) {
        return "light-cleaning";
      }
      let slug = "";
      slug = text.toLowerCase();
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "");
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-");
      // Change forward slash to "-"
      slug = slug.replace("/", "-");

      return slug;
    },
    unformatSlug(text) {
      return this.formatToTitleCase(text);
    },
    sortList(data, property, direction = "asc") {
      let sortedData = property
        ? data.sort((a, b) => (a[property] < b[property] ? -1 : 1))
        : data.sort((a, b) => (a < b ? -1 : 1));
      return direction === "asc" ? sortedData : sortedData.reverse();
    },
    formatInteger(value){
      if (value && typeof(value) !== "number"){
        const numbers = parseInt(value.replace(/[^0-9]/g, ""));
        return numbers;
      }else if(value>0){
        return value
      }else{
        return value*-1
      }
    }
  },
};
