const validateEdenMail = (rule, value, callback) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!value) {
    callback(new Error("This field is required"));
  } else if (emailRegex.test(value) === false) {
    callback(new Error("Email address is invalid"));
  } else if (value.indexOf("@edenlife.ng") === -1) {
    callback(new Error("Enter a valid Eden email"));
  } else {
    callback();
  }
};

const validatePhoneNumber = (rule, value, callback) => {
  const COUNTRY = localStorage.getItem("eden-crm-location");

  // NUMBER COUNT WITHOUT THE COUNTRY CODE CHARACTERS
  const numberCount = COUNTRY === "KE" ? 9 : 10;

  if (!value) {
    callback(new Error("Phone number is required"));
    // THE 3 HERE REPRESENTS THE COUNTRY CODE CHARACTERS
  } else if (value.length !== numberCount + 3) {
    console.log(value, value.length);
    callback(new Error(`Phone number has to be ${numberCount} digits.`));
  } else {
    callback();
  }
};

const validateFigure = (rule, value, callback) => {
  if (!value) {
    callback(new Error("This field is required"));
  } else {
    callback();
  }
};

const validateMealName = (rule, value, callback) => {
  if (!value) {
    callback(new Error("This field is required"));
  } else if (value.includes(",")) {
    callback(new Error("Meal name cannot contain a comma ','"));
  } else if (value.includes("/")) {
    callback(new Error("Meal name cannot contain a slash '/'"));
  } else {
    callback();
  }
};

const validateName = (rule, value, callback) => {
  if (!value) {
    callback(new Error("This field is required"));
  } else if (value.toLowerCase().includes("undefined")) {
    callback(new Error("Name cannot include 'undefined'"));
  } else if (value.toLowerCase() === "const") {
    callback(new Error("Name cannot include 'const'"));
  } else if (value.toLowerCase().includes("null")) {
    callback(new Error("Name cannot include 'null'"));
  } else {
    callback();
  }
};

export default {
  methods: {
    validateField(field) {
      return [
        {
          required: true,
          message: `${field ? field : "This field"} is required`,
          trigger: "blur",
        },
      ];
    },
    validateEmail(field) {
      return [
        {
          required: true,
          message: `${field ? field : "This field"} is required`,
          trigger: ["change"],
        },
        {
          type: "email",
          message: "Email address is not valid",
          trigger: ["blur"],
        },
      ];
    },
    validatePhone() {
      return [{ validator: validatePhoneNumber, trigger: "blur" }];
    },
    edenEmail() {
      return [{ validator: validateEdenMail, trigger: "blur" }];
    },
    validateName() {
      return [{ validator: validateName, trigger: "blur" }];
    },
    validateFigure() {
      return [{ validator: validateFigure, trigger: "blur" }];
    },
    validateMealName() {
      return [{ validator: validateMealName, trigger: "blur" }];
    },
  },
};
