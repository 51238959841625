import axios from "axios";

export default {
  list() {
    return axios.get("service_type/ingredients/all");
  },

  add(payload) {
    return axios.post("service_type/ingredients", payload);
  },

  update(id, payload) {
    return axios.put(`service_type/ingredients/${id}`, payload);
  },

  delete(payload) {
    return axios.post("service_type/expunge/ingredients", payload);
  },

  measurementList() {
    return axios.get("service_type/measurements/all");
  },

  measurementAdd(payload) {
    return axios.post("service_type/measurements", payload);
  },
};
