import axios from "axios";

export default {
  get({ service, start_date, end_date, menu_type, customer_type }) {
    let params = `start_date=${start_date}&end_date=${end_date}`;

    return axios.get(
      `service_partners/${service}/provisioned_orders/lite?${params}${
        menu_type ? `&menu_type=${menu_type}` : ""
      }${customer_type ? `&customer_type=${customer_type}` : ""}`,
    );
  },

  confirm(payload) {
    return axios.post("customer_order/update_confirmed_status", payload);
  },
};
