import * as types from "../../mutation-types";

export default {
  [types.SERVICES](state, services) {
    const enabled = ["beauty", "cleaning", "laundry", "meal"];
    state.services = services
      .filter((service) => enabled.includes(service.service_name.toLowerCase()))
      .sort((a, b) =>
        a.service_name.toLowerCase() < b.service_name.toLowerCase() ? -1 : 1,
      )
      .map((service) => {
        return {
          ...service,
          slug: service.service_name.toLowerCase(),
        };
      });
  },

  [types.SERVICE](state, { service, page, data, clear }) {
    if (clear) {
      state.service = {
        service: null,
        total: null,
        pages: {},
      };
      return;
    }
    /**
     * Reset state if [service] changes;
     */
    if (state.service.service !== service) {
      state.service = {
        service,
        total: data.total,
        pages: {},
      };
    }

    /**
     * Set [page] data;
     */
    state.service.pages[page] = {
      from: data.from,
      to: data.to,
      data: data.data,
    };
  },
  [types.MEAL_ITEM_LIST](state, data) {
    state.meal_item_list = data;
  },
  [types.SERVICE_TYPES](state, { service, data }) {
    state.service_types[service] = data.map((type) => {
      const name = type.name || type.service_type;
      return {
        ...type,
        name,
        slug: name.toLowerCase().replaceAll(" ", "-"),
      };
    });
  },

  [types.RESET_STATE](state, defaultState) {
    Object.assign(state, defaultState.services);
  },

  [types.MENUS](state, { page, data }) {
    state.menus.total = data.meta.total;
    state.menus.pages[page] = {
      from: data.meta.from,
      to: data.meta.to,
      data: data.data,
    };
  },

  [types.MENU_VARIATIONS](state, menu_variations) {
    state.menu_variations = menu_variations;
  },

  [types.INGREDIENTS](state, data) {
    state.ingredients = data;
  },

  [types.FOOD_TAGS](state, data) {
    state.food_tags = data;
  },

  [types.MENU_TYPES](state, data) {
    state.menu_types = data;
  },
};
