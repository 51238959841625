import axios from "axios";

export default {
  list() {
    return axios.get("crm/menu/food_tag");
  },

  add(payload) {
    return axios.post("crm/menu/food_tag", payload);
  },

  update(payload) {
    return axios.post("crm/menu/food_tag/update", payload);
  },

  delete(payload) {
    return axios.delete("crm/menu/food_tag", { data: payload });
  },
};
