<template>
  <div>
    <eden-custom-period-preview
      v-if="form.start_date"
      :daterange="{
        from: form.start_date,
        to: form.end_date,
      }"
      @change="showCustomPeriod = true"
      @clear="clear"
    />
    <el-button v-else type="plain" @click="showCustomPeriod = true">
      Filter by date
      <span v-if="form.start_date"
        >: {{ formatDate(form.start_date, "do m, y") }} -
        {{ formatDate(form.end_date, "do m, y") }}
      </span>
      <i class="el-icon-arrow-down"></i>
    </el-button>
    <eden-custom-period
      :show.sync="showCustomPeriod"
      :title="'Date Filter'"
      :button="'Filter'"
      @confirm="confirm"
    />
  </div>
</template>
<script>
export default {
  name: "EdenPeriodFilter",
  data() {
    return {
      showCustomPeriod: false,
      form: {
        start_date: null,
        end_date: null,
      },
    };
  },
  methods: {
    clear() {
      this.$emit("clear");
      this.form.start_date = null;
      this.form.end_date = null;
    },
    confirm({ from, to }) {
      this.form.start_date = from;
      this.form.end_date = to;
      this.$emit("confirm", { from, to });
    },
  },
};
</script>
