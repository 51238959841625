import axios from "axios";

export default {
  index( page ) {
    return axios.get(`crm/customer/business?page=${page}`);
  },

  get( id ) {
    return axios.get(`crm/customer/business/${id}`);
  },

  update( id, payload ) {
    return axios.patch(`crm/customer/business/${id}`, payload);
  },

  search(search) {
    return axios.get(`crm/customer/business?search=${search}`);
  },

  filter(params) {
    return axios.get(`crm/gardener/bulk/message?${params}`);
  },

  addEmployeesSingle({id, payload}) {
    return axios.post(`crm/customer/business/subscriptions/${id}/employees`, payload);
  },

  generatesubscription({id, payload}) {
    return axios.post(`crm/customer/business/${id}/subscriptions/generate`, payload);
  },

  addEmployeesBulk({id, payload}) {
    return axios.post(`crm/customer/business/subscriptions/${id}/employees/bulk`, payload);
  },

  createAccount( payload) {
    return axios.post(`crm/customer/business`, payload);
  },
  
  getCountries() {
    return axios.get(`${process.env.VUE_APP_API_SHORT}business/countries`);
  },

  getEmployees(id, page) {
    return axios.get(`crm/customer/business/${id}/employees?page=${page}`);
  },

  searchEmployyee(id, search) {
    return axios.get(`crm/customer/business/${id}/employees?search=${search}`);
  },

  getLocationAreas(country_code) {
    return axios.get(`${process.env.VUE_APP_API_SHORT}business/location_areas`, {
      headers:{
        'X-Eden-Location': country_code
      }
    });
  },

   getStates(id) {
    return axios.get(`${process.env.VUE_APP_API_SHORT}business/countries/${id}/states`);
  },

  message(start, id) {
    return axios.get(
      `crm/gardener/bulk/message_with_recipients?start_date=${start}&bulk_message_id=${id}`
    );
  },

  getUploadReceiptPayments(id){
    return axios.get(
      `crm/customer/business/${id}/payment_receipts`
    );
  },

  confirmReceiptPayments({sub_id, receipt_id}){
    return axios.patch(
      `crm/customer/business/${sub_id}/payment_receipts/${receipt_id}/confirm`
    );
  }
};
