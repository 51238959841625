import Vue from "vue";
import App from "./App.vue";

// Element UI
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import "element-ui/lib/theme-chalk/display.css";

// Styling
import "@/assets/css/eden-main.scss";

// plugins
import "@/plugins/vue-lazy-load";

// Layout & Global Component
import "@/layouts/layout-config.js";
import "@/components/Global/global-config.js";
import "@/components/Subscriptions/Subscription/Configuration/subscription-configuration";

// Mixins et Directives
import "@/mixins";
import "@/directives";

// Router
import router from "./router";

// Store
import store from "./store";

// Middleware
import "./middleware";

// Tracking
import mixpanel from "@/events/mixpanel";
import Hotjar from "vue-hotjar";

//novu notification
// import NotificationCenterPlugin from "@novu/notification-center-vue";
// import "@novu/notification-center-vue/dist/style.css";
// import { NotificationCenterWebComponent } from "@novu/notification-center";

// customElements.define(
//   "notification-center-component",
//   NotificationCenterWebComponent,
// );

ElementUI.Dropdown.methods.hide = function hide() {
  var _this2 = this;

  if (!this.triggerElm || this.triggerElm.disabled) return;
  this.removeTabindex();
  if (this.tabindex >= 0) {
    this.resetTabindex(this.triggerElm);
  }
  clearTimeout(this.timeout);
  this.timeout = setTimeout(
    function () {
      _this2.visible = false;
    },
    this.trigger === "click" ? 0 : this.hideTimeout,
  );
};

const ifInProduction = process.env.NODE_ENV === "production";
// const ifInProduction = process.env.NODE_ENV === "production" || true;

Vue.config.productionTip = ifInProduction;
Vue.config.devtools = !ifInProduction;

Vue.use(ElementUI, { locale });
// Vue.use(NotificationCenterPlugin);
Vue.use(Hotjar, {
  id: "2837993",
  isProduction: ifInProduction,
  snippetVersion: 6,
});
Vue.mixin(mixpanel);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
