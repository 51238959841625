const Gardeners = () => import("@/pages/gardeners/index");
const Gardener = () => import("@/pages/gardeners/gardener");
const GardenerAdd = () => import("@/pages/gardeners/add");
const GardenerEdit = () => import("@/pages/gardeners/edit");
const PaymentTracker = () => import("@/pages/gardeners/payment/tracker");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "growth",
  "gardener",
  "gardener_pro",
];
const authenticatedRolesTracker = [
  "superadmin",
  "gardener",
  "gardener_pro",
  "admin",
];

const MODULE = "gardeners";

const routes = () => {
  return [
    {
      path: "/gardeners",
      name: "gardeners.index",
      component: Gardeners,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/gardeners/add",
      name: "gardeners.gardener-add",
      component: GardenerAdd,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/gardeners/:id?/payment-tracker",
      name: "gardeners.payment-tracker",
      component: PaymentTracker,
      meta: {
        module: MODULE,
        permission: authenticatedRolesTracker,
        layout: "app",
      },
    },
    {
      path: "/gardeners/:id",
      name: "gardeners.gardener",
      component: Gardener,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/gardeners/:id/edit",
      name: "gardeners.gardener-edit",
      component: GardenerEdit,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
