import * as types from "../../mutation-types";

export default {
  [types.LOGISTICS_DIRECTORY](state, { directory, page, data }) {
    state.directory[directory].total = data.meta.total;
    state.directory[directory].pages = {
      [page]: {
        from: data.meta.from ?? 0,
        to: data.meta.to ?? 0,
        data: data.data,
      },
    };
  },

  [types.LOGISTICS_SCHEDULE](state, { service, page, data }) {
    if (!state.schedule[service]) {
      state.schedule[service] = {};
    }
    state.schedule[service].total = data.total;
    state.schedule[service].pages = {
      [page]: {
        from: data.from,
        to: data.to,
        data: data.data,
      },
    };
  },

  [types.LOGISTICS_SCHEDULE_ORDERS](state, { service, page, data }) {
    if (!state.schedule_orders[service]) {
      state.schedule_orders[service] = {};
    }
    state.schedule_orders[service].total = data.total;
    state.schedule_orders[service].pages = {
      [page]: {
        from: data.from,
        to: data.to,
        data: data.data,
      },
    };
  },

  [types.RESET_STATE](state, defaultState) {
    Object.assign(state, { ...defaultState.operations });
  },
};
