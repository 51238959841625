<template>
  <div class="page--header is-flex flex-wrap justify-between">
    <div
      v-if="section && subtitle && !subsection"
      class="body-text is-flex flex-wrap page--subtitled"
    >
      <p class="font-base subtitle" @click="goBackwards(-2)">{{ title }}</p>

      <i class="el-icon-arrow-right separator"></i>

      <p class="font-base subtitle" @click="goBackwards(-1)">
        {{ subtitle }}
      </p>

      <i class="el-icon-arrow-right separator"></i>
      <slot name="section">
        <p class="font-base page--title">{{ section }}</p>
      </slot>
    </div>

    <div v-else-if="section && subtitle && subsection" class="body-text is-flex flex-wrap page--subtitled">
      <p class="font-base subtitle" @click="goBackwards(-3)">
        {{ title }}
      </p>
        <i class="el-icon-arrow-right separator"></i>

      <p class="font-base subtitle text-capitalize" @click="goBackwards(-2)">
        {{ subtitle }}
      </p>

      <i class="el-icon-arrow-right separator"></i>

      <p class="font-base subtitle" @click="goBackwards(-1)">
        {{ section }}
      </p>

      <i class="el-icon-arrow-right separator"></i>

      <p class="font-base page--title">{{ subsection }}</p>
    </div>

    <div
      v-else-if="subtitle && !section"
      class="body-text is-flex flex-wrap page--subtitled"
    >
      <p class="font-base subtitle" @click="goBackwards(-1)">{{ title }}</p>

      <i class="el-icon-arrow-right separator"></i>

      <p class="font-base page--title">{{ subtitle }}</p>
    </div>
    <p v-else class="head-text page--title font-lg">{{ title }}</p>

    <div class="page--head-actions is-flex flex-wrap align-center">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  name: "EdenPageHeader",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    section: {
      type: String,
      default: "",
    },
    subsection: {
      type: String,
      default: "",
    },
  },

  methods: {
    goBackwards(index) {
      this.$router.go(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  &--header {
    align-items: center;
    margin-bottom: 50px;
  }

  &--title {
    color: var(--eden-grey-primary);
    font-weight: 600;
  }

  &--subtitled {
    align-items: center;
    color: #4b6358;
    .subtitle {
      font-weight: 400;
      color: #4b6358;
      cursor: pointer;
    }

    .page--title {
      font-weight: 500;
    }

    .separator {
      font-size: 14px;
      font-weight: 600;
      margin: 0 15px;
    }

    &.bell-icon {
      font-size: 1.5rem;
    }
  }

  &--head-actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .el-dropdown,
    .el-button {
      margin: 0 10px;
    }
  }
}
</style>
