<template>
  <el-row type="flex" :gutter="60" class="eden-form-section flex-wrap">
    <el-col v-if="title" :md="9">
      <p class="eden-form-section__title">{{ title }}</p>
      <span class="eden-form-section__subtitle">{{ subtitle }}</span>
    </el-col>
    <el-col :offset="title ? 0 : 9" :md="15">
      <slot name="form-fields" />
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "EdenFormSection",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.eden-form-section {
  margin-bottom: 60px;

  &__title {
    font-size: 1rem;
    font-weight: 500;
    color: var(--eden-grey-primary);
    margin-bottom: 5px;
  }

  &__subtitle {
    font-size: 0.825rem;
    color: var(--eden-grey-tertiary);
  }
}

@media (max-width: 1280px) {
  .eden-form-section {
    margin-bottom: 30px;
  }
}

@media (max-width: 400px) {
  .eden-form-section {
    .el-col-offset-10 {
      margin-left: 0 !important;
    }
  }
}
</style>
