<template>
  <div class="image" :style="imageStyle">
    <img
      v-if="!imageUrl"
      :src="getImage('default/image-upload-icon.svg')"
      :alt="'Image'"
      :class="'default'"
    />
    <img
      v-else
      :src="imageUrl"
      :alt="'Image'"
      :style="{ borderRadius: types[imageType] }"
    />
  </div>
</template>

<script>
export default {
  name: "EdenImage",
  props: {
    imageUrl: {
      type: String,
      default: "",
    },
    imageSize: {
      type: Number,
      default: 80,
    },
    imageType: {
      type: String,
      default: "rounded",
    },
    borderStyles: {
      type: String,
      default: "none",
    },
  },
  data() {
    return {
      types: {
        square: "0px",
        rounded: "12px",
        circle: "1000px",
      },
    };
  },
  computed: {
    imageStyle() {
      return {
        height: `${this.imageSize}px`,
        width: `${this.imageSize}px`,
        borderRadius: this.types[this.imageType],
        border: this.imageUrl ? this.borderStyles : "none",
      };
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.image {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--eden-green-septenary);

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;

    &.default {
      height: auto;
      width: 60%;
      margin: 0 auto;
    }

    &:not(.default) {
      border-radius: 12px;
    }
  }
}
</style>
