import axios from "axios";

export default {
  list() {
    return axios.get("clusters");
  },

  index(page) {
    return axios.get(`clusters/all?page=${page}`);
  },

  search(query) {
    return axios.get(`clusters/all/search/${query}`);
  },

  filter(payload) {
    return axios.post("clusters/all/filter", payload);
  },

  add(payload) {
    return axios.post("clusters", payload);
  },

  update(payload) {
    return axios.put("clusters", payload);
  },

  delete(id) {
    return axios.delete("clusters", { data: { cluster_id: id } });
  },
};
