import * as types from "../../mutation-types";

export default {
  [types.DISCOUNTS](state, { page, data }) {
    state.discounts.total = data.total;
    state.discounts.pages[page] = {
      from: data.from,
      to: data.to,
      data: data.data,
    };
  },

  [types.RESET_STATE](state, defaultState) {
    Object.assign(state, { ...defaultState.discounts });
  },
};
