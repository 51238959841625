import * as mutations from "../../mutation-types";
import * as actions from "../../action-types";

import orders from "@/requests/orders/index";
import schedule from "@/requests/orders/schedule";

export default {
  [actions.GET_ORDERS](context, { period, page }) {
    return new Promise((resolve, reject) => {
      orders
        .index(period, page)
        .then((response) => {
          if (response.data.status) {
            context.commit(
              mutations.ORDERS_SUMMARY,
              response.data.data.summary,
            );
            context.commit(mutations.ORDERS, {
              period,
              page,
              data: response.data.data.orders,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_ORDERS_SCHEDULE](context, payload) {
    return new Promise((resolve, reject) => {
      schedule
        .index(payload)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.ORDERS_SCHEDULE, {
              service: payload.service,
              sp: payload.servicePartnerId,
              start_date: payload.start_date,
              end_date: payload.end_date,
              page: payload.page,
              data: response.data.data ? response.data.data : {},
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
