<template>
  <el-select
    v-model="setGardener"
    filterable
    :placeholder="placeholder"
    :value-key="'id'"
  >
    <el-option
      v-for="gardener in gardeners"
      :key="gardener.id"
      :label="formatName(gardener.name)"
      :value="gardener"
    />
  </el-select>
</template>

<script>
import * as actions from "@/store/action-types";

export default {
  name: "EdenGardenerSelect",
  props: {
    placeholder: {
      type: String,
      default: "Select team member(s)",
    },
    gardener: {
      type: [String, Number, Object],
      default: "Select gardener",
    },
    itemKey: {
      type: String,
      default: "id",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    setGardener: {
      get() {
        return this.gardener;
      },
      set(value) {
        this.$emit(
          "update:gardener",
          this.returnObject ? value : value[this.itemKey],
        );
      },
    },
    gardeners() {
      return this.$store.getters.gardeners_list;
    },
  },
  created() {
    if (!this.gardeners.length) {
      this.$store.dispatch(actions.GET_GARDENERS_LIST);
    }
  },
};
</script>
