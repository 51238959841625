const all = ["superadmin", "admin", "operations", "growth"];

const stewards = ["steward", "ofa"];

export const navRoutes = () => {
  return [
    {
      icon: "orders",
      label: "Activities",
      name: "activities",
      access: ["superadmin", "admin", "operations"],
      active: true,
    },
    {
      icon: "dashboard",
      label: "Dashboard",
      name: "dashboard",
      access: all.concat(["gardener", "gardener_pro"]),
      active: true,
    },
    {
      icon: "dashboard",
      label: "Garden Feature Tracking",
      name: "feature-tracker",
      access: ["superadmin", "admin", "growth"],
      active: true,
    },
    // {
    //   icon: "dashboard",
    //   label: "Electronic Dance Festival",
    //   name: "dance-festival",
    //   access: all.concat(["kitchen", "gardener", ...stewards]),
    //   active: true,
    //   sub_routes: [
    //     {
    //       label: "Menu",
    //       name: "dance-festival.df-menu",
    //       access: all.concat(["kitchen", "gardener"]),
    //       active: true,
    //     },
    //     {
    //       label: "Orders",
    //       name: "dance-festival.df-orders",
    //       access: all.concat(["kitchen", "gardener", ...stewards]),
    //       active: true,
    //     },
    //     {
    //       label: "Meal Items",
    //       name: "dance-festival.df-meal-items",
    //       access: all.concat(["kitchen", "gardener", ...stewards]),
    //       active: true,
    //     },
    //   ],
    // },
    {
      icon: "feedback",
      label: "Feedback & Issues",
      name: "feedback",
      access: all.concat(["kitchen", "gardener", "gardener_pro", ...stewards]),
      active: true,
      sub_routes: [
        {
          label: "Feedback",
          name: "feedback.feedback",
          access: all.concat(["kitchen", "gardener", "gardener_pro"]),
          active: true,
        },
        {
          label: "Issues Tracker",
          name: "feedback.issues",
          access: all.concat([
            "kitchen",
            "gardener",
            "gardener_pro",
            ...stewards,
          ]),
          active: true,
        },
        {
          label: "Suggestions Log",
          name: "feedback.suggestions",
          access: all.concat([
            "kitchen",
            "gardener",
            "gardener_pro",
            ...stewards,
          ]),
          active: true,
        },
      ],
    },
    {
      icon: "gardener",
      label: "Gardeners",
      name: "gardeners",
      access: all.concat(["gardener", "gardener_pro"]),
      active: true,
    },
    {
      icon: "customers",
      label: "Customers",
      name: "customers",
      access: all.concat(["kitchen", "gardener", "gardener_pro"]),
      active: true,
    },

    {
      icon: "customers",
      label: "Eden for Business",
      name: "business",
      access: all.concat(["kitchen", "gardener", "gardener_pro"]),
      active: true,
    },
    {
      icon: "orders",
      label: "Orders & Schedule",
      name: "orders",
      access: all.concat(["kitchen", "gardener", "gardener_pro"]),
      active: true,
    },
    {
      icon: "orders",
      label: "Micro Fulfillment Center",
      name: "fulfillment",
      access: all.concat(["kitchen", "gardener", "stewards", "gardener_pro"]),
      active: true,
    },
    {
      icon: "zones",
      label: "Zones & Location areas",
      name: "zones",
      access: all.concat(["gardener", "gardener_pro"]),
      active: true,
    },
    {
      icon: "services",
      label: "Services",
      name: "services",
      access: all.concat(["kitchen", "gardener", "gardener_pro"]),
      active: true,
    },
    {
      icon: "operations",
      label: "Logistics",
      name: "logistics",
      access: ["superadmin", "admin", "operations"],
      active: true,
      sub_routes: [
        {
          label: "Directory",
          name: "logistics.directory",
          access: all,
          active: true,
        },
        {
          label: "Schedule",
          name: "logistics.schedule",
          access: ["admin"],
          active: true,
        },
      ],
    },
    {
      icon: "service-partners",
      label: "Service Operations",
      name: "serviceoperations",
      access: all.concat(["kitchen", "gardener", "gardener_pro"]),
      active: true,
      sub_routes: [
        {
          label: "Service Partners",
          name: "serviceoperations.partners",
          access: all.concat(["kitchen", "gardener", "gardener_pro"]),
          active: true,
        },
        {
          label: "Orders",
          name: "serviceoperations.serviceoperations-orders",
          access: all.concat(["kitchen", "gardener", "gardener_pro"]),
          active: true,
        },
      ],
    },
    {
      icon: "subscriptions",
      label: "Subscriptions & Payment",
      name: "subscriptions",
      access: all.concat(["gardener", "gardener_pro"]),
      active: true,
    },
    {
      icon: "discounts",
      label: "Discounts & Rewards",
      name: "discounts",
      access: all.concat(["gardener", "gardener_pro"]),
      active: true,
    },
    {
      icon: "communication",
      label: "Communications",
      name: "communications",
      access: all.concat(["gardener", "gardener_pro"]),
      active: true,
      sub_routes: [
        {
          label: "Notifications",
          name: "communications.notifications",
          access: all,
          active: true,
        },
        {
          label: "Bulk Messaging",
          name: "communications.bulk-messages",
          access: all.concat(["gardener", "gardener_pro"]),
          active: true,
        },
      ],
    },
    {
      icon: "kitchen",
      label: "Production Provisions",
      name: "production-provisions",
      access: stewards.concat(["superadmin", "admin"]),
      active: true,
    },
    {
      icon: "combo",
      label: "Combo Check",
      name: "combo-check",
      access: stewards.concat(["superadmin", "admin"]),
      active: true,
    },
    {
      icon: "order-fulfillment",
      label: "Order Fulfillment",
      name: "order-fulfillment",
      access: stewards.concat(["superadmin", "admin"]),
      active: true,
    },
    {
      icon: "settings",
      label: "Settings",
      name: "settings",
      access: all.concat(["kitchen", "gardener", "gardener_pro"]),
      active: true,
    },
  ];
};
