import * as types from "../../mutation-types";

export default {
  [types.CUSTOMERS_SUMMARY](state, customers_summary) {
    state.corporate_account_summary = customers_summary;
  },

  [types.CORPORATE_ACCOUNTS](state, { page, data }) {
    state.corporate_accounts.total = data.total;
    state.corporate_accounts.pages[page] = {};
    state.corporate_accounts.pages[page].from = data.from;
    state.corporate_accounts.pages[page].to = data.to;
    state.corporate_accounts.pages[page].data = data.data;
    state.corporate_accounts.per_page = data.per_page;
  },

  [types.SAVE_EMPLOYEES](state, { page, data }) {
    state.employees.total = data.total;
    state.employees.pages[page] = {};
    state.employees.pages[page].from = data.from;
    state.employees.pages[page].to = data.to;
    state.employees.pages[page].data = data.data;
    state.employees.per_page = data.per_page;
  },
  [types.SAVE_UPLOAD_RECEIPTS](state, { page, data }) {
    state.receipts.total = data.total;
    state.receipts.pages[page] = {};
    state.receipts.pages[page].from = data.from;
    state.receipts.pages[page].to = data.to;
    state.receipts.pages[page].data = data.data;
    state.receipts.per_page = data.per_page;
  },


  [types.SAVE_SUBSCRIPTION](state, subscriptions) {
    state.company_subscriptions = subscriptions;
  },

  [types.RESET_STATE](state, defaultState) {
    Object.assign(state, { ...defaultState.customers });
  },
};
