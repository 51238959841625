import Vue from "vue";

// Input Field Allow Only Number
const onlyNumber = (el) => {
  const input = el.children[0];
  input.addEventListener("keypress", (e) => {
    if (e.keyCode === 46 || e.keyCode === 8) {
      return false;
    } else if (e.keyCode < 48 || e.keyCode > 57) {
      e.preventDefault();
    }
  });
};

const decimal = (el) => {
  const input = el.children[0];
  input.addEventListener("keypress", (e) => {
    if (e.keyCode === 8) {
      return false;
    } else if (
      (e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode !== 46 || input.value.indexOf(".") !== -1)
    ) {
      e.preventDefault();
    }
  });
};

Vue.directive("number", {
  inserted: (el) => {
    onlyNumber(el);
  },
  bind: (el) => {
    onlyNumber(el);
  },
  update: (el) => {
    onlyNumber(el);
  },
});

Vue.directive("decimal", {
  bind: (el) => {
    decimal(el);
  },
  update: (el) => {
    decimal(el);
  },
});
