<template>
  <div class="service-configuration meal">
    <div class="title">
      <p>Food Configuration</p>
    </div>
    <el-form :model="form" label-position="top" ref="form">
      <template v-if="!oneTime">
        <el-form-item
          label="Select Menu Type Variation"
          prop="menu_type_variation_id"
          :rules="validateField()"
        >
          <el-select
            v-model="form.menu_type_variation_id"
            filterable
            placeholder="Select menu type"
          >
            <el-option
              v-for="type in filteredMenuTypes"
              :key="type.id"
              :label="type.name"
              :value="type.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="Select customer's delivery days"
          prop="service_day"
          :rules="validateField()"
        >
          <el-checkbox-group
            v-model="form.service_day"
            class="eden-custom--checkbox flex"
            :min="1"
            @change="sortServiceDays"
          >
            <el-checkbox
              v-for="(value, key, index) in serviceDays()"
              :key="index"
              :label="key"
              @change="updateConfig($event, key)"
              >{{ value }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <template>
          <el-form-item
            label="Do they want to receive same number of meals per delivery?"
            prop="service_day_quantity.same"
            :rules="validateField()"
          >
            <el-radio-group
              v-model="form.service_day_quantity.same"
              :disabled="form.service_day.length === 1"
              @change="updateSameMealNumberValue"
            >
              <el-radio :label="true">Yes</el-radio>
              <el-radio :label="false">No</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="form.service_day_quantity.same"
            label="Number of meals per delivery"
          >
            <div class="counter">
              <el-button
                :type="'control'"
                icon="el-icon-minus"
                :disabled="form.service_day_quantity.value === 1"
                @click="updateSameMealNumber('subtract')"
              />
              <el-input
                v-model.number="form.service_day_quantity.value"
                v-number
                type="text"
                readonly
              />
              <el-button
                :type="'control'"
                icon="el-icon-plus"
                :disabled="form.service_day_quantity.value === 20"
                @click="updateSameMealNumber('add')"
              />
            </div>
          </el-form-item>
          <template v-else>
            <el-form-item
              v-for="day in form.service_day"
              :key="day"
              :label="`Number of meals on ${formatText(day)}`"
            >
              <div class="counter">
                <el-button
                  :type="'control'"
                  icon="el-icon-minus"
                  :disabled="form.service_day_quantity[day] === 1"
                  @click="updateMealNumber(day, 'subtract')"
                />
                <el-input
                  v-model="form.service_day_quantity[day]"
                  v-number
                  type="text"
                  readonly
                />
                <el-button
                  :type="'control'"
                  icon="el-icon-plus"
                  :disabled="form.service_day_quantity[day] === 20"
                  @click="updateMealNumber(day, 'add')"
                />
              </div>
            </el-form-item>
          </template>
        </template>
        <el-form-item v-if="form.service_day.length < 5" label="">
          <div class="service-summary">
            <span>They will receive {{ mealsPerWeek }} meals a week.</span>
          </div>
        </el-form-item>
      </template>
      <template v-else>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Select date(s)"
              prop="dates"
              :rules="validateField()"
            >
              <el-date-picker
                v-model="form.dates"
                value-format="yyyy-MM-dd"
                type="dates"
                format="dd MMM, yyyy"
                popper-class="customized-multi-day-picker"
                prefix-icon="eden-icon--calendar"
                :picker-options="{
                  shortcuts: setMappedDates,
                  disabledDate: setDisabledDates,
                }"
                @input="setDates"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Number of meals per delivery">
              <div class="counter">
                <el-button
                  :type="'control'"
                  icon="el-icon-minus"
                  :disabled="form.qty === 1"
                  @click="form.qty--"
                />
                <el-input v-model.number="form.qty" v-number type="text" />
                <el-button
                  :type="'control'"
                  icon="el-icon-plus"
                  :disabled="form.qty === 20"
                  @click="form.qty++"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <el-row v-if="form.service_day.length" type="flex">
        <el-col :span="24">
          <el-form-item label="Total amount">
            <el-input
              v-model.number="form.amount"
              v-number
              type="text"
              readonly
              placeholder="Total amount"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
import menuTypes from "@/requests/services/meal/menu_types";

export default {
  name: "MealConfiguration",
  props: {
    config: {
      type: Object,
      default() {
        return {};
      },
    },
    oneTime: {
      type: Boolean,
      default: false,
    },
    menuTypeVariation: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      emitResponse: false,
      form: {
        menu_type_id: 1,
        menu_type_variation_id: null,
        service_day: ["tuesday"],
        service_day_quantity: {
          same: true,
          value: 1,
          tuesday: 1,
        },
        dates: [],
        qty: 1,
        amount: 0,
      },
      menu_type_variations: [],
      amount: 0,
    };
  },
  computed: {
    menuTypes() {
      return this.$store.getters.menu_types;
    },
    mealsPerWeek() {
      return Object.keys(this.form.service_day_quantity).reduce(
        (total, key) => {
          return this.form.service_day.includes(key)
            ? total + this.form.service_day_quantity[key]
            : total;
        },
        0,
      );
    },
    setMappedDates() {
      let dates = this.form.dates.map((date) => {
        return {
          text: this.formatDate(date, "mo d, y"),
        };
      });
      let dateText = {
        text: `Selected Dates: ${dates.length} `,
      };
      dates = [dateText, ...dates];
      return dates;
    },
    showMenuVariations() {
      return Object.keys(this.menuTypeVariation).length;
    },
    fixedMenuVariations() {
      let menuKeys = Object.keys(this.menuTypeVariation);
      if (menuKeys.length) {
        if (this.menuTypeVariation[menuKeys[0]] === "fixed") {
          return true;
        }
      }
      return false;
    },
    location() {
      return this.$store.getters.location;
    },
    filteredMenuTypes() {
      return this.menuTypes.filter((item) => item.name !== "Basic");
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (this.emitResponse) {
          this.setFormResponse();
        }
      },
    },
  },
  mounted() {
    this.$store.dispatch(actions.GET_MENU_TYPES);
    if (this.oneTime) {
      this.form.dates = this.formatDefaultServiceStartDate();
    }
    if (Object.keys(this.config).length) {
      this.setMealConfig();
    } else {
      this.emitResponse = true;
      this.setFormResponse();
    }
    if (this.fixedMenuVariations) {
      this.setMenuVariations();
    } else if (this.showMenuVariations) {
      this.getMenuVariations();
    } else {
      this.getMenuVariations();
    }
  },
  methods: {
    updateConfig(status, key) {
      if (status) {
        this.form.service_day_quantity[key] = this.form.service_day_quantity
          .same
          ? this.form.service_day_quantity.value
          : 1;
      } else {
        delete this.form.service_day_quantity[key];
      }
    },
    updateSameMealNumberValue() {
      this.form.service_day_quantity.value =
        this.form.service_day_quantity[this.form.service_day[0]];
      this.form.service_day.forEach((day) => {
        this.form.service_day_quantity[day] =
          this.form.service_day_quantity.value;
      });
    },
    updateSameMealNumber(action) {
      action === "add"
        ? this.form.service_day_quantity.value++
        : this.form.service_day_quantity.value--;
      this.form.service_day.forEach((day) => {
        this.form.service_day_quantity[day] =
          this.form.service_day_quantity.value;
      });
      this.form.service_day_quantity = Object.assign(
        {},
        this.form.service_day_quantity,
      );
    },
    updateMealNumber(day, action) {
      action === "add"
        ? this.form.service_day_quantity[day]++
        : this.form.service_day_quantity[day]--;
      this.form.service_day_quantity = Object.assign(
        {},
        this.form.service_day_quantity,
      );
    },
    sortServiceDays() {
      this.form.service_day = this.sortedDaysOfWeek(this.form.service_day);
      this.form.service_day.forEach((day) => {
        if (!this.form.service_day_quantity[day]) {
          this.form.service_day_quantity[day] = 1;
        }
      });
    },
    setDisabledDates(time) {
      if (this.getWeekday(time).toLowerCase() === "sunday") {
        return true;
      }
      if (
        this.form.dates.length === 5 &&
        !this.form.dates.includes(this.formatDate(time, "y-m-d"))
      ) {
        return true;
      }
      if (this.allowAccessFor(["superadmin"])) {
        return false;
      }
      if (this.allowAccessFor(["admin", "operations", "kitchen"])) {
        return time.getTime() < new Date();
      }
      return (
        time.getTime() < new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      );
    },
    setDates() {
      const { dates } = this.form;
      if (dates.length > 5) {
        this.form.dates.pop();
        this.$message.info("You can only select 5 days");
      }
      this.form.dates = dates.sort((a, b) => new Date(a) - new Date(b));
    },
    setMenuVariations() {
      let menuKeys = Object.keys(this.menuTypeVariation);
      this.form.menu_type_variation_id = this.menuTypeVariation[menuKeys[1]].id;
      this.menu_type_variations = this.menuTypeVariation[menuKeys[2]];
    },
    getMenuVariations() {
      menuTypes
        .getMenuVariations()
        .then((response) => {
          if (response.data.status) {
            let data = response.data.data;
            if (this.menuTypeVariation.menu) {
              this.form.menu_type_variation_id = this.menuTypeVariation.menu.id;
            } else {
              this.form.menu_type_variation_id = data[0].id;
            }

            // }
            this.menu_type_variations = data;

            // this line is specificly for eden for business
            //Eden for business don't offer basic menu
            if (this.$route.query?.company_name) {
              this.menu_type_variations = data.filter(
                (elm) => elm.name != "Basic menu",
              );
            }
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          // this.updating = false;
        });
    },
    setMealConfig() {
      const { service_day, meal_per_delivery, amount, menu_type_variation_id } =
        this.config;
      try {
        this.form.service_day = service_day.map((day) => day.toLowerCase());
        this.form.service_day_quantity = {
          same: meal_per_delivery.same_number_per_delivery,
          value: meal_per_delivery.same_number_per_delivery
            ? meal_per_delivery[service_day[0].toLowerCase()]
            : 1,
          ...meal_per_delivery,
        };
        this.form.amount = amount;
        this.form.menu_type_id = menu_type_variation_id;
        console.log(this.form, "well?");
        delete this.form.service_day_quantity.same_number_per_delivery;
      } catch (e) {
        this.$message.error("Error setting Meal Configuration");
      } finally {
        this.emitResponse = true;
      }
    },
    async setFormResponse() {
      let {
        service_day,
        service_day_quantity,
        dates,
        qty,
        menu_type_variation_id,
      } = this.form;
      const menuType = this.menu_type_variations?.find(
        (item) => item.id === menu_type_variation_id,
      );
      let response = {
        item: null,
        frequency:
          service_day.length < 5
            ? `weekly${this.serviceDayPrefix()[service_day.length]}`
            : "daily",
        service_day,
        meal_per_delivery: {
          ...service_day_quantity,
          same_number_per_delivery: service_day_quantity.same,
        },
        dates,
        qty: this.oneTime ? qty : this.mealsPerWeek,
        menu_type_variation_id: menu_type_variation_id,
      };
      delete response.meal_per_delivery.same;
      delete response.meal_per_delivery.value;

      // response.amount = this.pricing({
      //   meal: {
      //     frequency:
      //       this.oneTime &&
      //       menuType &&
      //       (menuType?.name?.includes("Lite") ||
      //         menuType?.name?.includes("Basic"))
      //         ? "lunch-one-off"
      //         : this.oneTime
      //         ? "one-off"
      //         : menuType &&
      //           (menuType?.name?.includes("Lite") ||
      //             menuType?.name?.includes("Basic"))
      //         ? "lunch"
      //         : response.frequency,
      //     qty: response.qty,
      //     service_day,
      //     meal_per_delivery: response.meal_per_delivery,
      //     dates,
      //     menu_type: [{ ...menuType }],
      //   },
      // });
      await this.getPrice({
        meal: {
          frequency: response.frequency,
          qty: response.qty,
          service_day,
          meal_per_delivery: response.meal_per_delivery,
          dates,
          menu_type: [{ ...menuType }],
          amount: 0,
        },
      });

      response.amount = this.amount;

      if (!this.oneTime) {
        delete response.dates;
      } else {
        response.frequency = "one-off";
        delete response.service_day;
        delete response.meal_per_delivery;
      }
      // response.menu_type = menuType
      this.form.amount = response.amount;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$emit("update", { validity: valid, response, service: "meal" });
        }
      });
    },
    async getPrice(meal) {
      const params = {
        plan: meal,
        domicile_location: this.location,
        menu_type_variation_id: this.form.menu_type_variation_id
          ? this.form.menu_type_variation_id
          : 1,
        return_new_plan: false,
      };
      await menuTypes
        .price(params)
        .then((response) => {
          // this.$message.success(response.data.message);
          this.loading = false;
          this.amount = response.data.data[0].amount;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
