import * as mutations from "../../mutation-types";
import * as actions from "../../action-types";

import schedule from "@/requests/fulfillment/index";

export default {
  // [actions.GET_ORDERS](context, { period, page }) {
  //   return new Promise((resolve, reject) => {
  //     orders
  //       .index(period, page)
  //       .then((response) => {
  //         if (response.data.status) {
  //           context.commit(
  //             mutations.ORDERS_SUMMARY,
  //             response.data.data.summary,
  //           );
  //           context.commit(mutations.ORDERS, {
  //             period,
  //             page,
  //             data: response.data.data.orders,
  //           });
  //         }
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },

  [actions.GET_FULFILLMENT_ORDERS_SCHEDULE](context, payload) {
    return new Promise((resolve, reject) => {
      schedule
        .index(payload)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.FULFILLMENT_ORDERS_SCHEDULE, {
              service: payload.service,
              start_date: payload.start_date,
              end_date: payload.end_date,
              page: payload.page,
              data: response.data.data ? response.data.data : {},
            });
          }
          console.log(response.data.data, "");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
