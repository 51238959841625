export default {
  methods: {
    formatMealIndex(index) {
      const ordinals = {
        0: "One",
        1: "Two",
        2: "Three",
        3: "Four",
        4: "Five",
        5: "Six",
        6: "Seven",
        7: "Eight",
        8: "Nine",
        9: "Ten",
        10: "Eleven",
        11: "Twelve",
        12: "Thirteen",
        13: "Fourteen",
        14: "Fifteen",
        15: "Sixteen",
        16: "Seventeen",
        17: "Eighteen",
        18: "Nineteen",
        19: "Twenty",
      };

      return ordinals[index || 0];
    },
    formatMealName(mealName) {
      if (mealName) {
        const words = mealName.split(" ");
        let newMealName = [];
        for (let i = 0; i < words.length; i++) {
          newMealName.push(
            words[i].charAt(0).toUpperCase() + words[i].substr(1).toLowerCase(),
          );
        }
        return newMealName.join(" ");
      }
      return null;
    },
    formatMealNameWithType(mealName) {
      if (mealName) {
        let lowerMealName = mealName.toLowerCase();
        if (lowerMealName.includes("|corporate standard")) {
          return lowerMealName.replace("|corporate standard", "");
        } else if (lowerMealName.includes("|individual standard")) {
          return lowerMealName.replace("|individual standard", "");
        } else if (lowerMealName.includes("|corporate lite")) {
          return lowerMealName.replace("|corporate lite", "");
        } else if (lowerMealName.includes("|corporate basic")) {
          return lowerMealName.replace("|corporate basic", "");
        }
        return mealName;
      }
      return null;
    },
    concatenateItems(...args) {
      const combo = args.filter((el) => {
        return el ? el : false;
      });

      return combo.join(", ");
    },
    formatCombo(combo) {
      return this.concatenateItems(
        combo.main_sides,
        combo.protein_sides,
        combo.other_sides,
        combo.sauce_sides,
      );
    },
    getDefaultSp(service) {
      const LOCATION = localStorage.getItem("eden-crm-location");
      const env = process.env.VUE_APP_ENV;

      const dev = {
        beauty: {
          KE: 276,
          NG: null,
        },
        cleaning: {
          KE: 280,
          NG: 278,
        },
        laundry: {
          KE: 281,
          NG: 132,
        },
        meal: {
          KE: 277,
          NG: 274,
        },
      };
      const production = {
        beauty: {
          KE: 428,
          NG: null,
        },
        cleaning: {
          KE: 430,
          NG: 411,
        },
        laundry: {
          KE: 429,
          NG: 423,
        },
        meal: {
          KE: 427,
          NG: 422,
        },
      };
      return env === "production"
        ? production[service][LOCATION]
        : dev[service][LOCATION];
    },
  },
};
