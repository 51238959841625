import axios from "axios";

export default {
  list(params) {
    return axios.get(`marketplace_crm/products?${params}`);
  },
  disable(id, productId, payload) {
    return axios.post(
      `marketplace_crm/products/vendor/${id}/disable_product/${productId}`,
      payload,
    );
  },
  enable(id, productId) {
    return axios.post(
      `marketplace_crm/products/vendor/${id}/enable_product/${productId}`,
    );
  },
};
