import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = () => ({
  customers_summary: {},
  employees: {
    total: null,
    pages: {}
  },
  receipts: {
    total: null,
    pages: {}
  },
  corporate_accounts: {
    total: null,
    pages: {},
  },

  company_subscriptions: [],
  customer_schedule: {},
  // customer_orders: {},
  // customer_activity: { total: null, pages: {} },
  // customer_transactions: { total: null, pages: {} },
});

export default {
  state,
  getters,
  actions,
  mutations,
};
