import axios from "axios";

export default {
  login(payload) {
    return axios.post("admin/login", payload);
  },
  sendResetCode(payload) {
    return axios.post(`password/createpasscode`, payload);
  },

  resetPassword(payload) {
    return axios.post(`password/reset`, payload);
  },

  acceptInvite(id, payload) {
    return axios.put(`lighthouse/settings/account/${id}/update_invite`,payload);
  },

  impersonate(payload) {
    return axios.post("admin/impersonate", payload);
  },

  stopImpersonation(payload) {
    return axios.post("admin/stop_impersonation", payload);
  },
};
