import axios from "axios";

export default {
  index({ page, start_date, end_date }) {
    let params = `page=${page}&start_date=${start_date}&end_date=${end_date}`;

    return axios.get(`crm/gardener/bulk/message?${params}`);
  },

  filter(params) {
    return axios.get(`crm/gardener/bulk/message?${params}`);
  },

  message(start, id) {
    return axios.get(
      `crm/gardener/bulk/message_with_recipients?start_date=${start}&bulk_message_id=${id}`
    );
  },
};
