import Vue from "vue";
import VueRouter from "vue-router";

import guest from "./guest";
import activities from "./activities";
import dashboard from "./dashboard";
import feedback from "./feedback";
import gardeners from "./gardeners";
import customers from "./customers";
import danceFestival from "./dance-festival";
import orders from "./orders";
import zones from "./zones";
import services from "./services";
import logistics from "./logistics";
import serviceoperations from "./serviceoperations";
import subscriptions from "./subscriptions";
import discounts from "./discounts";
import communications from "./communications";
import steward from "./steward";
import settings from "./settings";
import featureTracker from "./feature-tracker";
import business from "./business";
import vendors from "./vendors";
import curations from "./curations";
import products from "./products";
import ordersAndSchedule from "./ordersAndSchedule";
import fulfillment from "./fulfillment";
import tags from "./tags";
import marketplaceLogistics from "./marketplace-logistics";
import voucher from "./voucher";

Vue.use(VueRouter);
const routes = guest.concat(
  activities,
  featureTracker,
  dashboard,
  danceFestival,
  feedback,
  gardeners,
  customers,
  orders,
  zones,
  services,
  logistics,
  serviceoperations,
  subscriptions,
  discounts,
  communications,
  steward,
  settings,
  business,
  vendors,
  curations,
  products,
  ordersAndSchedule,
  fulfillment,
  tags,
  marketplaceLogistics,
  voucher,
);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.name === "error") {
    next();
    return;
  }

  if (to.name === "unauthorized") {
    next();
    return;
  }

  if (to.name === "reset-password") {
    next();
    return;
  }

  const user = JSON.parse(localStorage.getItem("eden-crm-user"));
  const user_role = localStorage.getItem("eden-crm-user-role");
  const stewards = ["steward", "ofa"];

  const guestRoute = to.meta.permission === "guest";
  let authenticatedRoute = false;
  let authenticatedUser = false;

  if (user) {
    authenticatedRoute = to.meta.permission.includes(user_role);
    authenticatedUser = !!user.access_token;
  }

  if (!authenticatedUser && guestRoute) {
    next();
  } else if (authenticatedUser && guestRoute) {
    stewards.includes(user_role)
      ? next({ name: "production-provisions.index" })
      : next({ name: "customers.index" });
  } else if (authenticatedRoute && authenticatedUser) {
    next();
  } else if (authenticatedRoute && !authenticatedUser) {
    next({ name: "login" });
  } else if (!authenticatedRoute && authenticatedUser) {
    next({ name: "unauthorized" });
  } else {
    next({ name: "login" });
  }
});

export default router;
