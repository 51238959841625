import axios from "axios";

export default {
  index({ service, start_date, end_date, page }) {
    let params = `start_date=${start_date}&end_date=${end_date}&page=${page}`;
    return axios.get(`crm/micro-fulfillment-center/orders?service=${service}&${params}`);
  },

  search( query) {
    return axios.get(
      `crm/micro-fulfillment-center/orders/search/${query}`,
    );
  },

  filter( params) {
    return axios.get(`crm/micro-fulfillment-center/orders?${params}`);
  },

  activity(payload) {
    return axios.post(`crm/micro-fulfillment-center/operations/meal`, payload);
  },

  export(params) {
    return axios.get(`crm/micro-fulfillment-center/orders/all?${params}`);
  }

//   export({ servicePartnerId, period }) {
//     const sp = servicePartnerId === "all" ? "" : `${servicePartnerId}/`;
//     return axios.get(
//       `${sp}customer_orders/for_stewards/zones/export?period=${period}`,
//     );
//   },

};
