<template>
  <div>
    <div v-if="attachments.length" class="attachments">
      <div
        v-for="(attachment, index) in attachments"
        :key="index"
        class="attachment"
      >
        <a :href="handleDownload(attachment)" class="attachment-image">
          <img
            :src="getAttachmentImage(attachment.url)"
            :alt="attachment.name"
            :class="getAttachmentType(attachment.url)"
          />
        </a>
        <span class="fileName">{{ attachment.name }}</span>
      </div>
    </div>
    <eden-content-empty v-else :text="'No attachments'" :size="70" />
  </div>
</template>

<script>
export default {
  name: "EdenAttachments",
  props: {
    attachments: {
      type: Array,
      default() {
        return [];
      },
    },
    action:{
      type:String,
      default:"View"
    }
  },
  methods: {
    getAttachmentType(url) {
      const extension = url.split(".").pop();
      const images = ["jpg", "jpeg", "png"];

      return images.includes(extension) ? "image" : "file";
    },
    getAttachmentName({ name, url }) {
      const extension = url.split(".").pop();
      return (
        (name.length > 8 ? `${name.slice(0, 8)}... ` : name) + "." + extension
      );
    },
    handleDownload(attachment){
      return attachment.url.replace("upload","upload/fl_attachment")
    },
    getAttachmentImage(url) {
      const extension = url.split(".").pop();

      switch (extension) {
        case "pdf":
          return this.getImage("attachments/pdf.svg");
        case "doc":
          return this.getImage("attachments/doc.svg");
        case "docx":
          return this.getImage("attachments/doc.svg");
        default:
          return this.getThumbnailFromCloudinary(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.attachments {
  display: flex;
  flex-wrap: wrap;
}

.attachment {
  border: 1px solid var(--eden-grey-septenary);
  border-radius: 8px;
  width: 120px;
  padding: 6px;
  margin-right: 10px;

  &-image {
    border-radius: 4px;
    background: var(--eden-grey-septenary);
    margin-bottom: 10px;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      &.image {
        height: 100%;
        width: 100%;
        object-fit: contain;
        border-radius: 4px;
      }
    }
  }
  .fileName{
    overflow-wrap: break-word;
  }
  p {
    font-size: 0.875rem;
  }
}
</style>
