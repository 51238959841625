export default {
  services: (state) => state.services,
  service: (state) => state.service,
  service_types: (state) => state.service_types,
  menus: (state) => state.menus,
  ingredients: (state) => state.ingredients,
  food_tags: (state) => state.food_tags,
  meal_item_list: (state) => state.meal_item_list,
  menu_types: (state) => state.menu_types,
  menu_variations: (state) => state.menu_variations,
};
