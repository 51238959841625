import * as mutations from "../../mutation-types";
import * as actions from "../../action-types";

import active from "../../../requests/vendors/active";
import inactive from "../../../requests/vendors/inactive";
import pending from "../../../requests/vendors/pending";
import rejected from "../../../requests/vendors/rejected";
import disabled from "../../../requests/vendors/disabled";
import accountManagers from "../../../requests/vendors/account-managers";
import summary from "../../../requests/vendors/summary";

export default {
  [actions.GET_VENDORS_SUMMARY](context, page) {
    return new Promise((resolve, reject) => {
      summary
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.VENDORS_SUMMARY, {
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [actions.GET_VENDORS_ACTIVE](context, page) {
    return new Promise((resolve, reject) => {
      active
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.VENDORS_ACTIVE, {
              page,
              data: response.data.data,
              meta: response.data.meta,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [actions.GET_VENDORS_INACTIVE](context, page) {
    return new Promise((resolve, reject) => {
      inactive
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.VENDORS_INACTIVE, {
              page,
              data: response.data.data,
              meta: response.data.meta,
            });
          }
          console.log(response.data, "inactive response");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [actions.GET_VENDORS_PENDING](context, page) {
    return new Promise((resolve, reject) => {
      pending
        .index(page)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data);
            context.commit(mutations.VENDORS_PENDING, {
              page,
              data: response.data.data,
              meta: response.data.meta,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_VENDORS_REJECTED](context, page) {
    return new Promise((resolve, reject) => {
      rejected
        .index(page)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data);
            context.commit(mutations.VENDORS_REJECTED, {
              page,
              data: response.data.data,
              meta: response.data.meta,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [actions.GET_VENDORS_DISABLED](context, page) {
    return new Promise((resolve, reject) => {
      disabled
        .index(page)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data);
            context.commit(mutations.VENDORS_DISABLED, {
              page,
              data: response.data.data,
              meta: response.data.meta,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [actions.GET_ACCOUNT_MANAGERS_LIST](context, page) {
    return new Promise((resolve, reject) => {
      accountManagers
        .list(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.ACCOUNT_MANAGERS, {
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [actions.TOGGLE_VENDOR_ACTIVE_STATE](context, data) {
    return new Promise((resolve, reject) => {
      const { id, ...payload } = { ...data };
      active
        .updateVendorState(id, payload)
        .then((response) => {
          if (response.data.status) {
            // console.log(response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [actions.VENDOR_APPROVAL](context, data) {
    return new Promise((resolve, reject) => {
      const { id, ...payload } = { ...data };
      pending
        .vendorApproval(id, payload)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
