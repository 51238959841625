import axios from "axios";

export default {
  items(spId, period, from, to) {
    if (period === "custom") {
      return axios.get(
        `service_partners/${spId}/kitchen_provision?from=${from}&to=${to}`
      );
    }
    return axios.get(
      `service_partners/${spId}/kitchen_provision?period=${period}`
    );
  },

  filter(spId, params = "") {
    return axios.get(
      `service_partners/${spId}/kitchen_provision${params}`
    );
  }
};
