import axios from "axios";

export default {
  index({ service, page }) {
    return axios.get(`service_type/${service}/all?page=${page}`);
  },
  search(service, query) {
    return axios.get(`service_type/${service}/all/search/${query}`);
  },

  filter(payload) {
    return axios.post("service_type/meal/all/filter", payload);
  },

  serviceTypeGet(id) {
    return axios.get(`service_type/${id}`);
  },

  serviceTypeAdd(payload) {
    return axios.post("service_type", payload);
  },

  serviceTypeUpdate(id, payload) {
    return axios.put(`service_type/${id}`, payload);
  },

  serviceTypeDelete(service, payload) {
    return axios.post(`service_type/${service}/expunge`, payload);
  },
};
