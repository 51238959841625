import axios from "axios";

export default {
  list() {
    return axios.get("customers");
  },

  // strippedList() {
  //   return axios.get("customers/no_appended_value");
  // },

  strippedList() {
    return axios.get("customers/subscribed_one_time");
  },

  customersList() {
    return axios.get("customers/no_appended_value");
  },

  index(page) {
    return axios.get(`customers/all?page=${page}`);
  },

  search(query) {
    return axios.get(`customers/all/search/${query}`);
  },

  searchByName(query) {
    return axios.get(`customers/search/name/${query}`);
  },

  export() {
    return axios.get("customers/all/export");
  },

  filter(params) {
    return axios.get(`crm/customer/filter?${params}`);
  },

  add(payload) {
    return axios.post("customer", payload);
  },

  update(id, payload) {
    return axios.put(`customer/${id}`, payload);
  },

  get(id) {
    return axios.get(`customer/${id}`);
  },

  personas() {
    return axios.get("customers/persona");
  },

  feed(id, params) {
    return axios.get(`crm/customer/feed/${id}?${params}`);
  },

  gardenerLog(id) {
    return axios.get(`gardener_assignment_logs/${id}`);
  },

  gardenerChat(userId, gardenerId, startDate) {
    return axios.get(
      `customer/${userId}/gardener/${gardenerId}/chat_logs/${startDate}`,
    );
  },

  issuesTracker(user_id) {
    return axios.get(
      `crm/issue-tracker?affected_user_id[]=${user_id}`
    );
  },
};
