<template>
  <!-- add `data-active` and the event listeners -->
  <div
    :data-active="active"
    @dragenter.prevent="setActive"
    @dragover.prevent="setActive"
    @dragleave.prevent="setInactive"
    @drop.prevent="onDrop"
    :style="[logo ? 'logo-style' : '']"
  >
    <!-- share state with the scoped slot -->
    <slot :dropZoneActive="active"></slot>
  </div>
</template>

<script>
export default {
  props: {
    types: {
      type: Array,
      default: () => [],
    },
    error: String,
    logo: Boolean,
  },
  data() {
    return {
      active: false,
      inActiveTimeout: null,
    };
  },
  methods: {
    setActive() {
      this.active = true;
      clearTimeout(this.inActiveTimeout);
    },
    setInactive() {
      this.inActiveTimeout = setTimeout(() => {
        this.active = false;
      }, 50);
    },
    onDrop(e) {
      this.setInactive();
      const droppedFiles = [...e.dataTransfer.files];

      if (
        this.types.length > 0 &&
        droppedFiles.some((item) =>
          this.types.some((allowedType) => item.type.startsWith(allowedType)),
        )
      ) {
        this.$emit("files-dropped", [...droppedFiles]);
      } else {
        this.$message.error({
          message: this.error || "Incorrect file type uploaded",
        });
      }
    },

    preventDefaults(e) {
      e.preventDefault();
    },
  },
  mounted() {
    const events = ["dragenter", "dragover", "dragleave", "drop"];
    events.forEach((eventName) => {
      document.body.addEventListener(eventName, this.preventDefault);
    });
  },
  beforeDestroy() {
    const events = ["dragenter", "dragover", "dragleave", "drop"];
    events.forEach((eventName) => {
      document.body.removeEventListener(eventName, this.preventDefault);
    });
  },
};
</script>

<style scoped>
.logo-style {
  height: 60px !important;
  width: 60px !important;
  border-radius: 100% !important;
}
</style>
