import * as mutations from "@/store/mutation-types";
import * as actions from "@/store/action-types";
import auth from "@/requests/auth";
import store from "../../index";

export default {
  [actions.LOGIN](context, payload) {
    return new Promise((resolve, reject) => {
      auth
        .login(payload)
        .then((response) => {
          if (response.data.status) {
            const data = response.data.data;
            const token = data.access_token;
            window.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            localStorage.setItem("eden-crm-token", JSON.stringify(token));
            localStorage.setItem("eden-crm-user", JSON.stringify(data));
            localStorage.setItem("eden-crm-user-role", data.role);
            localStorage.setItem("eden-crm-state", JSON.stringify(store.state));
            context.commit(mutations.TOKEN, token);
            context.commit(mutations.AUTHENTICATED, !!token);
            context.commit(mutations.LOCATION, data.domicile_location);
            context.commit(mutations.USER, data);
            context.commit(mutations.USER_ROLE, data.role);
            context.commit(mutations.IMPERSONATE, false);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.LOGOUT](context) {
    return new Promise((resolve, reject) => {
      const defaultState = JSON.parse(localStorage.getItem("eden-crm-state"));
      context.commit(mutations.RESET_STATE, defaultState);
      localStorage.clear();
      resolve(true);
      reject(false);
    });
  },
};
