<template>
  <el-collapse-item
    class="eden-collapse--item"
    :name="name"
    :disabled="disabled"
  >
    <template slot="title">
      <div class="eden-collapse--item__overview">
        <div class="summary"><slot name="summary" /></div>
        <div class="breakdown"><slot name="breakdown" /></div>
      </div>
    </template>
    <div class="eden-collapse--item__content">
      <slot name="content" />
      <div v-if="Object.keys(activities).length">
        <eden-progress-bar :activities="activities" :wrap-bar="wrapBar" />
      </div>
    </div>
  </el-collapse-item>
</template>

<script>
export default {
  name: "EdenCollapseItem",
  props: {
    name: {
      type: String,
      default: "",
    },
    activities: {
      type: Object,
      default() {
        return {};
      },
    },
    wrapBar: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.eden-collapse--item {
  &__overview {
    width: 100%;

    .summary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      top: -2px;

      .type {
        color: var(--eden-grey-primary);
        font-weight: 500;
        font-size: 0.8rem;
      }

      .status {
        display: flex;
        align-items: center;
        font-size: 0.775rem;

        i {
          margin-right: 5px;
        }
      }
    }

    .breakdown {
      margin-top: 7px;

      &-section {
        margin-top: 15px;

        span {
          display: block;

          &.type {
            color: var(--eden-grey-primary);
            font-weight: 500;
            font-size: 0.775rem;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .subscription.title,
  .pause.title,
  .pause-total {
    margin: 6px 0 16px 0;
  }
}
</style>
