import * as mutations from "@/store/mutation-types";
import * as actions from "@/store/action-types";
import services from "@/requests/services";
import service from "@/requests/services/service";
import menu from "@/requests/services/meal/menu";
import menu_types from "@/requests/services/meal/menu_types";
import meal from "@/requests/services/meal/";
import ingredients from "@/requests/services/meal/ingredients";
import foodTags from "@/requests/services/meal/food-tags";

export default {
  [actions.GET_SERVICES](context) {
    return new Promise((resolve, reject) => {
      services
        .index()
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.SERVICES, response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_SERVICE](context, payload) {
    return new Promise((resolve, reject) => {
      service
        .index(payload)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.SERVICE, {
              service: payload.service,
              page: payload.page,
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_MEAL_ITEM_LIST](context) {
    return new Promise((resolve, reject) => {
      meal
        .all()
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.MEAL_ITEM_LIST, response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // [actions.GET_MEAL_ITEM_ALL](context, payload) {
  //   return new Promise((resolve, reject) => {
  //     meal
  //       .all(payload)
  //       .then((response) => {
  //         if (response.data.status) {
  //           context.commit(mutations.MEAL_ITEM_LIST, response.data.data);
  //         }
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },

  [actions.GET_SERVICE_TYPES](context, service) {
    return new Promise((resolve, reject) => {
      services
        .serviceTypes(service)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.SERVICE_TYPES, {
              service,
              data: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_MENUS](context, { page, params }) {
    return new Promise((resolve, reject) => {
      menu
        .index(page, params)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data);
            context.commit(mutations.MENUS, {
              page,
              data: response.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_INGREDIENTS](context) {
    return new Promise((resolve, reject) => {
      ingredients
        .list()
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.INGREDIENTS, response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_FOOD_TAGS](context) {
    return new Promise((resolve, reject) => {
      foodTags
        .list()
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.FOOD_TAGS, response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_MENU_TYPES](context) {
    return new Promise((resolve, reject) => {
      menu_types
        .list()
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.MENU_TYPES, response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_MENU_VARIATIONS](context) {
    return new Promise((resolve, reject) => {
      menu_types
        .getMenuVariations()
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.MENU_VARIATIONS, response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
