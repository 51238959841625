import * as types from "../../mutation-types";

export default {
  [types.SUBSCRIPTIONS_SUMMARY](state, subscriptions_summary) {
    state.subscriptions_summary = subscriptions_summary;
  },

  [types.SUBSCRIPTIONS](state, { page, subscriptions }) {
    state.subscriptions[page] = subscriptions;
  },

  [types.BILLING_HISTORY](state, { page, billing_history, clear }) {
    if (clear) {
      state.billing_history = {};
    } else {
      state.billing_history[page] = billing_history;
    }
  },

  [types.TRANSACTIONS](state, { page, transactions }) {
    state.transactions[page] = transactions;
  },
  [types.PAYMENT_LINKS](state, { page, payment_links }) {
    state.payment_links[page] = payment_links;
  },
};
