<template>
  <div class="tab-toggler">
    <div
      v-for="item in toggleItems"
      class="tab-toggler--item"
      :class="{ 'is-active text-primary': currentNavMode === item }"
      :key="item"
      @click="$emit('toggle-nav-mode', item)"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    toggleItems: Array,
    currentNavMode: String,
  },
};
</script>

<style lang="scss" scoped>
.tab-toggler {
  background-color: var(--eden-grey-septenary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  border-radius: 15px;
  cursor: pointer;

  &--item {
    padding: 0.5rem;
    font-size: 0.8em;
    flex-basis: 50%;
    text-align: center;
  }

  &--item.is-active {
    border-radius: 15px;
    background-color: white;
  }
}
</style>
