import axios from "axios";

export default {
  index({ service, period }) {
    return axios.get(`operations/${service}/schedule?period=${period}`);
  },

  orders({ service, date, page }) {
    return axios.get(
      `operations/${service}/schedule-orders?date=${date}&page=${page}`,
    );
  },
  orderScheduleCount(payload) {
    return axios.post("crm/third-party-user/schedule/count", payload);
  },
};
