import axios from "axios";

export default {
  summary(start_date, end_date) {
    return axios.get(
      `gardeners/summary?start_date=${start_date}&end_date=${end_date}`,
    );
  },

  list() {
    return axios.get("gardeners/list/lite");
  },

  index(page) {
    return axios.get(`gardeners?page=${page}`);
  },

  search(query) {
    return axios.get(`gardeners/search/${query}`);
  },

  filter(payload) {
    return axios.post("gardeners/filter", payload);
  },
  filterCustomers(filters) {
    return axios.get(`crm/customer/filter/gardener?${filters}`);
  },
};
