import Vue from "vue";
import JsonCSV from "vue-json-csv";
import JsonExcel from "vue-json-excel";
import { jsPDF } from "jspdf";
import { applyPlugin } from "jspdf-autotable";

Vue.component("downloadCsv", JsonCSV);
Vue.component("downloadExcel", JsonExcel);
applyPlugin(jsPDF);

export default {
  methods: {
    generatePdf({ title, headers, data, fileName, titleMargin }) {
      const doc = new jsPDF({ orientation: "landscape" });
      const totalPages = "{total_pages_count_string}";

      doc.autoTable({
        head: [headers],
        body: data,
        margin: { top: titleMargin ? titleMargin : 10 },
        styles: { overflow: "linebreak", cellWidth: "auto" },
        tableWidth: "auto",
        didDrawPage: (data) => {
          doc.setFontSize(12);
          // doc.setFont("CerebriSans-Regular");
          doc.setLineHeightFactor(1.6);

          if (doc.internal.getNumberOfPages() === 1) {
            doc.text(`${title}`, data.settings.margin.left + 5, 22);
          }

          let str = "Page " + doc.internal.getNumberOfPages();
          if (typeof doc.putTotalPages === "function") {
            str = str + " of " + totalPages;
          }
          doc.setFontSize(10);

          let pageSize = doc.internal.pageSize;
          let pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          doc.text(str, data.settings.margin.left, pageHeight - 10);
          data.settings.margin.top = 10;
        },
        didParseCell: (data) => {
          if (data.row.raw.sn === "S/N") {
            data.cell.styles.fillColor = [3, 168, 78];
          }
        },
      });

      if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPages);
      }

      doc.save(`${fileName}.pdf`);
    },
    generateCsv({ title, data }) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(data[0]).join(";"),
        ...data.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const csvData = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", csvData);
      link.setAttribute("download", `${title}.csv`);
      link.click();
    },
  },
};
