export default {
  methods: {
    stringTrim(text) {
      if (!text) {
        return "";
      }
      return text.trimStart().trimEnd().toLowerCase();
    },
    formatQueryText(text) {
      if (!text) {
        return "";
      }
      return text
        .trimStart()
        .trimEnd()
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .toLowerCase();
    },
    formatQueryHighlight(type, text, query) {
      if (query === "") {
        return type === "name" ? this.formatName(text) : text;
      }

      let lText = text.toLowerCase();
      let lQuery = this.formatQueryText(query);

      let startsWith = lText.startsWith(lQuery);
      let substring = lText.includes(lQuery);

      if (startsWith) {
        return lText.replace(
          lQuery,
          '<span class="highlighted">' + this.formatText(lQuery) + "</span>"
        );
      } else if (substring) {
        return text.replace(
          lQuery,
          '<span class="highlighted">' + lQuery + "</span>"
        );
      } else {
        return text;
      }
    },
    formatFullName({ firstname, lastname }) {
      if (!firstname) {
        return "";
      }
      return this.formatText(firstname) + " " + this.formatText(lastname);
    },
    formatDeletedEmail(email) {
      if (!email) {
        return "";
      }
      if (email.includes('deleted_account')){
        let emailIndex = email.indexOf("_", "deleted_account_".length);
        let newEmail = email.slice(emailIndex + 1);
        return newEmail;
      }
      return email
    },
    formatArrayToString({ data, property, separator = ", " }) {
      if (!data.length) {
        return "-";
      }
      return data.reduce((acc, item, index) => {
        return (
          acc +
          (property ? item[property] : item) +
          (index + 1 === data.length ? "" : separator)
        );
      }, "");
    },
  },
};
