/**
 * Auth Module
 */
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

/**
 * Activities Module
 */
export const GET_ACTIVITIES = "GET_ACTIVITIES";

/**
 * Feedbacks Module
 */
export const GET_FEEDBACK_SUMMARY = "GET_FEEDBACK_SUMMARY";
export const GET_FEEDBACK = "GET_FEEDBACK";

/**
 * Customers Module
 */
export const GET_CUSTOMERS_INDIVIDUAL = "GET_CUSTOMERS_INDIVIDUAL";
export const GET_CUSTOMERS_CORPORATE = "GET_CUSTOMERS_CORPORATE";
export const GET_CUSTOMERS_ONETIME = "GET_CUSTOMERS_ONETIME";

export const GET_CUSTOMER_SCHEDULE = "GET_CUSTOMER_SCHEDULE";
export const GET_CUSTOMER_ORDERS = "GET_CUSTOMER_ORDERS";
export const GET_CUSTOMER_TRANSACTIONS = "GET_CUSTOMER_TRANSACTIONS";

/**
 * Rden for Business Module
 */
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const GET_CORPORATE_ACCOUNT = "GET_CORPORATE_ACCOUNT";
export const GET_UPLOAD_RECEIPTS = "GET_UPLOAD_RECEIPTS";

/**
 * Vendor Module
 */
export const GET_VENDORS_ACTIVE = "GET_VENDORS_ACTIVE";
export const GET_VENDORS_INACTIVE = "GET_VENDORS_INACTIVE";
export const GET_VENDORS_SUMMARY = "GET_VENDORS_SUMMARY";
export const GET_VENDORS_REJECTED = "GET_VENDORS_REJECTED";
export const GET_VENDORS_PENDING = "GET_VENDORS_PENDING";
export const GET_VENDORS_DISABLED = "GET_VENDORS_DISABLED";

export const TOGGLE_VENDOR_ACTIVE_STATE = "TOGGLE_VENDOR_ACTIVE_STATE";
export const VENDOR_APPROVAL = "APPROVE_VENDOR";
export const GET_ACCOUNT_MANAGERS_LIST = "GET_ACCOUNT_MANAGERS_LIST";
// export const GET_CUSTOMER_ORDERS = "GET_CUSTOMER_ORDERS";
// export const GET_CUSTOMER_TRANSACTIONS = "GET_CUSTOMER_TRANSACTIONS";

/**
 * Gardeners Module
 */
export const GET_GARDENERS_LIST = "GET_GARDENERS_LIST";
export const GET_GARDENERS = "GET_GARDENERS";
export const GET_GARDENER_SCHEDULE = "GET_GARDENER_SCHEDULE";
export const GET_GARDENERS_PAYMENT_TRACKER = "GET_GARDENERS_PAYMENT_TRACKER";

/**
 * Orders Module
 */
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SCHEDULE = "GET_ORDERS_SCHEDULE";
export const GET_FULFILLMENT_ORDERS_SCHEDULE =
  "GET_FULFILLMENT_ORDERS_SCHEDULE";

/**
 * Zones, MFCs, and Location Areas Module
 */
export const GET_ZONES_LIST = "GET_ZONES_LIST";
export const GET_ZONES = "GET_ZONES";

export const GET_LOCATION_AREAS_LIST = "GET_LOCATION_AREAS_LIST";
export const GET_LOCATION_AREAS = "GET_LOCATION_AREAS";

export const GET_MICROFULFILLMENT_LIST = "GET_MICROFULFILLMENT_LIST";
export const GET_MICROFULFILLMENT_CENTRES = "GET_MICROFULFILLMENT_CENTRES";

/**
 * Services Module
 */
export const GET_SERVICES = "GET_SERVICES";
export const GET_SERVICE = "GET_SERVICE";
export const GET_SERVICE_TYPES = "GET_SERVICE_TYPES";

/**
 * Meal Services Sub-Module
 */
export const GET_MEAL_ITEM_LIST = "GET_MEAL_ITEM_LIST";
export const GET_MENUS = "GET_MENUS";
export const GET_INGREDIENTS = "GET_INGREDIENTS";
export const GET_FOOD_TAGS = "GET_FOOD_TAGS";
export const GET_MENU_TYPES = "GET_MENU_TYPES";
export const GET_MENU_VARIATIONS = "GET_MENU_VARIATIONS";

/**
 * Operations Module
 */
export const GET_LOGISTICS_DIRECTORY = "GET_LOGISTICS_DIRECTORY";
export const GET_LOGISTICS_SCHEDULE = "GET_LOGISTICS_SCHEDULE";
export const GET_LOGISTICS_SCHEDULE_ORDERS = "GET_LOGISTICS_SCHEDULE_ORDERS";

/**
 * Service Operations
 */
export const GET_SERVICE_PARTNERS_LIST = "GET_SERVICE_PARTNERS_LIST";
export const GET_SERVICE_PARTNERS = "GET_SERVICE_PARTNERS";
export const GET_PROVISIONED_ORDERS = "GET_PROVISIONED_ORDERS";

/**
 * SubscriptionS Module
 */
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const GET_BILLING_HISTORY = "GET_BILLING_HISTORY";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_PAYMENT_LINKS = "GET_PAYMENT_LINKS";

/**
 * Discounts and Rewards
 */
export const GET_DISCOUNTS = "GET_DISCOUNTS";

/**
 * Communications
 */
export const GET_BULK_MESSAGES = "GET_BULK_MESSAGES";

/**
 * Settings
 */
export const GET_MEMBERS = "GET_MEMBERS";

/**
 * Stewards
 */
export const GET_PRODUCTION_PROVISIONS = "GET_PRODUCTION_PROVISIONS";
export const GET_COMBO_CHECK = "GET_COMBO_CHECK";
export const GET_COMBO_HISTORY = "GET_COMBO_HISTORY";

export const GET_OFA_HISTORY = "GET_OFA_HISTORY";

// Marketplace

export const GET_BUSINESS_TYPE = "GET_BUSINESS_TYPE";
export const GET_PRODUCTS = "GET_PRODUCTS";
