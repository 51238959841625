const all = ["superadmin", "admin", "vendor_manager"];

export const navRoutesMarketplace = () => {
  return [
    {
      icon: "users",
      label: "Vendors",
      name: "vendors",
      access: all.concat(["operations", "growth", "gardener", "gardener_pro"]),
      active: true,
    },
    {
      icon: "order-fulfillment",
      label: "Products",
      name: "products",
      access: all.concat(["operations", "growth", "gardener_pro", "gardener"]),
      active: true,
    },
    {
      icon: "orders",
      label: "Orders",
      name: "ordersAndSchedule",
      access: all.concat(["operations", "gardener", "gardener_pro", "growth"]),
      active: true,
    },
    {
      icon: "kitchen",
      label: "Curations",
      name: "curations",
      access: all.concat(["growth"]),
      active: true,
    },
    {
      icon: "feedback",
      label: "Tags",
      name: "tags",
      access: all.concat(["growth", "gardener_pro", "gardener"]),
      active: true,
    },
    {
      icon: "service-partners",
      label: "Logistics",
      name: "marketplace-logistics",
      access: all.concat(["operations", "growth"]),
      active: true,
      sub_routes: [
        {
          label: "Directory",
          name: "marketplace-logistics.directory",
          access: all.concat(["operations", "growth"]),
          active: true,
        },
      ],
    },
    {
      icon: "discounts",
      label: "Vouchers & Discounts",
      name: "vouchers",
      access: all.concat(["operations", "growth", "gardener", "gardener_pro"]),
      active: true,
    },
  ];
};
