import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = () => ({
  subscriptions_summary: {},
  subscriptions: {},
  billing_history: {},
  transactions: {},
  payment_links: {},
});

export default {
  state,
  getters,
  actions,
  mutations,
};
