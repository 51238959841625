export default {
  methods: {
    /**
     * Sort days of the week into right format
     * @param days
     * @param format
     * @returns {*}
     */
    sortWeekDays(days, format = "short") {
      const formats = {
        short: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
        long: [
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ],
      };

      return days
        .map((day) => day.toLowerCase())
        .sort((a, b) =>
          formats[format].indexOf(a) < formats[format].indexOf(b) ? -1 : 1
        );
    },
    /**
     * Summing the contents of an array or property in an array of objects
     * @param data
     * @param property
     * @returns {*}
     */
    sumArray(data, property) {
      return data.reduce((total, el) => {
        return total + el[property];
      }, 0);
    },

    /**
     * Sort Array based on property and direction
     * @param data
     * @param property
     * @param direction
     * @returns {*}
     */
    sortArray({ data, property, direction = "asc" }) {
      if (!data.length) {
        return [];
      }
      let sorted = [];
      if (property && typeof data[0][property] === "string") {
        sorted = data.sort((a, b) =>
          a[property].toLowerCase() < b[property].toLowerCase() ? -1 : 1
        );
      } else if (property) {
        sorted = data.sort((a, b) => (a[property] < b[property] ? -1 : 1));
      } else {
        sorted = data.sort((a, b) => (a < b ? -1 : 1));
      }
      return direction === "asc" ? sorted : sorted.reverse();
    },

    /**
     * Sort Object
     * @param data
     * @returns {{}}
     */
    sortObject(data) {
      return Object.keys(data)
        .sort()
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: data[key],
          }),
          {}
        );
    },
    sortUniqueObject(data, property) {
      return data.reduce((item, o) => {
        if (!item.some((obj) => obj[property] === o[property])) {
          item.push(o);
        }
        return item;
      }, []);
    },
    sortArrayIntoObject(array, key) {
      const object = {};
      return array.reduce((obj, item) => {
        return {
          ...obj,
          [item[key]]: item,
        };
      }, object);
    },
    sortObjectAsParams(object) {
      let params = "";
      let paramsKeys = Object.keys(object);

      paramsKeys.forEach((property) => {
        if (Array.isArray(object[property])) {
          object[property].forEach((value) => {
            params = `${params}&${property}[]=${value}`;
          });
        } else {
          params = `${params}&${property}=${object[property]}`;
        }
      });
      params = params.replace("&", "");
      return params;
    },
    paginate(data, size) {
      let pages = Math.ceil(data.length / size);
      let paginated = {};

      for (let i = 1; i <= pages; i++) {
        const end = i * size;
        const start = end - size;
        paginated[i] = {
          from: start + 1,
          data: data.slice(start, end),
        };
        paginated[i].to = paginated[i].from + (paginated[i].data.length - 1);
      }

      return paginated;
    },
    parseData(data) {
      return JSON.parse(JSON.stringify(data));
    },
  },
};
