import router from "@/router";
// import Vue from "vue";
window.axios = require("axios");

window.axios.defaults.baseURL = process.env.VUE_APP_API;

const location = localStorage.getItem("eden-crm-location");
if (!location) {
  localStorage.setItem("eden-crm-location", "NG");
  window.axios.defaults.headers.common["X-Eden-Location"] = "NG";
} else {
  window.axios.defaults.headers.common["X-Eden-Location"] = location;
}

window.axios.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = JSON.parse(localStorage.getItem("eden-crm-token"));

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

window.axios.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Logout user if token has expired
    if (
      error.response.status === 401 ||
      error.response.message === "Unauthenticated."
    ) {
      localStorage.removeItem("eden-crm-token");
      localStorage.removeItem("eden-crm-user");
      localStorage.removeItem("eden-crm-state");
      if (router.history.current.name !== "login") {
        const redirectUrl = window.location.href;

        router.push({ name: "login", query: { redirect: redirectUrl } });
      }
      return true;
    }
  },
);
