import * as mutations from "@/store/mutation-types";
import * as actions from "@/store/action-types";
import marketplace from "../../../requests/products/product";

export default {
  [actions.GET_PRODUCTS](context, {id}) {
      return new Promise((resolve, reject) => {
          marketplace.list(id)
              .then((response) => {
                  if (response.data.status) {
                      context.commit(mutations.GET_PRODUCTS, {
                        id,
                        products:response.data.data
                    });
                  }
                  resolve(response.data.data);
              })
              .catch((error) => {
                  reject(error);
              });
      });
  },
};

