<template>
  <el-input
    v-model="setInput"
    v-number
    :maxlength="10"
    :placeholder="placeholder"
    class="phone-input"
    type="number"
  >
    <template slot="prepend">
      <div class="is-flex is-align-center">
        <img
          slot="prefix"
          :style="{
            height: '15px',
            borderRadius: '2px',
            marginRight: '6px',
          }"
          :src="getCountryFlag(location)"
          alt="code"
        />

        <span class="font-base"> +{{ countryCode }} </span>
      </div>
    </template>
  </el-input>
</template>

<script>
export default {
  name: "EdenPhoneInput",
  props: {
    input: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      inputValue: "",
    };
  },
  computed: {
    location() {
      return this.$store.getters.location;
    },
    placeholder() {
      return this.location === "NG" ? "812 345 6789" : "71 6345 6789";
    },
    countryCode() {
      return this.location === "NG" ? "234" : "254";
    },
    setInput: {
      get() {
        return this.input.slice(0, 3) === this.countryCode
          ? this.input.replace(this.countryCode, "")
          : this.input;
      },
      set(value) {
        this.$emit("update:input", `${this.countryCode}${value}`);
      },
    },
  },
};
</script>

<style lang="scss">
.phone-input {
  .el-input-group__prepend {
    padding: 0 10px !important;
  }
}
</style>
