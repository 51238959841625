import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const token = JSON.parse(localStorage.getItem("eden-crm-token"));
const location = localStorage.getItem("eden-crm-location");
const user = JSON.parse(localStorage.getItem("eden-crm-user"));
const user_role = localStorage.getItem("eden-crm-user-role");
const impersonate = localStorage.getItem("eden-crm-impersonate") === "true";

const state = () => ({
  token,
  authenticated: !!token,
  location,
  user,
  user_role,
  impersonate,
});

export default {
  state,
  getters,
  actions,
  mutations,
};
