import axios from "axios";

export default {
  index(page) {
    return axios.get(`crm/company?page=${page}`);
  },

  search(query) {
    return axios.get(`crm/company?name=${query}`);
  },

  add(payload) {
    return axios.post("crm/company", payload);
  },

  get(id) {
    return axios.get(`crm/company?company_id=${id}`);
  },

  update(payload) {
    return axios.post("crm/company/update", payload);
  },

  delete(payload) {
    return axios.post("crm/company/delete", payload);
  },
};
