import axios from "axios";

export default {
  search(query) {
    return axios.get(`crm/menu/meal?name=${query}`);
  },

  list(params = "") {
    return axios.get(`crm/menu/meal${params}`);
  },

  all(params = "") {
    return axios.get(`crm/menu/meal/all${params}`);
  },

  status(payload) {
    return axios.post(
      `service_type/service/update_garden_visibility_status`,
      payload,
    );
  },

  add(payload) {
    return axios.post(`crm/menu/meal`, payload);
  },

  update(payload) {
    return axios.post(`crm/menu/meal/update`, payload);
  },

  deleteMealItemIngredient(payload) {
    return axios.delete(`crm/menu/meal/ingredient`, { data: payload });
  },
};
