import * as types from "../../mutation-types";

export default {
  [types.TOKEN](state, token) {
    state.token = token;
  },

  [types.AUTHENTICATED](state, authenticated) {
    state.authenticated = authenticated;
  },

  [types.LOCATION](state, location) {
    state.location = location;
    localStorage.setItem("eden-crm-location", location);
    window.axios.defaults.headers.common["X-Eden-Location"] = location;
  },

  [types.USER](state, user) {
    state.user = { ...user };
  },

  [types.USER_ROLE](state, role) {
    state.user_role = role;
    localStorage.setItem("eden-crm-user-role", role);
  },

  [types.IMPERSONATE](state, impersonate) {
    state.impersonate = impersonate;
    localStorage.setItem("eden-crm-impersonate", JSON.stringify(impersonate));
  },

  [types.RESET_STATE](state, defaultState) {
    Object.assign(state, { ...defaultState.auth });
  },
};
