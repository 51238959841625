import axios from "axios";

// window.axios.defaults.baseURL =
//   "https://api-marketplace.edenlife.ng/api/v1/marketplace_crm/";

export default {
  index() {
    return axios.get(`marketplace_crm/vendors/stats`);
  },
};
