import * as types from "../../mutation-types";

export default {
  [types.GARDENERS_PAYMENT_TRACKER](state, { type, page, data }) {
    state.gardeners_payment_tracker[type].total = data.total;
    state.gardeners_payment_tracker[type].per_page = data.per_page;
    state.gardeners_payment_tracker[type].pages[page] = {
      from: data.from,
      to: data.to,
      data: data.data,
    };
  },

  [types.RESET_GARDENERS_PAYMENT_TRACKER](state) {
    state.gardeners_payment_tracker = {
      individual: {
        pages: {},
        total: null,
      },
      corporate: {
        pages: {},
        total: null,
      },
    };
  },

  [types.GARDENERS_LIST](state, gardeners_list) {
    state.gardeners_list = gardeners_list.sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
    );
  },

  [types.GARDENERS_SUMMARY](state, summary) {
    state.gardeners_summary = summary;
  },

  [types.GARDENERS](state, { page, data }) {
    state.gardeners.total = data.total;
    state.gardeners.pages[page] = {
      from: data.from,
      to: data.to,
      data: data.data,
    };
  },

  [types.GARDENER_SCHEDULE](state, { period, page, data }) {
    /**
     * Reset state if [period] changes;
     */
    if (state.gardener_schedule.period !== period) {
      state.gardener_schedule = {
        period,
        total: data.total,
        pages: {},
      };
    }

    /**
     * Set [page] data;
     */
    state.gardener_schedule.pages[page] = {
      from: data.from,
      to: data.to,
      data: data.data,
      total: data.total,
    };
  },

  [types.RESET_GARDENER_SCHEDULE](state) {
    state.gardener_schedule = {};
  },

  [types.RESET_STATE](state, defaultState) {
    Object.assign(state, { ...defaultState.gardeners });
  },
};
