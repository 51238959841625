import * as mutations from "../../mutation-types";
import * as actions from "../../action-types";
import subscriptions from "@/requests/subscriptions/index";
import billings from "@/requests/subscriptions/billings";
import transactions from "@/requests/subscriptions/transactions";
import paymentLinks from "@/requests/subscriptions/payment-links";

export default {
  [actions.GET_SUBSCRIPTIONS](context, page) {
    return new Promise((resolve, reject) => {
      subscriptions
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(
              mutations.SUBSCRIPTIONS_SUMMARY,
              response.data.summary
            );
            context.commit(mutations.SUBSCRIPTIONS, {
              page,
              subscriptions: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_BILLING_HISTORY](context, { id, page }) {
    return new Promise((resolve, reject) => {
      billings
        .index(id, page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.BILLING_HISTORY, {
              page,
              billing_history: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_TRANSACTIONS](context, page) {
    return new Promise((resolve, reject) => {
      transactions
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.TRANSACTIONS, {
              page,
              transactions: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [actions.GET_PAYMENT_LINKS](context, page) {
    return new Promise((resolve, reject) => {
      paymentLinks
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.PAYMENT_LINKS, {
              page,
              payment_links: response.data.data,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
