<template>
  <el-tag :type="setType(status)">{{ formatText(status) }}</el-tag>
</template>

<script>
export default {
  name: "EdenOrderStatus",
  props: {
    service: {
      type: String,
      default: "",
    },
    activity: {
      type: String,
      default: null,
    },
    completedStatus: {
      type: String,
      default: "",
    },
  },
  computed: {
    status() {
      if (this.completedStatus === "complete") {
        return "completed";
      }

      if (!this.activity && this.completedStatus === "incomplete") {
        return "not started";
      }

      return this.activity && this.activity.toLowerCase().replaceAll("_", " ");
    },
  },
};
</script>
