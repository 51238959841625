/**
 * Auth Module
 */
export const TOKEN = "TOKEN";
export const AUTHENTICATED = "AUTHENTICATED";
export const USER = "USER";
export const USER_ROLE = "USER_ROLE";
export const LOCATION = "LOCATION";
export const IMPERSONATE = "IMPERSONATE";
export const RESET_STATE = "RESET_STATE";

/**
 * Auth Module
 */
export const ACTIVITIES = "ACTIVITIES";

/**
 * Feedbacks Module
 */
export const FEEDBACK_SUMMARY = "FEEDBACK_SUMMARY";
export const FEEDBACK = "FEEDBACK";

/**
 * Customers Module
 */
export const CUSTOMERS_SUMMARY = "CUSTOMERS_SUMMARY";
export const CUSTOMERS_INDIVIDUAL = "CUSTOMERS_INDIVIDUAL";
export const CUSTOMERS_CORPORATE = "CUSTOMERS_CORPORATE";
export const CUSTOMERS_ONETIME = "CUSTOMERS_ONETIME";

export const CUSTOMER_SCHEDULE = "CUSTOMER_SCHEDULE";
export const CUSTOMER_ORDERS = "CUSTOMER_ORDERS";
export const CUSTOMER_TRANSACTIONS = "CUSTOMER_TRANSACTIONS";
/**
 * Vendors Module
 */
export const VENDORS_SUMMARY = "VENDORS_SUMMARY";
export const VENDORS_ACTIVE = "VENDORS_ACTIVE";
export const VENDORS_INACTIVE = "VENDORS_INACTIVE";
export const VENDORS_PENDING = "VENDORS_PENDING";
export const VENDORS_REJECTED = "VENDORS_REJECTED";
export const VENDORS_DISABLED = "VENDORS_DISABLED";

export const ACCOUNT_MANAGERS = "ACCOUNT_MANAGERS";

// export const CUSTOMER_SCHEDULE = "CUSTOMER_SCHEDULE";
// export const CUSTOMER_ORDERS = "CUSTOMER_ORDERS";
// export const CUSTOMER_TRANSACTIONS = "CUSTOMER_TRANSACTIONS";

/**
 * Eden for Busines Module
 */
export const CORPORATE_ACCOUNTS = "CORPORATE_ACCOUNTS";
export const SAVE_SUBSCRIPTION = "SAVE_SUBSCRIPTION";
export const SAVE_EMPLOYEES = "SAVE_EMPLOYEES";
export const SAVE_UPLOAD_RECEIPTS = "SAVE_UPLOAD_RECEIPTS";

/**
 * Gardeners Module
 */
export const GARDENERS_SUMMARY = "GARDENERS_SUMMARY";
export const GARDENERS_LIST = "GARDENERS_LIST";
export const GARDENERS = "GARDENERS";
export const GARDENER_SCHEDULE = "GARDENER_SCHEDULE";
export const RESET_GARDENER_SCHEDULE = "RESET_GARDENER_SCHEDULE";
export const GARDENERS_PAYMENT_TRACKER = "GARDENERS_PAYMENT_TRACKER";
export const RESET_GARDENERS_PAYMENT_TRACKER =
  "RESET_GARDENERS_PAYMENT_TRACKER";

/**
 * Orders Module
 */
export const ORDERS_SUMMARY = "ORDERS_SUMMARY";
export const ORDERS = "ORDERS";
export const ORDERS_SCHEDULE = "ORDERS_SCHEDULE";
export const FULFILLMENT_ORDERS_SCHEDULE = "FULFILLMENT_ORDERS_SCHEDULE";

/**
 * Zones and Location Areas Module
 *
 */
export const ZONES_LIST = "ZONES_LIST";
export const ZONES = "ZONES";

export const LOCATION_AREAS_LIST = "LOCATION_AREAS_LIST";
export const LOCATION_AREAS = "LOCATION_AREAS";

export const MICROFULFILLMENT_LIST = "MICROFULFILLMENT_LIST";
export const MICROFULFILLMENT_CENTRES = "MICROFULFILLMENT_CENTRES";

/**
 * Services Module
 */
export const SERVICES = "SERVICES";
export const SERVICE = "SERVICE";
export const MEAL_ITEM_LIST = "MEAL_ITEM_LIST";
export const SERVICE_TYPES = "SERVICE_TYPES";
export const MENUS = "MENUS";
export const MENU_VARIATIONS = "MENU_VARIATIONS";
export const INGREDIENTS = "INGREDIENTS";
export const FOOD_TAGS = "FOOD_TAGS";
export const MENU_TYPES = "MENU_TYPES";

/**
 * Operations Module
 */
export const LOGISTICS_DIRECTORY = "LOGISTICS_DIRECTORY";
export const LOGISTICS_SCHEDULE = "LOGISTICS_SCHEDULE";
export const LOGISTICS_SCHEDULE_ORDERS = "LOGISTICS_SCHEDULE_ORDERS";

/**
 * Service Operations Module
 */
export const SERVICE_PARTNERS_LIST = "SERVICE_PARTNERS_LIST";
export const SERVICE_PARTNERS = "SERVICE_PARTNERS";
export const PROVISIONED_ORDERS = "PROVISIONED_ORDERS";

/**
 * Subscriptions & Payment Module
 */
export const SUBSCRIPTIONS_SUMMARY = "SUBSCRIPTIONS_SUMMARY";
export const SUBSCRIPTIONS = "SUBSCRIPTIONS";
export const BILLING_HISTORY = "BILLING_HISTORY";
export const TRANSACTIONS = "TRANSACTIONS";
export const PAYMENT_LINKS = "PAYMENT_LINKS";

/**
 * Discounts & Rewards
 */
export const DISCOUNTS = "GET_BADGES";

/**
 * Communications
 */
export const BULK_MESSAGES = "BULK_MESSAGES";

/**
 * Settings Module
 */
export const MEMBERS = "MEMBERS";

/**
 * Stewards
 */
export const PRODUCTION_PROVISIONS = "PRODUCTION_PROVISIONS";
export const COMBO_CHECK = "COMBO_CHECK";
export const COMBO_HISTORY = "COMBO_HISTORY";

export const OFA_HISTORY = "OFA_HISTORY";

// Marketplace

export const GET_BUSINESS_TYPE = "GET_BUSINESS_TYPE";
export const PRODUCTS = "PRODUCTS";
