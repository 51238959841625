import axios from "axios";

export default {
  combos(spId, period, from, to) {
    if (period === "custom") {
      return axios.get(
        `service_partners/${spId}/combo_check?from=${from}&to=${to}`
      );
    }
    return axios.get(`service_partners/${spId}/combo_check?period=${period}`);
  },

  filterCombo(spId, params = "") {
    return axios.get(
      `service_partners/${spId}/combo_check${params}`
    );
  },

  combo(spId, date) {
    return axios.get(`service_partners/${spId}/combo_check?from=${date}`);
  },

  plate(payload) {
    return axios.post("service_partners/combo_check/mark_combos", payload);
  },

  stove(payload) {
    return axios.post("crm/chef/stove_off", payload);
  },

  stoved(date) {
    return axios.get(`crm/chef/activity?date=${date}`);
  },

  mark(payload) {
    return axios.post("crm/steward/combo", payload);
  },

  history(page) {
    return axios.get(`crm/steward/daily/summary?page=${page}`);
  },

  filter(start, end) {
    return axios.get(
      `crm/steward/daily/summary?start_date=${start}&end_date=${end}`
    );
  },
};
