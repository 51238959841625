import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = () => ({
  gardeners_list: [],
  gardeners_summary: {},
  gardeners: {
    total: null,
    pages: {},
  },
  gardener_schedule: {},
  gardeners_payment_tracker: {
    individual: {
      total: null,
      pages: {},
    },
    corporate: {
      total: null,
      pages: {},
    },
  },
});

export default {
  state,
  getters,
  actions,
  mutations,
};
