import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = () => ({
  // customers_summary: {},
  vendors_active: {
    total: null,
    pages: {},
  },
  vendors_inactive: {
    total: null,
    pages: {},
  },
  vendors_pending: {
    total: null,
    pages: {},
  },
  vendors_rejected: {
    total: null,
    pages: {},
  },
  vendors_disabled: {
    total: null,
    pages: {},
  },
  account_managers: {},
  vendors_summary: {},
});

export default {
  state,
  getters,
  actions,
  mutations,
};
