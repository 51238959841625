<template>
  <el-dialog
    :title="title"
    :visible.sync="setShow"
    :close-on-click-modal="false"
    :show-close="false"
    width="30%"
    append-to-body
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row type="flex" :gutter="10" class="is-flex-wrap">
        <el-col :xs="24" :md="12">
          <el-form-item label="From" prop="start" :rules="validateField()">
            <el-date-picker
              v-model="form.start"
              type="date"
              placeholder="Select start"
              format="dd MMM, yyyy"
              value-format="yyyy-MM-dd"
              :clearable="false"
              prefix-icon="eden-icon-calendar"
              @change="checkEndDate"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="12">
          <el-form-item label="To" prop="end" :rules="validateField()">
            <el-date-picker
              ref="end"
              v-model="form.end"
              type="date"
              placeholder="Select end"
              value-format="yyyy-MM-dd"
              format="dd MMM, yyyy"
              :clearable="false"
              :picker-options="{
                disabledDate: disableDates,
              }"
              prefix-icon="eden-icon-calendar"
              :disabled="form.start === ''"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer justify-between">
      <el-button plain @click="cancel">Cancel</el-button>
      <el-button type="success" :disabled="disable" @click="confirm">{{
        button
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "EdenCustomPeriod",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Get",
    },
    button: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      value: "",
      form: {
        start: "",
        end: "",
      },
      loading: false,
    };
  },

  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    disable() {
      return this.form.start === "" && this.form.end === "";
    },
  },

  methods: {
    checkEndDate() {
      if (this.form.end && this.form.end < this.form.start) {
        this.form.end = "";
      }
      this.$refs.end.focus();
    },

    disableDates(time) {
      return new Date(this.formatDate(time,'y-m-d')).getTime() < new Date(this.form.start).getTime();
    },

    cancel() {
      this.setShow = false;
      this.$emit("cancel");
      this.form.start = "";
      this.form.end = "";
    },

    confirm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.$emit("confirm", {
          from: this.form.start,
          to: this.form.end,
        });
        this.setShow = false;
        this.$refs.form.resetFields();
      });
    },
  },
};
</script>
