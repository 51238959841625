import * as mutations from "@/store/mutation-types";
import * as actions from "@/store/action-types";
import marketplace from "../../../requests/curations/curation";

export default {
  [actions.GET_BUSINESS_TYPE](context) {
      return new Promise((resolve, reject) => {
          marketplace.getBusinessTypes()
              .then((response) => {
                  if (response.data.status) {
                      context.commit(mutations.GET_BUSINESS_TYPE, response.data.data);
                  }
                  resolve(response.data.data);
              })
              .catch((error) => {
                  reject(error);
              });
      });
  },
};

