import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = () => ({
  orders_summary: {},
  orders: {},
  orders_schedule: {
    beauty: {},
    cleaning: {},
    laundry_pickup: {},
    laundry_delivery: {},
    meal: {},
  },
});

export default {
  state,
  getters,
  actions,
  mutations,
};
