import axios from "axios";

export default {
  summary(start_date, end_date) {
    return axios.get(
      `crm/summary/order?start_date=${start_date}&end_date=${end_date}`
    );
  },

  index(period, page) {
    return axios.get(`customer_orders?period=${period}&page=${page}`);
  },

  search(query, period) {
    return axios.get(`customer_orders/search/${query}?period=${period}`);
  },

  filter(payload) {
    return axios.post("customer_orders/filter", payload);
  },

  status(status, payload) {
    return axios.post(
      `customer_orders/${status}_all_orders_within_duration`,
      payload
    );
  },

  reschedule(payload) {
    return axios.post("reschedule_customer_order", payload);
  },

  export(period, payload) {
    if (period === "custom") {
      return axios.post("customer_orders/export/all/filter", payload);
    }
    return axios.get(`customer_orders/export/all?period=${period}`);
  },

  complete(payload) {
    return axios.post("customer_orders/update_completion_status", payload);
  },
};
