import * as mutations from "@/store/mutation-types";
import * as actions from "@/store/action-types";
import servicepartners from "@/requests/serviceoperations/servicepartners";
import provisionedorders from "@/requests/serviceoperations/provisionedorders";

export default {
  [actions.GET_SERVICE_PARTNERS_LIST](context) {
    return new Promise((resolve, reject) => {
      servicepartners
        .list()
        .then((response) => {
          if (response.data.status) {
            context.commit(
              mutations.SERVICE_PARTNERS_LIST,
              response.data.partners,
            );
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_SERVICE_PARTNERS](context, page) {
    return new Promise((resolve, reject) => {
      servicepartners
        .index(page)
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.SERVICE_PARTNERS, {
              page,
              data: response.data.partners,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [actions.GET_PROVISIONED_ORDERS](
    context,
    { service, start_date, end_date, menu_type, customer_type },
  ) {
    return new Promise((resolve, reject) => {
      provisionedorders
        .get({ service, start_date, end_date, menu_type, customer_type })
        .then((response) => {
          if (response.data.status) {
            context.commit(mutations.PROVISIONED_ORDERS, {
              provisioned_orders: response.data.data,
              service,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
