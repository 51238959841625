import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import activities from "./modules/activities";
import gardeners from "./modules/gardeners";
import customers from "./modules/customers";
import orders from "./modules/orders";
import fulfillment from "./modules/fulfillment";
import zones from "./modules/zones";
import feedback from "./modules/feedback";
import services from "./modules/services";
import logistics from "./modules/logistics";
import serviceoperations from "./modules/serviceoperations";
import subscriptions from "./modules/subscriptions";
import discounts from "./modules/discounts";
import communications from "./modules/communications";
import stewards from "./modules/stewards";
import settings from "./modules/settings";
import business from "./modules/business";
import vendors from "./modules/vendors";
import products from "./modules/products"
import curations from "./modules/curations";

Vue.use(Vuex);

const ifInProduction = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  modules: {
    auth,
    activities,
    gardeners,
    customers,
    orders,
    fulfillment,
    zones,
    services,
    logistics,
    serviceoperations,
    subscriptions,
    discounts,
    communications,
    stewards,
    settings,
    feedback,
    business,
    vendors,
    products,
    curations,
  },
  strict: ifInProduction,
});

export default store;
