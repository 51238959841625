// const Base = () => import("@/pages/dance-festival/base");
const Menu = () => import("@/pages/dance-festival/menu/index");
const MenuBase = () => import("@/pages/dance-festival/menu/base");
const OrdersBase = () => import("@/pages/dance-festival/orders/base");
const Orders = () => import("@/pages/dance-festival/orders/index");
const MenuAdd = () => import("@/pages/dance-festival/menu/add");
const MenuEdit = () => import("@/pages/dance-festival/menu/edit");
const MealItemsBase = () => import("@/pages/dance-festival/meal-items/base");
const MealItems = () => import("@/pages/dance-festival/meal-items/index");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "growth",
  "gardener",
];

const routes = () => {
  return [
    /**
     * Redirect to Menu page if someone tries to
     * access main module route [.../dance-festival] without
     * specifying a sub-module  [.../dance-festival/:sub-module]
     */
    {
      path: "/dance-festival",
      redirect: {
        name: "dance-festival.df-menu.index",
        component: Menu,
      },
    },
    /**
     * Menu Sub-Module
     */
    {
      path: "/dance-festival/menu",
      name: "dance-festival.df-menu.index",
      component: Menu,
      meta: {
        module: "dance-festival",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/dance-festival/menu/",
      component: MenuBase,
      meta: {
        module: "dance-festival",
        permission: authenticatedRoles,
        layout: "app",
      },
      children: [
        {
          path: "meals",
          name: "dance-festival.df-menu.index",
          component: Menu,
          meta: {
            module: "dance-festival",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: "add",
          name: "dance-festival.df-menu.add",
          component: MenuAdd,
          meta: {
            module: "dance-festival",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
        {
          path: ":id/edit",
          name: "dance-festival.df-menu.edit",
          component: MenuEdit,
          meta: {
            module: "dance-festival",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
      ],
    },
    /**
     * Orders Sub-Module
     */
    {
      path: "/dance-festival/orders",
      name: "dance-festival.df-orders.index",
      component: Orders,
      meta: {
        module: "dance-festival",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/dance-festival/orders/",
      component: OrdersBase,
      meta: {
        module: "dance-festival",
        permission: authenticatedRoles,
        layout: "app",
      },
      children: [
        {
          path: "items",
          name: "dance-festival.df-orders.index",
          component: Orders,
          meta: {
            module: "dance-festival",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
      ],
    },
    /**
     * Meal Items Sub-Module
     */
    {
      path: "/dance-festival/meal-items",
      name: "dance-festival.df-meal-items.index",
      component: MealItems,
      meta: {
        module: "dance-festival",
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/dance-festival/meal-items/",
      component: MealItemsBase,
      meta: {
        module: "dance-festival",
        permission: authenticatedRoles,
        layout: "app",
      },
      children: [
        {
          path: "items",
          name: "dance-festival.df-meal-items.index",
          component: MealItems,
          meta: {
            module: "dance-festival",
            permission: authenticatedRoles,
            layout: "app",
          },
        },
      ],
    },
  ];
};

export default routes();
