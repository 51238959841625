<template>
  <div class="eden-daterange-preview">
    <el-button
      class="display"
      type="outline"
      :disabled="loading"
      @click="$emit('change')"
    >
      Custom date
      <span class="text-grey-primary text-bold">
        : {{ formatDate(daterange.from, "do m, y") }} -
        {{ formatDate(daterange.to, "do m, y") }}
      </span>
    </el-button>
    <el-button type="plain" :disabled="loading" @click="$emit('clear')">
      Clear Dates
    </el-button>
  </div>
</template>
<script>
export default {
  name: "EdenCustomPeriodPreview",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    daterange: {
      type: Object,
      default() {
        return {
          from: "",
          to: "",
        };
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.eden-daterange-preview {
  display: flex;
  align-items: center;

  .date {
    height: 40px;
    background: transparent;
  }
}
</style>
