import axios from "axios";

export default {
  profile(id) {
    return axios.get(`customer/${id}`);
  },
  pendingDiscounts(userId){
    return axios.get(`customers/${userId}/unused_redeemed_rewards`)
  },
  schedule({ id, period, page }) {
    return axios.get(`customer_orders/${id}?period=${period}&page=${page}`);
  },

  foodActivities(id, period) {
    return axios.get(`${id}/meal/picking_items/audits?period=${period}`);
  },

  pauses(email) {
    return axios.get(`subscriptions/current/all_pauses/${email}`);
  },

  orders(id, period, page) {
    return axios.get(`customer_orders/${id}?period=${period}&page=${page}`);
  },

  subscriptions(id) {
    return axios.get(`subscriptions/individual/all_cycles/${id}`);
  },

  transactions(id, page) {
    return axios.get(`customer/${id}/billing_history?page=${page}`);
  },

  gardenersLog(id) {
    return axios.get(`gardener_assignment_logs/${id}`);
  },

  rewards(id, refresh) {
    return axios.get(
      `/customers/${id}/rewards_logs${refresh ? "?refresh=true" : ""}`
    );
  },
};
