const Subscriptions = () => import("@/pages/subscriptions/index");
const BillingHistory = () => import("@/pages/subscriptions/history");

const authenticatedRoles = [
  "superadmin",
  "admin",
  "operations",
  "kitchen",
  "growth",
  "gardener",
  "gardener_pro",
];

const MODULE = "subscriptions";

const routes = () => {
  return [
    {
      path: "/subscriptions",
      name: "subscriptions.index",
      component: Subscriptions,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
    {
      path: "/subscriptions/:id",
      name: "subscriptions.history",
      component: BillingHistory,
      meta: {
        module: MODULE,
        permission: authenticatedRoles,
        layout: "app",
      },
    },
  ];
};

export default routes();
